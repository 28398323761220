export const MODULE_NAME = 'WELLCOME_HOME_FAG';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface Question {
  tid: string;
  answerTid: string;
  subTitleFirst?: string;
  subTitleSecond?: string;
  subTitleThird?: string;
  answerFirst?: string;
  answerSecond?: string;
  answerThird?: string;
  answerFourth?: string;
  subTitleFourth?: string;
}

export interface FAQProps {
  activeIcon: string;
  activeQuestion: number | null;
  toggleQuestion: (index: number) => void;
}

export const question: Question[] = [
  {
    tid: 'LANDING.HOME_FAQ.LIST.0.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.0.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.0.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.0.SUBTITLE.2',

    answerFirst: 'LANDING.HOME_FAQ.LIST.0.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.0.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.0.ANSWER.2',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.1.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.1.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.1.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.1.SUBTITLE.2',
    subTitleFourth: 'LANDING.HOME_FAQ.LIST.1.SUBTITLE.3',

    answerFirst: 'LANDING.HOME_FAQ.LIST.1.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.1.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.1.ANSWER.2',
    answerFourth: 'LANDING.HOME_FAQ.LIST.1.ANSWER.3',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.2.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.2.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.2.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.2.SUBTITLE.2',

    answerFirst: 'LANDING.HOME_FAQ.LIST.2.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.2.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.2.ANSWER.2',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.3.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.3.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.3.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.3.SUBTITLE.2',

    answerFirst: 'LANDING.HOME_FAQ.LIST.3.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.3.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.3.ANSWER.2',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.4.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.4.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.4.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.4.SUBTITLE.2',

    answerFirst: 'LANDING.HOME_FAQ.LIST.4.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.4.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.4.ANSWER.2',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.5.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.5.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.5.SUBTITLE.1',
    subTitleThird: 'LANDING.HOME_FAQ.LIST.5.SUBTITLE.2',

    answerFirst: 'LANDING.HOME_FAQ.LIST.5.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.5.ANSWER.1',
    answerThird: 'LANDING.HOME_FAQ.LIST.5.ANSWER.2',
  },

  {
    tid: 'LANDING.HOME_FAQ.LIST.6.QUESTION',
    answerTid: '',
    subTitleFirst: 'LANDING.HOME_FAQ.LIST.6.SUBTITLE.0',
    subTitleSecond: 'LANDING.HOME_FAQ.LIST.6.SUBTITLE.1',

    answerFirst: 'LANDING.HOME_FAQ.LIST.6.ANSWER.0',
    answerSecond: 'LANDING.HOME_FAQ.LIST.6.ANSWER.1',
  },
];
