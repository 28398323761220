import styled from 'styled-components';
import { useRef, useState } from 'react';
import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';
import uploadDocumentActiveSvg from '../../../asset/svg/common/document-upload-active.svg';

import { ButtonElem } from '../../../common/button';
import { AlertActionElem } from '../../../common/alert-action';

export const FileOrderUploader: React.FC<{
  isDisabled: boolean;
  handleFileChange: (file: File) => void;
  isFile: boolean;
}> = ({ handleFileChange, isDisabled, isFile }) => {
  const [validation, setValidation] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const allowedMimeTypeArr = [
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/bmp',
    'image/tiff',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/rtf',
  ];

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;

    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      const isAllowedType = allowedMimeTypeArr.includes(selectedFile.type);

      if (isAllowedType) {
        handleFileChange(selectedFile);
        setSuccess('Файл доданий успішно');
        e.target.value = '';
      } else {
        setValidation('Недопустимий формат');
        e.target.value = '';
      }
    }
  };

  const handleButtonClick = () => {
    if (isDisabled) return;

    fileInputRef.current?.click();
  };

  return (
    <>
      {validation && <AlertActionElem type="error" tid={validation} />}
      {success && <AlertActionElem type="success" tid={success} />}

      <AddContainer>
        <StyledInput
          ref={fileInputRef}
          type="file"
          onChange={fileChangeHandler}
        />
        <ButtonElemStyled
          iconSize="medium"
          padding="19px 32px"
          tid="COMMON.FILE.ORDER_UPLOADER"
          type="secondary"
          sizeText="semiSmall"
          disabled={isDisabled}
          iconLeft={isFile ? uploadDocumentActiveSvg : uploadDocumentSvg}
          onClick={handleButtonClick}
        />
      </AddContainer>
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  justify-content: center;
`;

const AddContainer = styled.label``;

const StyledInput = styled.input`
  display: none;
`;
