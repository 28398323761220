import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { CATALOG_ITEM_LIST } from './constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{}> = () => {
  // const history = useHistory();

  // const handleRedirect = (id: number) => {
  //   history.push(`/psycho-list?psychoCategory=${id}`);
  // };

  return (
    <Wrapper>
      <CardContainer>
        <CardHeader>
          <TextElem size="title" type="medium" color="textPrimary">
            {i18n.t('KNOWLEDGE_LANDING.TITLE.ADVANTAGE')}
            <TextElem
              color="borderActive"
              tid="KNOWLEDGE_LANDING.TITLE.ADVANTAGE_LOGO"
              size="title"
              type="medium"
            />
          </TextElem>
        </CardHeader>
        <GridContainer>
          {CATALOG_ITEM_LIST.map((service, index) => (
            <Card key={index}>
              <ImageWrapper>
                <CardImg src={service.imgSrc} alt={service.imgAlt} />
              </ImageWrapper>
              <TextElem
                lineHeight="25.6px"
                style={{ textAlign: 'center' }}
                color="textPrimary"
                size="cardImgTitle"
                type="medium"
                tid={service.text}
              />
              {/* <CardTitle>{service.title}</CardTitle> */}
              {/* <CardSubtitle>{service.subtitle}</CardSubtitle> */}
            </Card>
          ))}
        </GridContainer>
      </CardContainer>
    </Wrapper>
  );
};

const ImageWrapper = styled.div`
  overflow: hidden;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  padding-bottom: ${Spacing(3)};

  // max-width: 300px;
  // max-height: 300px;

  margin: 0 auto;

  // @media (max-width: 1024px) {
  //   max-width: 280px;
  //   max-height: 280px;
  // }

  @media (max-width: 768px) {
    padding: 0 ${Spacing(5)} ${Spacing(3)};
  }
`;

const Wrapper = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  // max-width: ${Spacing(350)};
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  // padding: 0 ${Spacing(3)};

  @media (max-width: 1024px) {
    padding: ${Spacing(4)};
  }
`;

const CardContainer = styled.div`
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  background-size: cover;
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
  width: 100%; // поставив щоб була менша площа квадрата білого, як інакше не знаю
  // max-width: ${Spacing(344)};
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  display: grid;
  gap: ${Spacing(6)};
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${Spacing(14)} ${Spacing(30)};

  @media (max-width: 1325px) {
    padding-right: clamp(12px, 5.1vw, 120px);
    padding-left: clamp(12px, 5.1vw, 120px);
  }

  @media (max-width: 768px) {
    padding-top: ${Spacing(8)};
    padding-bottom: ${Spacing(8)};
    padding-right: clamp(12px, 3.7vw, 120px);
    padding-left: clamp(12px, 3.7vw, 120px);
  }
`;

const CardHeader = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  justify-self: center;
  text-align: center;

  @media (max-width: 768px) {
    span > span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px;
    }
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px;
    }
  }
`;

const CardImg = styled.img`
  // padding-bottom: ${Spacing(3)};
  width: 75%;
  height: 100%;
  align-self: center;
  object-fit: contain;

  @media (max-width: 1024px) {
    width: 60%;
  }

  // @media (max-width: 768px) {
  //   width: 50%;
  // }

  // border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]};

  @media (max-width: 1024px) {
    // padding: 0 ${Spacing(5)} ${Spacing(3)};
  }
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: ${Spacing(7)};
  column-gap: ${Spacing(5)};
  justify-content: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(CardElem)`
  display: grid;
  grid-template-rows: max-content 1fr;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    span {
      font-size: 14px !important;
      // line-height: 21px;
    }
  }
`;

export default Component;
