import React, { useState } from 'react';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { clipboardCopy } from '../../lib/util/clipboard';
import { TextElem } from '../text';
import copyIcon from '../../asset/svg/alert/copy.svg';
import ReactDOM from 'react-dom';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{
  value: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, value, className }) => {
  const [copy, setCopy] = useState(false);

  const handleCopy = () => {
    setCopy(true);
    clipboardCopy(value);
    setTimeout(() => setCopy(false), 3000);
  };

  return (
    <Container onClick={handleCopy} className={className}>
      {children}

      {copy &&
        ReactDOM.createPortal(
          <AlertContainer>
            <FlexElemStyled>
              <img src={copyIcon} alt="copy icon" />
              <TextElem
                tid="COMMON.ALERT.COPY"
                size="alert"
                type="medium"
                color="default"
              />
            </FlexElemStyled>
          </AlertContainer>,
          document.body,
        )}
    </Container>
  );
};

const FlexElemStyled = styled.div`
  gap: ${Spacing(3)};
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 ${Spacing(3)};
  padding: ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};

  position: relative;
  /* plus menu-bar */
  left: 135px;
`;

const Container = styled.div`
  cursor: pointer;
  width: 100%;
`;

const AlertContainer = styled.div`
  position: fixed;
  top: 12px;
  padding: 0 ${Spacing(3)};

  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  transition: background-color
    ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  width: 100%;
`;
