import { i18n } from '../../lib/lang';
import { numberPositiveMin, required } from '../../lib/validation/service';
import { FORM_VALUE_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_CATEGORY_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'category/update',
  },
  DELETE: {
    TYPE: 'DELETE',
    URL: (categoryId: string) => `category/item/delete/${categoryId}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export const config = {
  [FORM_VALUE_ENUM.CATEGORY]: [required],
  [FORM_VALUE_ENUM.DESCRIPTION]: [],
  [FORM_VALUE_ENUM.WARNING]: [],
  [FORM_VALUE_ENUM.CERTIFICATE]: [],
  [FORM_VALUE_ENUM.DURATION]: [required, numberPositiveMin(50)],
  [FORM_VALUE_ENUM.DIPLOMA]: [],
  [FORM_VALUE_ENUM.VIDEO]: [],
  [FORM_VALUE_ENUM.PRICE]: [required, numberPositiveMin(1000)],
};

export const configPsychiatrist = {
  [FORM_VALUE_ENUM.CATEGORY]: [required],
  [FORM_VALUE_ENUM.DESCRIPTION]: [],
  [FORM_VALUE_ENUM.CERTIFICATE]: [],
  [FORM_VALUE_ENUM.DURATION]: [required, numberPositiveMin(30)],
  [FORM_VALUE_ENUM.DIPLOMA]: [],
  [FORM_VALUE_ENUM.VIDEO]: [],
  [FORM_VALUE_ENUM.PRICE]: [required, numberPositiveMin(500)],
};
