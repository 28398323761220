import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';

import { UseMutationResult } from 'react-query';
import fileIcon from '../../../asset/svg/file/folder-open.svg';
import draggingFileSvg from '../../../asset/svg/common/arrow-down.svg';
import {
  SettingContainer,
  IconSettingBackground,
  IconImg,
  Background,
} from './image';
import refreshFileSvg from '../../../asset/svg/common/refresh.svg';
import trashFileSvg from '../../../asset/svg/common/trash.svg';
import uploadIcon from '../../../asset/svg/common/document-upload.svg';
import { AlertActionElem } from '../../../common/alert-action';

export const FileComponent: React.FC<{
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error: string;
  isDragActive: boolean;
  data: any;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
}> = ({
  getInputProps,
  getRootProps,
  uploadFile,
  defaultValue,
  error,
  isDragActive,
  data,
  handleReselect,
  handleReset,
}) => {
  const open = () => {
    window.open(defaultValue || data.url, '_blank');
  };
  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  return (
    <>
      {!uploadFile.isLoading && !uploadFile.isSuccess && !defaultValue && (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Content spacing={3}>
            <Center spacing={2}>
              {isDragActive ? (
                <img src={draggingFileSvg} alt="dragging" />
              ) : (
                <img src={fileIcon} alt="file" />
              )}
              <TitleText
                tid={
                  isDragActive
                    ? 'COMMON.FILE.DRAGGING'
                    : 'COMMON.FILE.DOCUMENT_CONTENT_TITLE'
                }
                size="semiSmall"
                color={error || false ? 'textSecondary' : 'default'}
                type="regular"
              />
              {!isDragActive && (
                <HelperText
                  color={error ? 'error' : 'textSecondary'}
                  type="light"
                  size="small"
                  tid={!!error ? error : 'COMMON.FILE.DOCUMENT_SIZE_TITLE'}
                />
              )}
            </Center>
          </Content>
        </div>
      )}

      {(!!uploadFile.isSuccess ||
        (!!defaultValue && !uploadFile.isLoading)) && (
        <>
          <Background onClick={open}>
            <Center>
              <img src={uploadIcon} alt="upload" />
              <TitleText
                tid="COMMON.FILE.VIEW_FILE"
                size="semiSmall"
                type="regular"
                color="default"
              />
            </Center>
            <SettingContainer onClick={stopPropagation} className="setting">
              <IconSettingBackground onClick={handleReselect}>
                <IconImg src={refreshFileSvg} />
              </IconSettingBackground>
              <IconSettingBackground onClick={handleReset}>
                <IconImg src={trashFileSvg} />
              </IconSettingBackground>
            </SettingContainer>
          </Background>
          {!!error && <AlertActionElem text={error} type="error" />}
        </>
      )}
    </>
  );
};

const TitleText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 13px;
  }
`;

const HelperText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 11px;
  }
`;

export const Center = styled(GridElem)`
  justify-items: center;
  text-align: center;
`;

export const Content = styled(Center)`
  padding: 0 ${Spacing(4)};
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  display: block !important;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  cursor: pointer;
`;
