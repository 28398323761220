export const getIsAfterDate = (targetDateStr: string) => {
  const targetDate = new Date(targetDateStr);

  targetDate.setMinutes(targetDate.getMinutes());

  const currentUtcDate = new Date();

  return currentUtcDate > targetDate;
};

export const getIsAfterDurationDate = (
  targetDateStr: string,
  duration: number,
) => {
  const targetDate = new Date(targetDateStr);

  targetDate.setMinutes(targetDate.getMinutes() + duration);

  const currentUtcDate = new Date();

  return currentUtcDate > targetDate;
};
