import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import { ButtonElem } from '../../../common/button';
import { SIZE_FONT_DATA, SIZE_FONT_ENUM } from '../../../theme/size';
import { useHistory } from 'react-router';

export const PackOrder: React.FC<{ id: string }> = ({ id }) => {
  const href = `/oplata/${id}?isPack=true`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };
  return (
    <MainContainer>
      <LogoSvg src="./assets/svg/header/syayvoLogo.svg" alt="syaivo Logo" />
      <TextContainer>
        <TextElem
          tid="CONSULTATION.USER.PACK.TITLE"
          type="medium"
          size="main"
          color="textPrimary"
        />
        <TextElem
          tid="CONSULTATION.USER.PACK.DESCRIPTION"
          size="input"
          type="light"
          color="textSecondary"
        />
      </TextContainer>
      <ButtonStyled
        onClick={onClick}
        sizeText="semiSmall"
        type="submit"
        tid="Оформити пакет консультацій"
      />
    </MainContainer>
  );
};

const ButtonStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(5)} !important;
  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const LogoSvg = styled.img`
  width: 131px;
  height: 46px;
  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(7)};
  gap: ${Spacing(5)};
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;
