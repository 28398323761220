import React, { useState } from 'react';
import { TextElem } from '../text';
import styled, { css } from 'styled-components';
import { COLOR_DATA, COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';
import { PROPS_TYPE } from '../text/constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { STEP_LIST } from './constant';

export const Elem: React.FC<{
  step: STEP_LIST;
  text: string;
}> = ({ step, text }) => {
  return (
    <ProgressBar>
      <ProgressText>
        <TextElem
          color="textPrimary"
          type="regular"
          size="default"
          tid={text}
          lineHeight="16px"
        />
        <TextElem type="bold" size="main" lineHeight="16px">
          {step}{' '}
          <TextElem
            color="textSecondary"
            size="main"
            lineHeight="16px"
            tid="/ 3"
            type="bold"
          />
        </TextElem>
      </ProgressText>
      <ProgressScale>
        {Object.values(STEP_LIST).map((item) => (
          <ProgressPoint active={item <= step} />
        ))}
        {/* <ProgressPoint />
        <ProgressPoint />
        <ProgressPoint /> */}
      </ProgressScale>
    </ProgressBar>
  );
};

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
  row-gap: ${Spacing(4)};
  width: 100%;
  /* max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  ); */
  padding: ${Spacing(5)};

  margin: 0 auto;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
`;

const ProgressText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 420px) {
    span {
      font-size: 13px;
    }
  }
`;

const ProgressScale = styled.div`
  display: grid;
  gap: ${Spacing(1)};
  grid-template-columns: repeat(3, 1fr);
`;

const ProgressPoint = styled.div<{ color?: COLOR_ENUM; active?: boolean }>`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  height: ${Spacing(2)};

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
      `;
    }
    return css`
      opacity: 20%;
      background-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
    `;
  }}
`;
