import { AUTH_TYPE } from '../../data/auth/constant';
import { CONSULTATION_RAW_INTER } from '../../data/consultation/constant';
import { CONSULTATION_DATA_RAW_INTER } from '../consultation-list/constant';

export const MODULE_NAME = 'AUTH_CONSULTATION_INVITE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD = 'password',
  TYPE = 'type',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.EMAIL]?: string;
  [FORM_VALUE_ENUM.PHONE]?: string;
  [FORM_VALUE_ENUM.PASSWORD]: string;
  [FORM_VALUE_ENUM.TYPE]: AUTH_TYPE;
}

export const API = {
  MAIN: {
    TYPE: 'GET',
    URL: (id: string) => `/auth/consultation-token/${id}`,
  },
};

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
  consultation: CONSULTATION_RAW_INTER;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
