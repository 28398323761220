import React from 'react';

import { DATA_INTER, FORM_VALUE_ENUM } from '../constant';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { SelectElem } from '../../../common/select';
import {
  CONSULTATION_PACK_PRICE_CONFIG,
  CONSULTATION_TYPE_OPTION,
} from '../../../data/consultation/constant';

export const Category: React.FC<{
  data?: DATA_INTER;
  getFieldValue: Function;
  setFieldValue?: Function;
  setValue: Function;
  isFieldError: Function;
  getFieldError: Function;
  isPack: boolean;
  consultationPackPrice: number;
  consultationPackDiscount: number;
}> = ({
  data,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  isPack,
  getFieldValue,
  consultationPackPrice,
  consultationPackDiscount,
}) => {
  return (
    <Wrapper>
      <InfoContainer>
        <SelectWrapper>
          <SelectElem
            placeholder="USER.PSYCHOLOGIST.CATEGORY_PLACEHOLDER"
            name={FORM_VALUE_ENUM.CATEGORY}
            onChange={setFieldValue}
            options={data?.user.category}
            title="USER.PSYCHOLOGIST.CATEGORY_NAME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
            value={setValue(data?.user.category, FORM_VALUE_ENUM.CATEGORY)}
          />
        </SelectWrapper>
        <TextContainer>
          <TextElem
            oneLine
            tid="USER.PSYCHO.HEADER.PRICE"
            tvalue={{ value: data?.price }}
            type="bold"
            size="main"
          />
          <TextElem
            oneLine
            type="regular"
            color="textSecondary"
            tid="USER.PSYCHO.HEADER.TIME"
            tvalue={{ value: data?.duration }}
          />
        </TextContainer>
      </InfoContainer>

      {isPack && (
        <InfoContainer>
          <SelectWrapperCount>
            <SelectElem
              placeholder="ORDER.CONSULTATION_COUNT_PLACEHOLDER"
              name={FORM_VALUE_ENUM.CONSULTATION_COUNT}
              onChange={setFieldValue}
              defaultValue={2}
              options={CONSULTATION_TYPE_OPTION}
              title="ORDER.CONSULTATION_COUNT_TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.CONSULTATION_COUNT)}
              error={isFieldError(FORM_VALUE_ENUM.CONSULTATION_COUNT)}
              value={setValue(
                CONSULTATION_TYPE_OPTION,
                FORM_VALUE_ENUM.CONSULTATION_COUNT,
              )}
            />
          </SelectWrapperCount>
          <TextContainer>
            <TextElem
              oneLine
              tid="USER.PSYCHO.HEADER.PRICE"
              tvalue={{ value: consultationPackPrice }}
              type="bold"
              size="main"
            />
            {consultationPackDiscount !== 0 && (
              <TextElem
                oneLine
                type="regular"
                color="textSecondary"
                tid="ORDER.COUNT_VALUE"
                tvalue={{ value: consultationPackDiscount }}
              />
            )}
          </TextContainer>
        </InfoContainer>
      )}
    </Wrapper>
  );
};

const SelectWrapper = styled.div`
  max-width: ${Spacing(80)};
  width: 100%;

  @media screen and (max-width: 760px) {
    max-width: unset;
  }
`;

const SelectWrapperCount = styled(SelectWrapper)`
  margin-top: ${Spacing(3)};
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
  position: relative;
  bottom: 20px;

  @media screen and (max-width: 760px) {
    bottom: 0px;
  }
`;

const InfoContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: end;

  gap: ${Spacing(3)};

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Wrapper = styled.div`
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
`;
