import { UseMutationResult } from 'react-query';
import styled from 'styled-components';
import { ModalElem } from '../../../common/modal';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

import { Center, Content, Input } from './file';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { AlertActionElem } from '../../../common/alert-action';
import draggingFileSvg from '../../../asset/svg/common/arrow-down.svg';
import imageIcon from '../../../asset/svg/file/gallery.svg';
import ImageZoomIcon from '../../../asset/svg/upload/image-zoom.svg';
import refreshFileSvg from '../../../asset/svg/common/refresh.svg';
import trashFileSvg from '../../../asset/svg/common/trash.svg';

export const ImageComponent: React.FC<{
  data?: any;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
  openModalPreview: (e: any) => void;
  closeModalPreview: (e: any) => void;
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error?: string;
  modalPreviewVisible: boolean;
  isDragActive: boolean;
}> = ({
  data,
  getInputProps,
  getRootProps,
  uploadFile,
  defaultValue,
  error,
  handleReselect,
  handleReset,
  modalPreviewVisible,
  openModalPreview,
  closeModalPreview,
  isDragActive,
}) => {
  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  return (
    <>
      {!uploadFile.isLoading && !uploadFile.isSuccess && !defaultValue && (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Content spacing={3}>
            <Center spacing={2}>
              {isDragActive ? (
                <img src={draggingFileSvg} alt="dragging" />
              ) : (
                <img src={imageIcon} alt="image icon" />
              )}
              <TitleText
                tid={
                  isDragActive
                    ? 'COMMON.FILE.DRAGGING'
                    : 'COMMON.FILE.IMAGE_CONTENT_TITLE'
                }
                size="semiSmall"
                color={error || isDragActive ? 'textSecondary' : 'default'}
                type="regular"
              />
              {!isDragActive && (
                <HelperText
                  color={error ? 'error' : 'textSecondary'}
                  type="light"
                  size="small"
                  tid={!!error ? error : 'COMMON.FILE.IMAGE_SIZE_TITLE'}
                />
              )}
            </Center>
          </Content>
        </div>
      )}

      {(!!uploadFile.isSuccess ||
        (!!defaultValue && !uploadFile.isLoading)) && (
        <Background onClick={openModalPreview}>
          <ImageStyled src={data?.url || defaultValue} />
          <SettingContainer onClick={stopPropagation} className="setting">
            <IconSettingBackground onClick={handleReselect}>
              <IconImg src={refreshFileSvg} />
            </IconSettingBackground>
            <IconSettingBackground onClick={handleReset}>
              <IconImg src={trashFileSvg} />
            </IconSettingBackground>
          </SettingContainer>
          <ZoomIcon className="icon">
            <img src={ImageZoomIcon} />
          </ZoomIcon>
          {error && <AlertActionElem tid={error} />}

          <ModalElem open={modalPreviewVisible} onClose={closeModalPreview}>
            <Image src={data?.url || defaultValue} />
          </ModalElem>
        </Background>
      )}
    </>
  );
};

const TitleText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 13px;
  }
`;

const HelperText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 11px;
  }
`;

export const IconImg = styled.img`
  height: 14px;
  width: auto;
`;

export const SettingContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
  z-index: 10;
  cursor: pointer;
  position: absolute;
  right: ${Spacing(3)};
  top: ${Spacing(3)};
`;

export const IconSettingBackground = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.12);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const ImageStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DROPDOWN]}px;
  max-height: 274px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Image = styled.img`
  align-self: center;
  margin: 0 auto;

  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  max-height: 55vh;
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icon {
    display: none;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    position: absolute;
  }
  :not(:has(.setting:hover)):hover {
    .icon {
      display: flex;
    }
  }
`;

export const ZoomIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  align-items: center;
  justify-content: center;
  background: white;
`;
