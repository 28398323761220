import React from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { HeaderContainer } from '../../epic/header';
import { GridElem } from '../../common/grid';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { AdminNewsletterContainer } from '../../epic/admin-newsletter';
import icon from '../../asset/svg/menu-app/directbox-default.svg';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer
          icon={icon}
          title="NEWSLETTER.PAGE.TITLE"
          size="small"
        />

        <LayoutElem size="small">
          <LayoutSectionElem spacing={8}>
            <GridElem>
              <AdminNewsletterContainer />
            </GridElem>
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
