import { HttpRequest } from '../../lib/http';
import { API, filterOption } from './constant';

export const action = (filterOption?: filterOption) => {
  let params = '?';

  if (filterOption?.psychoCategory && filterOption?.psychoCategory !== 'All') {
    params += `psychoCategory=${filterOption.psychoCategory}&`;
  }

  if (filterOption?.methodList) {
    params += `methodList=${filterOption.methodList}&`;
  }

  if (filterOption?.date) {
    params += `date=${filterOption.date}&`;
  }

  if (filterOption?.requestList) {
    params += `requestList=${filterOption.requestList}&`;
  }

  if (filterOption?.languageList) {
    params += `languageList=${filterOption.languageList}&`;
  }

  if (filterOption?.timezone) {
    params += `timezone=${filterOption.timezone}&`;
  }

  if (filterOption?.priceOrder) {
    params += `priceOrder=${filterOption.priceOrder}&`;
  }

  if (filterOption?.popularityOrder) {
    params += `popularityOrder=${filterOption.popularityOrder}&`;
  }

  if (filterOption?.experience) {
    params += `experience=${filterOption.experience}&`;
  }

  if (filterOption?.practice) {
    params += `practice=${filterOption.practice}&`;
  }

  if (filterOption?.priceStart) {
    params += `priceStart=${filterOption.priceStart}&`;
  }

  if (filterOption?.priceEnd) {
    params += `priceEnd=${filterOption.priceEnd}&`;
  }

  if (filterOption?.search) {
    params += `search=${filterOption.search}&`;
  }

  if (filterOption?.startDate) {
    params += `startDate=${filterOption.startDate}&`;
  }

  if (filterOption?.endDate) {
    params += `endDate=${filterOption.endDate}&`;
  }

  params.slice(0, -1);

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
