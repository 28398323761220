import { OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'ADMIN_NEWSLETTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  USER = 'user',
  TITLE = 'title',
  SUB_TITLE = 'subTitle',
  TEXT = 'text',
  USER_TYPE = 'userType',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.USER]?: OPTION_INTER[] | null;
  [FORM_VALUE_ENUM.USER_TYPE]?: OPTION_INTER | null;
  [FORM_VALUE_ENUM.TEXT]?: string | null;
  [FORM_VALUE_ENUM.TITLE]?: string | null;
  [FORM_VALUE_ENUM.SUB_TITLE]?: string | null;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: (type: 'test' | 'all') => `/message/notify?type=${type}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export enum NOTIFY_ENUM {
  ALL = 1,
  PSYCHOLOGIST = 2,
  CLIENT = 3,
  NOT_PAID = 4,
}

export const USER_TYPE_OPTION_LIST = [
  { id: 1, value: NOTIFY_ENUM.ALL, label: i18n.t('NEWSLETTER.USER_TYPE.ALL') },
  {
    id: 2,
    value: NOTIFY_ENUM.PSYCHOLOGIST,
    label: i18n.t('NEWSLETTER.USER_TYPE.PSYCHOLOGIST'),
  },
  {
    id: 3,
    value: NOTIFY_ENUM.CLIENT,
    label: i18n.t('NEWSLETTER.USER_TYPE.CLIENT'),
  },
  {
    id: 4,
    value: NOTIFY_ENUM.NOT_PAID,
    label: i18n.t('NEWSLETTER.USER_TYPE.NOT_PAID'),
  },
];
