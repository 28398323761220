import React from 'react';
import styled from 'styled-components';

import { DATA_INTER, FORM_VALUE_ENUM } from './constant';
import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { FormikValues } from 'formik';
import { Category } from './frame/category';
import { Profile } from './frame/profile';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { HeaderOrder } from './frame/header';
import { Order } from './frame/order';
import { AlertActionElem } from '../../common/alert-action';
import { ReadMoreElem } from '../../common/read-more';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  timeOption: any[];
  isFieldDisabled: Function;
  isTimeLoading: boolean;
  availableDate?: string[];
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  onDeleteFile: Function;
  isPack: boolean;
  consultationPackPrice: number;
  consultationPackDiscount: number;
  handleDateChange: (value: string | Date | null) => void;
  file: File | null;
}> = ({
  data,
  isSuccess,
  isLoading,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  getFieldValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  isError,
  errorMessage,
  availableDate,
  setFile,
  onDeleteFile,
  isPack,
  consultationPackPrice,
  consultationPackDiscount,
  handleDateChange,
  file,
}) => {
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <>
          <Container>
            <HeaderOrder
              id={data?.user.id}
              user={data?.user}
              category={getFieldValue(FORM_VALUE_ENUM.CATEGORY)}
            />
            <Profile user={data?.user} />
            <Category
              consultationPackDiscount={consultationPackDiscount}
              consultationPackPrice={consultationPackPrice}
              isPack={isPack}
              data={data}
              setFieldValue={setFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              setValue={setValue}
              getFieldValue={getFieldValue}
            />
            {data?.description && (
              <InfoContainer>
                <TextContainer>
                  <TextElem
                    lineHeight="1.6em"
                    type="medium"
                    color="textPrimary"
                    size="main"
                    tid="ORDER.ITEM.ABOUT_SERVICE"
                  />
                  {data?.description && (
                    <ReadMoreElem textLimit={450} text={data?.description} />
                  )}
                  {!data?.description && (
                    <TextElem
                      type="regular"
                      color="textSecondary"
                      size="input"
                      tid="ORDER.ITEM.CONDITION_NOT_SET"
                    />
                  )}
                </TextContainer>
              </InfoContainer>
            )}

            {/* <InfoContainer>
              <TextContainer>
                <TextElem
                  lineHeight="1.6em"
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="ORDER.ITEM.WARNING"
                />
                {data?.warning ? (
                  <TextElem
                    type="regular"
                    color="textSecondary"
                    size="input"
                    tid={data?.warning}
                  />
                ) : (
                  <TextElem
                    type="regular"
                    color="textSecondary"
                    size="input"
                    tid="ORDER.ITEM.WARNING_DESCRIPTION"
                  />
                )}
              </TextContainer>
            </InfoContainer> */}

            <Order
              file={file}
              handleDateChange={handleDateChange}
              isPack={isPack}
              onDeleteFile={onDeleteFile}
              setFile={setFile}
              availableDate={availableDate}
              isTimeLoading={isTimeLoading}
              isFieldDisabled={isFieldDisabled}
              timeOption={timeOption}
              isSubmitDisabled={isSubmitDisabled}
              isFieldError={isFieldError}
              getFieldError={getFieldError}
              getFieldValue={getFieldValue}
              setValue={setValue}
              setFieldValue={setFieldValue}
              formik={formik}
            />
          </Container>
        </>
      )}
    </>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(3)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  margin-bottom: ${Spacing(10)};
`;
