import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_GRID_DATA,
  SIZE_GRID_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';

import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { LANDING_ID_ENUM, LANDING_ID_PROPS, LANDING_ID_TYPE } from './constant';
import { i18n } from '../../lib/lang';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';
import { AUTH_SIGNUP_PAGE_PATH } from '../../page/auth-signup';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { STORE_INTER } from '../../data/auth/constant';
import { FlexElem } from '../../common/flex';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import { HOME_PAGE_PATH } from '../../page/landing';
import { TextElem } from '../../common/text';
import { KNOWLEDGE_PAGE_PATH } from '../../page/knowledge';
import { FAQ_PAGE_PATH } from '../../page/faq';

export const Component: React.FC<LANDING_ID_PROPS> = ({ handleNavClick }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  // const activeSection = useIntersectionObserver();
  const history = useHistory();
  const router = useLocation();

  const toggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  const isActivePath = (...path: string[]) =>
    path.some((el) => router.pathname.includes(el));

  const handleRedirect = () => {
    history.push(`/home`);
    scrollToSection(LANDING_ID_ENUM.HOME);
  };

  const handleAction = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    section: LANDING_ID_TYPE,
  ) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    handleNavClick(section);
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  return (
    <HomeHeader>
      <Wrapper isBurgerOpen={isBurgerOpen}>
        <NavBar isBurgerOpen={isBurgerOpen}>
          {/* {Object.values(LANDING_ID_ENUM).map((section, index, array) => (
            <NavLink
              // target="_blank"
              key={section}
              href={
                index === array.length - 2
                  ? '/faq'
                  : index === array.length - 1
                  ? '/knowledge'
                  : `#${section}`
              }
              // onClick={(e) =>
              //   index === array.length - 2 || index === array.length - 1
              //     ? undefined
              //     : handleAction(e, section)
              // }
              isActive={isActivePath()}
            >
              {i18n.t(`LANDING.HOME_HEADER.NAV_BAR.${section}`)}
            </NavLink>
          ))} */}
          <NavLink
            key={LANDING_ID_ENUM.HOME}
            to={HOME_PAGE_PATH}
            isActive={isActivePath(HOME_PAGE_PATH)}
          >
            <TextElem
              color="textSecondary"
              tid="LANDING.HOME_HEADER.NAV_BAR.MAIN"
            />
          </NavLink>
          <NavLink
            key={LANDING_ID_ENUM.PSYCHO_LIST}
            to={PSYCHO_LIST_PAGE_PATH}
            isActive={isActivePath(PSYCHO_LIST_PAGE_PATH)}
          >
            <TextElem
              color="textSecondary"
              tid="LANDING.HOME_HEADER.NAV_BAR.PSYCHO_LIST"
            />
          </NavLink>

          <NavLink
            key={LANDING_ID_ENUM.KNOWLEDGE}
            to={KNOWLEDGE_PAGE_PATH}
            isActive={isActivePath(KNOWLEDGE_PAGE_PATH)}
          >
            <TextElem
              color="textSecondary"
              tid="LANDING.HOME_HEADER.NAV_BAR.KNOWLEDGE"
            />
          </NavLink>

          <NavLink
            key={LANDING_ID_ENUM.FAQ}
            to={FAQ_PAGE_PATH}
            isActive={isActivePath(FAQ_PAGE_PATH)}
          >
            <TextElem
              color="textSecondary"
              tid="LANDING.HOME_HEADER.NAV_BAR.FAQ"
            />
          </NavLink>

          <ButtonsContainerBurger isBurgerOpen={isBurgerOpen}>
            <LinkStyled
              to={
                isAuth
                  ? CONSULTATION_PAGE_PATH_DYNAMIC('')
                  : AUTH_LOGIN_PAGE_PATH
              }
            >
              <LogRegButton type="login">
                {isAuth
                  ? i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN_AUTH')
                  : i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN')}
              </LogRegButton>
            </LinkStyled>
            {!isAuth && (
              <LinkStyled
                to={
                  isAuth
                    ? CONSULTATION_PAGE_PATH_DYNAMIC('')
                    : AUTH_SIGNUP_PAGE_PATH
                }
              >
                <LogRegButton type="register">
                  {i18n.t('LANDING.HOME_HEADER.BUTTONS.REGISTER')}
                </LogRegButton>
              </LinkStyled>
            )}
          </ButtonsContainerBurger>
        </NavBar>
      </Wrapper>
      <LogoContainer>
        <img
          onClick={() => handleRedirect()}
          src="./assets/svg/header/syayvoLogo.svg"
          alt="syaivo Logo"
        />
      </LogoContainer>
      <ButtonsContainer>
        <LinkStyled
          to={
            isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_LOGIN_PAGE_PATH
          }
        >
          <LogRegButton type="login">
            {isAuth
              ? i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN_AUTH')
              : i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN')}
          </LogRegButton>
        </LinkStyled>

        {!isAuth && (
          <LinkStyled
            to={
              isAuth
                ? CONSULTATION_PAGE_PATH_DYNAMIC('')
                : AUTH_SIGNUP_PAGE_PATH
            }
          >
            <LogRegButton type="register">
              {i18n.t('LANDING.HOME_HEADER.BUTTONS.REGISTER')}
            </LogRegButton>
          </LinkStyled>
        )}
      </ButtonsContainer>

      <LastContainer spacing={7}>
        <LoginButtonAdaptive isBurgerOpen={isBurgerOpen}>
          <LinkStyled
            to={
              isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_LOGIN_PAGE_PATH
            }
          >
            <LogRegButtonAdaptive type="login">
              {isAuth
                ? i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN_AUTH')
                : i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN')}
            </LogRegButtonAdaptive>
          </LinkStyled>
        </LoginButtonAdaptive>
        <BurgerIcon
          onClick={toggleBurger}
          aria-expanded={isBurgerOpen}
          isBurgerOpen={isBurgerOpen}
        >
          <span />
          <span />
          <span />
        </BurgerIcon>
      </LastContainer>
    </HomeHeader>
  );
};

const LastContainer = styled(FlexElem)`
  width: max-content;
  display: none;

  @media (max-width: 975px) {
    display: flex;
    align-items: center;
  }
`;

const LoginButtonAdaptive = styled.div<{ isBurgerOpen?: boolean }>`
  display: none;
  @media (max-width: 975px) {
    display: ${({ isBurgerOpen }) => (!isBurgerOpen ? 'block' : 'none')};
  }
`;

const LinkStyled = styled(Link)`
  width: max-content;
  @media (max-width: 975px) {
    width: 30%;
    margin: 0 auto;
    min-width: 150px;
  }
`;

const Wrapper = styled.div<{ isBurgerOpen: boolean }>`
  overflow: auto;
  width: max-content;

  @media (max-width: 975px) {
    display: ${({ isBurgerOpen }) => (isBurgerOpen ? 'flex' : 'none')};
    width: 100%;
    position: absolute;
    top: ${Spacing(21)};
    left: 0;
    background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    background: cover;
    font-size: ${SIZE_GRID_DATA[SIZE_GRID_ENUM.DEFAULT]}px;
  }

  @media (max-height: 400px) {
    height: calc(100vh - ${Spacing(21)});
  }
`;

const NavBar = styled.div<{ isBurgerOpen: boolean }>`
  display: flex;
  height: ${Spacing(9)};
  justify-content: start;
  align-items: center;

  @media (max-width: 975px) {
    display: ${({ isBurgerOpen }) => (isBurgerOpen ? 'flex' : 'none')};
    flex-direction: column;
    // position: absolute;
    // top: ${Spacing(20)};
    // left: 0;
    width: 100%;
    // height: ${Spacing(50)};
    height: max-content;
    background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    background: cover;
    font-size: ${SIZE_GRID_DATA[SIZE_GRID_ENUM.DEFAULT]}px;
    gap: ${Spacing(1)};
  }

  @media (max-height: 400px) and (orientation: landscape) {
    justify-content: start;
  }
`;

const ButtonsContainer = styled.div`
  gap: ${Spacing(2.5)};
  display: flex;
  @media (max-width: 975px) {
    display: none;
  }
`;

const ButtonsContainerBurger = styled.div<{ isBurgerOpen: boolean }>`
  display: none;
  flex-direction: column;
  gap: ${Spacing(2)};

  @media (max-width: 975px) {
    display: flex;

    width: 100%;
    background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    padding: ${Spacing(4)} 0;
  }
`;

const LogRegButton = styled(ButtonElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;

const LogRegButtonAdaptive = styled(LogRegButton)`
  @media screen and (max-width: 355px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(2.25)};
  img {
    max-width: unset;
    cursor: pointer;
  }
  @media screen and (max-width: 380px) {
    img {
      max-width: 95%;
    }
  }
`;

const HomeHeader = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 23px ${Spacing(37)};
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  font-family: 'e-UkraineHead' !important;
  width: 100%;

  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  margin: 0 auto;
  margin-top: ${Spacing(3)};
  display: flex;
  gap: ${Spacing(4)};
  justify-content: space-between;
  align-items: center;
  z-index: 510;

  @media (max-width: 1290px) {
    height: ${Spacing(21)};
    margin-top: unset;
    padding: 0px ${Spacing(20)} 0px ${Spacing(20)};
  }
  @media (max-width: 1161px) {
    padding: 0px ${Spacing(10)} 0px ${Spacing(10)};
  }

  @media (max-width: 768px) {
    padding: 0px ${Spacing(4)};
  }

  @media (max-width: 975px) {
    justify-content: space-between;
  }
`;

const BurgerIcon = styled.div<{ isBurgerOpen: boolean }>`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: ${Spacing(6)};
  height: ${Spacing(6)};
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  span {
    width: 100%;
    height: ${Spacing(0.75)};
    background-color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  @media (max-width: 975px) {
    display: flex;
  }

  ${({ isBurgerOpen }) =>
    isBurgerOpen &&
    css`
      span:nth-child(1) {
        transform: rotate(45deg) translate(6px, 6px);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg) translate(6px, -6px);
      }
    `}
`;

const activeStyles = css`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;
const NavLink = styled(Link)<{ isActive: boolean }>`
  font-family: 'e-Ukraine', sans-serif;
  display: inline-block;
  padding: ${Spacing(2)} ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }

  span {
    ${({ isActive }) => isActive && activeStyles};
  }

  @media (max-width: 1161px) {
    padding: ${Spacing(2)} ${Spacing(2)};
  }
`;
