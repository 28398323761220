import React, { useState } from 'react';

import { Component } from './component';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import useRoomSocket from './useSocket';
import { i18n } from '../../lib/lang';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC<{
  consultationId: string | number;
  isAdmin?: boolean;
  disabled: boolean;
  isOver: boolean;
  isNotPaid: boolean;
}> = ({ consultationId, isAdmin = false, disabled, isOver, isNotPaid }) => {
  const [chatMessage, setChatMessage] = useState<MESSAGE_ITEM_DATA[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [filePreviewList, setFilePreviewList] = useState<
    { id: string; name: string; url: string; mimetype: string }[]
  >([]);

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const userId = state.user?.id;

  const changeMessage = (e: any) => {
    if (e.target.value.trim().length > 300) {
      setError(i18n.t('VALIDATION.MAX_LENGTH', { max: 300 }));
      // } else if (containsForbiddenWords(e.target.value.trim(), offensiveWords)) {
      //   setError(i18n.t('ERROR.OFFENSIVE_WORD'));
    } else {
      setError('');
    }
    setNewMessage(e.target.value);
  };

  const pressEnter = (e: any) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.metaKey &&
      !isSubmitDisabled()
    ) {
      e.preventDefault();
      sendMessageWrapper();
    }
  };

  const sendMessageWrapper = () => {
    if (!error && newMessage.trim()) {
      sendMessage(
        newMessage.trim(),
        filePreviewList.map((file) => file.id),
      );
      setNewMessage('');
      setFilePreviewList([]);
    }
  };

  function isSubmitDisabled() {
    if (isOver || disabled || isNotPaid) return true;
    if (newMessage.length < 1) return true;
    return error;
  }

  const { sendMessage, isConnected } = useRoomSocket({
    consultationId,
    setChatMessage,
    userId,
  });

  const onSuccessUpload = (
    id: string,
    name: string,
    url: string,
    mimetype: string,
  ) => {
    setFilePreviewList((prevList) => [
      ...prevList,
      { id, name, url, mimetype },
    ]);
  };

  const handleFileDelete = (id: string) => {
    setFilePreviewList((prevList) => prevList.filter((file) => file.id !== id));
  };

  return (
    <Component
      isConnected={isConnected}
      chatMessage={chatMessage}
      error={error}
      changeMessage={changeMessage}
      pressEnter={pressEnter}
      newMessage={newMessage}
      sendMessageWrapper={sendMessageWrapper}
      setNewMessage={setNewMessage}
      isSubmitDisabled={isSubmitDisabled}
      userId={userId}
      onSuccessUpload={onSuccessUpload}
      handleFileDelete={handleFileDelete}
      filePreviewList={filePreviewList}
      isAdmin={isAdmin}
      disabled={disabled}
      isOver={isOver}
      isNotPaid={isNotPaid}
    />
  );
};
