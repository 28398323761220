import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

import { Component } from './component';
import {
  ACTION_ERROR_INTER,
  CATEGORY_RAW_INTER,
} from '../profile-psycho/constant';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { action } from './action';

export const Container: React.FC<{
  dateArr?: string[];
  psychoCategory?: CATEGORY_RAW_INTER;
  psychoId: string;
}> = ({ psychoCategory, dateArr, psychoId }) => {
  const [isErrorCategory, setIsErrorCategory] = useState(false);

  const history = useHistory();
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  const onOpenSpChat = () => {
    const widgetButton = document.querySelector(
      '.open-widget',
    ) as HTMLButtonElement;
    if (widgetButton) {
      widgetButton.click();
    }
  };

  const onSuccess = (data: any) => {
    if (window?.dataLayer) {
      window.dataLayer.push({ event: 'consultation_success' });
    }

    history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id));

    onOpenSpChat();
  };

  const patchAction = useMutation(action, { onSuccess });

  const handleClickDate = (date?: string) => {
    if (!psychoCategory) {
      setIsErrorCategory(true);

      setTimeout(() => {
        setIsErrorCategory(false);
      }, 2000);

      return;
    }

    const payload = {
      psychologist: psychoId,
      category: psychoCategory.category,
      time: date,
      duration: psychoCategory.duration,
      price: psychoCategory.price,
      isUnlogged: !isAuth,
    };

    return patchAction.mutate({ payload, isAuth: !!isAuth });
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = patchAction.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      errorMessage={getErrorMessage()}
      isError={patchAction.isError}
      isErrorCategory={isErrorCategory}
      handleClickDate={handleClickDate}
      dateArr={dateArr}
    />
  );
};
