import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { SelectElem } from '../../../common/select';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { ButtonElem } from '../../../common/button';
import { DatePickerElem } from '../../../common/date-picker';
import { OPTION_ITEM_DATA } from '../../../common/select/constant';
import { i18n } from '../../../lib/lang';
import { FileOrderUploader } from './file-uploader';
import { DividerElem } from '../../../common/divider';
import { ModalElem } from '../../../common/modal';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../../theme/value';
import imageLoadingIcon from '../../../asset/svg/upload/image-loading.svg';
import deleteIcon from '../../../asset/svg/file/delete.svg';
import { FlexElem } from '../../../common/flex';

export const Order: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  timeOption: OPTION_ITEM_DATA[];
  isFieldDisabled: Function;
  isTimeLoading: boolean;
  availableDate?: string[];
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  onDeleteFile: Function;
  isPack: boolean;
  handleDateChange: (value: string | Date | null) => void;
  file: File | null;
}> = ({
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  availableDate,
  setFile,
  onDeleteFile,
  isPack,
  handleDateChange,
  file,
}) => {
  const [openModalFile, setOpenModalFile] = useState<boolean>(false);

  const ImagePreview = styled.img`
    object-fit: contain;
    border-radius: ${Spacing(2)};
  `;

  const fileUrl = file ? URL.createObjectURL(file) : null;

  return (
    <Container>
      <TextContainer>
        {!isPack && (
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="ORDER.ITEM.REGISTRATION.TITLE_ORDER"
          />
        )}

        {isPack && (
          <TextElem type="medium" color="textPrimary" size="main">
            {i18n.t('ORDER.ITEM.PACK.TITLE.0')}
            <TextElem
              type="medium"
              color="default"
              size="main"
              tid="ORDER.ITEM.PACK.TITLE.1"
            />
            {i18n.t('ORDER.ITEM.PACK.TITLE.2')}
          </TextElem>
        )}

        <TextElem
          type="regular"
          color="textSecondary"
          size="input"
          tid="ORDER.ITEM.DESCRIPTION"
        />
      </TextContainer>
      <FormContainer>
        <Double>
          <DatePickerElem
            availableDateArr={availableDate}
            isDisabled={isFieldDisabled()}
            name={FORM_VALUE_ENUM.DATE}
            title="ORDER.ITEM.REGISTRATION.DATE"
            placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
            handleChange={handleDateChange}
            value={getFieldValue(FORM_VALUE_ENUM.DATE)}
          />

          <SelectElem
            isDisabled={!getFieldValue(FORM_VALUE_ENUM.DATE)}
            dynamic
            isLoading={isTimeLoading}
            placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIME}
            onChange={setFieldValue}
            options={timeOption}
            title="ORDER.ITEM.REGISTRATION.TIME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
            value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
          />
        </Double>
        <AdditionalContainer>
          <FieldTextAreaElemStyled
            disabled={isFieldDisabled()}
            name={FORM_VALUE_ENUM.COMMENT}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
            title="ORDER.ITEM.REGISTRATION.COMMENT"
            placeholder="ORDER.ITEM.REGISTRATION.COMMENT_PLACEHOLDER"
            errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
            error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
          />
          <FileOrderUploader
            isFile={!!file}
            handleFileChange={(e) => {
              setFile(e);
            }}
            isDisabled={false}
          />
        </AdditionalContainer>
      </FormContainer>
      <ButtonElem
        disabled={isSubmitDisabled()}
        type="submit"
        onClick={formik.handleSubmit}
        tid="ORDER.ITEM.CONFIRM"
      />

      {fileUrl && file && (
        <>
          <FileContainer onClick={() => setOpenModalFile(true)}>
            <FlexElem alignItems="center" spacing={2}>
              <Img src={imageLoadingIcon} alt="image" />
              <TextElemStyled tid={file.name} color="textPrimary" />
            </FlexElem>
            <DeleteIconStyled src={deleteIcon} onClick={() => onDeleteFile()} />
          </FileContainer>
          {openModalFile && (
            <ModalElem
              open={openModalFile}
              onClose={() => setOpenModalFile(false)}
            >
              <ImagePreview src={fileUrl} alt="Preview" />
            </ModalElem>
          )}
        </>
      )}
    </Container>
  );
};

const DeleteIconStyled = styled.img`
  cursor: pointer;
  padding: ${Spacing(1)};
  min-width: ${Spacing(3)};
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
`;

const TextElemStyled = styled(TextElem)`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 350px;
  align-self: center;
  align-items: center;
  gap: ${Spacing(2)};
  border: 1px solid rgb(57, 61, 64);
  padding: 14px;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  cursor: pointer;
`;

const AdditionalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const FieldTextAreaElemStyled = styled(FieldTextAreaElem)`
  @media screen and (max-width: 760px) {
    textarea {
      height: 52px;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-direction: column;
`;
