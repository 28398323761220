import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  ACTION_RESPONSE_INTER,
  FILTER_OPTION,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
  USER_OPTION_LIST_QUERY_KEY,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convert, convertData, convertRole } from './convert';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';

const config = {
  [FORM_VALUE_ENUM.ROLE]: [],
  [FORM_VALUE_ENUM.USERS]: [],
};

export const Container: React.FC<{
  setOption: React.Dispatch<React.SetStateAction<FILTER_OPTION>>;
}> = ({ setOption }) => {
  const [initialValues, setInitialValues] = useState({
    [FORM_VALUE_ENUM.ROLE]: '',
    [FORM_VALUE_ENUM.USERS]: '',
  });
  // const [selectUserValue, setSelectUsertValue] = useState('');
  const [indentify, setIndentify] = useState<string | undefined>();
  const [role, setRole] = useState(0);
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));


  const validate = (values: FormikValues) => validation(values, config);

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: () => {},
  });


  useEffect(() => {
    setOption((prevOption: FILTER_OPTION) => ({
      ...prevOption,
      indentify: indentify,
      role: convertRole(role),
    }));
  }, [formik.values, indentify, role]);

  const isLogged = () => auth.logged;

  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };


  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, e?.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item?.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      onChangeSelect={onChangeSelect}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      isFieldError={isFieldError}
      setIndentify={setIndentify}
      active={role}
      setActive={setRole}
    />
  );
};
