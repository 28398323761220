import React, { ReactNode, useState } from 'react';
import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';

import { API, API_ENUM, API_TYPE, FILE_ITEM_TYPE } from './constant';
import { HttpRequest } from '../../lib/http';
import { ImageComponent } from './view/image';
import { Center, Content, FileComponent } from './view/file';
import { VideoComponent } from './view/video';
import { i18n } from '../../lib/lang';
import {
  isFileTypeValid,
  isPickedFileTypeValid,
} from '../../lib/util/isFileTypeValid';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import { AlertActionElem } from '../../common/alert-action';

import fileIcon from '../../asset/svg/file/folder-open.svg';
import imageIcon from '../../asset/svg/file/gallery.svg';
import videoIcon from '../../asset/svg/file/video-square.svg';

export const Container: React.FC<{
  children?: ReactNode;
  onSuccess?: Function;
  onReset?: Function;
  errorMessage?: string;
  name?: string;
  defaultValue?: string;
  type?: API_TYPE;
  isDot?: boolean;
  title?: string;
  variant?: 'default' | 'icon';
}> = ({
  defaultValue,
  name,
  onSuccess,
  onReset,
  type = API_ENUM.PHOTO,
  title,
  isDot,
  variant,
}) => {
  const [progress, setProgress] = useState(0);
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);

  const { maxSize, acceptedTypes } = FILE_ITEM_TYPE[type];

  const errorFormat = i18n.t('COMMON.FILE.ERROR_FORMAT');
  const errorFileSize = i18n.t('COMMON.FILE.ERROR_SIZE');

  const openModalPreview = (e: any) => {
    e.stopPropagation();
    setModalPreviewVisible(true);
  };

  const closeModalPreview = (e: any) => {
    setModalPreviewVisible(false);
  };

  const action = async (formData: FormData) => {
    console.log('TYPE', type);
    console.log('ACCEPTEDTYPES: ', acceptedTypes);

    const response = await HttpRequest({
      method: API[type].TYPE,
      url: API[type].URL,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });
    setProgress(0);
    return response;
  };

  const uploadFile = useMutation((data: any) => action(data), {
    onSuccess: (d: any) => {
      onSuccess?.(d.id, name);
    },
  });

  const appendFileToFormData = async (file: PickedFile) => {
    console.log('file', file);

    if (
      file.mimeType.startsWith('/image') &&
      file?.width &&
      file.width > 1000
    ) {
      setFileError('COMMON.FILE.ERROR_IMAGE_WIDTH');
    } else {
      try {
        const formData = new FormData();
        let rawFile: File;

        if (file.blob) {
          rawFile = new File([file.blob as BlobPart], file.name, {
            type: file.mimeType,
          });
        } else if (file.data) {
          const base64Response = await fetch(
            `data:${file.mimeType};base64,${file.data}`,
          );
          const blob = await base64Response.blob();
          rawFile = new File([blob as BlobPart], file.name, {
            type: file.mimeType,
          });
        } else {
          return;
        }

        formData.append('file', rawFile, file.name);
        uploadFile.mutate(formData);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClick = async () => {
    setFileError(null);

    // const file = await getFile();
    // if (file) {
    //   appendFileToFormData(file);
    // }
  };

  const handleReselect = async (e: any) => {
    setFileError(null);

    e.stopPropagation();

    const file = await getFile();
    if (file) {
      setFileError(null);

      if (!isPickedFileTypeValid(file, acceptedTypes)) {
        setFileError(errorFormat);
        return;
      }

      if (file.blob && file.blob.size > maxSize) {
        setFileError(errorFileSize);

        return;
      }

      setFileError(null);
      appendFileToFormData(file);

      if (onReset) {
        onReset(name);
      }
    }
  };

  const getFile = async () => {
    setFileError(null);

    try {
      let file = null;

      const result = await FilePicker.pickFiles({ readData: true });

      if (result) {
        file = result.files[0];
      }

      setFileError(null);

      if (type === 'photo' && file?.width && file?.width > 1000) {
        setFileError('COMMON.FILE.ERROR_IMAGE_WIDTH');
      } else {
        return file;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = (e: any) => {
    setFileError(null);

    e.stopPropagation();
    uploadFile.reset();
    onReset?.(name);
    onSuccess?.(null, name);
  };

  const handleDrop = (acceptedFiles: File[], fileRejections: any[]) => {
    if (fileRejections.length > 0) {
      const errors = fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((e: any) => e.message).join(', ');
        return `${file.name} - ${errorMessage}`;
      });
      setFileError(errors.join('\n'));
      return;
    }

    const file = acceptedFiles[0];

    if (!isFileTypeValid(file, acceptedTypes)) {
      setFileError(errorFormat);
    } else if (file.size > maxSize) {
      setFileError(errorFileSize);
    } else {
      setFileError(null);
    }

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      let width = null;

      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          const img = new Image();
          img.onload = () => {
            width = img.width;

            if (width > 1000) {
              setFileError('COMMON.FILE.ERROR_IMAGE_WIDTH');
            } else {
              const formData = new FormData();

              formData.append('file', file);
              uploadFile.mutate(formData);
            }
          };
          img.src = result;
        }
      };
    } else {
      const formData = new FormData();

      formData.append('file', file);
      uploadFile.mutate(formData);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleDrop,
      onDragEnter: () => setFileError(null),
    });

  const commonProps = {
    data: uploadFile.data,
    getInputProps,
    getRootProps,
    uploadFile,
    defaultValue,
    handleClick,
    //@ts-ignore
    error: fileError || (uploadFile?.error?.message as string),
    progress,
    handleReselect,
    handleReset,
    modalPreviewVisible,
    openModalPreview,
    closeModalPreview,
    isDragActive,
    variant,
  };

  const getComponentByType = () => {
    switch (type) {
      case API_ENUM.PHOTO:
        return <ImageComponent {...commonProps} />;
      case API_ENUM.VIDEO:
        return <VideoComponent {...commonProps} />;
      default:
        return <FileComponent {...commonProps} />;
    }
  };

  const getIconByType = () => {
    switch (type) {
      case API_ENUM.PHOTO:
        return <img src={imageIcon} alt="img" />;
      case API_ENUM.VIDEO:
        return <img src={videoIcon} alt="video" />;
      default:
        return <img src={fileIcon} alt="file" />;
    }
  };

  return (
    <>
      {uploadFile.isSuccess && (
        <AlertActionElem type="success" tid="USER.FILE.SUCCESS" />
      )}
      <FileContainer>
        {title && (
          <TitleContainer>
            <TitleText
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />
            {isDot && <Dot />}
          </TitleContainer>
        )}
        <Wrapper
          isDragActive={isDragActive}
          // @ts-ignore
          isError={!!fileError || uploadFile?.error?.message}
          onClick={defaultValue ? openModalPreview : handleClick}
          isSuccess={!!uploadFile.isSuccess || !!defaultValue}
        >
          {getComponentByType()}
          {uploadFile.isLoading && (
            <Content spacing={3}>
              <Center spacing={2}>
                {getIconByType()}
                <HelperText
                  tid="COMMON.FILE.UPLOADING"
                  size="semiSmall"
                  type="regular"
                  color="textSecondary"
                />
              </Center>

              <Progress>
                <ProgressBar width={progress} />
              </Progress>
            </Content>
          )}
        </Wrapper>
      </FileContainer>
    </>
  );
};

const TitleText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 13px;
  }
`;

const HelperText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 11px;
  }
`;

export const ProgressBar = styled.div<{ width: number }>`
  border-radius: 100px;
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  position: absolute;
  ${({ width }) =>
    css`
      width: ${width}%;
    `}
`;

export const Progress = styled.div`
  border-radius: 100px;
  width: 60%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  position: relative;
`;

const Wrapper = styled.div<{
  isSuccess?: boolean;
  isError?: boolean;
  isDragActive?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  min-height: 137px;
  width: 100%;
  gap: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.ERROR]};
      background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    `}

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.FILE]};
  }

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
      background: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
    `}

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      background: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      border: 3px dashed ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    `}
`;

const FileContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  flex-direction: column;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};
`;
