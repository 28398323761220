import React from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

export const Progress: React.FC<{
  stepList: { title: string; description: string; value: number }[];
  step: number;
}> = ({ step, stepList }) => {
  const stepInfo = stepList.find((e) => e.value === step);

  return (
    <ProgressBar>
      <ProgressText>
        <TextElem
          color="textPrimary"
          type="regular"
          size="default"
          tid={stepInfo?.title}
          lineHeight="16px"
        />
        <TextElem type="bold" size="main" lineHeight="16px">
          {step}{' '}
          <TextElem
            color="textSecondary"
            size="main"
            lineHeight="16px"
            tid={`/ ${stepList.length}`}
            type="bold"
          />
        </TextElem>
      </ProgressText>
      <ProgressScale>
        {stepList.map((item) => (
          <ProgressPoint active={item.value <= step} />
        ))}
      </ProgressScale>
      <TextElem
        color="textSecondary"
        type="light"
        size="small"
        tid={stepInfo?.description}
      />
    </ProgressBar>
  );
};

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
  row-gap: ${Spacing(4)};
  width: 100%;

  padding: ${Spacing(5)};

  margin: 0 auto;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
`;

const ProgressText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 420px) {
    span {
      font-size: 13px;
    }
  }
`;

const ProgressScale = styled.div`
  display: grid;
  gap: ${Spacing(1)};
  grid-template-columns: repeat(4, 1fr);
`;

const ProgressPoint = styled.div<{ color?: COLOR_ENUM; active?: boolean }>`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  height: ${Spacing(2)};

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
      `;
    }
    return css`
      opacity: 20%;
      background-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
    `;
  }}
`;
