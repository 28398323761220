import { paymentView } from './../../data/payment/constant';
import {
  CONSULTATION_RAW_INTER,
  CONSULTATION_STATUS_VIEW,
  CONSULTATION_TYPE_VIEW,
} from '../../data/consultation/constant';
import {
  GENDER_ENUM,
  methodViewObj,
  requestViewObj,
} from '../../data/user/constant';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';
import { convertPayment } from '../../data/payment/convert';

export const convert = (
  values: CONSULTATION_RAW_INTER,
  timezone: number,
): any => {
  const { time, day } = convertDateToLocal(values.date, timezone);
  const { time: paymentTime, day: paymentDay } = convertDateToLocal(
    values?.payment?.createDate,
    timezone,
  );

  const data = {
    ...values,
    timeView: time,
    dayView: day,
    paymentTimeView: paymentTime,
    paymentDayView: paymentDay,
    paymentView: paymentView[values.payment?.system],
    paymentStatus: values?.payment?.isPaid ? 'PAID' : 'NOT_PAID',
    statusView: CONSULTATION_STATUS_VIEW[values.status],
    typeView:
      CONSULTATION_TYPE_VIEW[values.type] || 'CONSULTATION.TYPE.ONE_OFF',
    category: CATEGORY_VIEW[values?.category],
    user: values.user && {
      ...values.user,
      methodList: Array.isArray(values?.user?.methodList)
        ? values.user.methodList.map((e) => (e ? methodViewObj[e] : undefined))
        : [],
      requestList: Array.isArray(values?.user?.requestList)
        ? values.user.requestList.map((e) =>
            e ? requestViewObj[e] : undefined,
          )
        : [],
      needGender: values?.user?.needGender
        ? GENDER_VIEW[values.user.needGender as GENDER_ENUM]
        : '',
      timezone: values?.user?.timezone
        ? TIMEZONE_VIEW[values.user.timezone as TIMEZONE_ENUM]
        : '',
      numberTimezone: values?.user?.timezone,
      needAge: getYearLabel(values?.user?.needAge || 0),
    },
    psychologist: {
      ...values.psychologist,
      gender:
        values?.psychologist?.gender &&
        GENDER_VIEW[values.psychologist.gender as GENDER_ENUM],
      timezone:
        values?.psychologist?.timezone &&
        TIMEZONE_VIEW[values?.psychologist?.timezone as TIMEZONE_ENUM],
      age: getYearLabel(values?.psychologist?.age || 0),
    },
  };

  return data;
};
