import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual } from 'swiper/modules';
import 'swiper/css';

import { AlertActionElem } from '../../common/alert-action';
import { Card } from './frame/card';
import { PSYCHO_ITEM_DATA_INTER } from './constant';
import { Spacing } from '../../theme';
import { SkeletonCardElem } from '../../common/skeleton-сard';
import { TextElem } from '../../common/text';
import { FlexElem } from '../../common/flex';
import { ButtonElem } from '../../common/button';
import { EmptyElem } from './frame/empty-elem';
import arrowSvg from '../../asset/svg/common/arrow-swipe.svg';

export const Component: React.FC<{
  cardArrToRender: PSYCHO_ITEM_DATA_INTER[];
  cardToRenderMobile: PSYCHO_ITEM_DATA_INTER[];
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  selectedCategory?: string | number;
  errorMessage?: string;
  onClickCardHandler: (id: string) => void;
  isButtonRender: boolean;
  handleMoreRender: () => void;
}> = ({
  cardArrToRender,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  selectedCategory,
  onClickCardHandler,
  isButtonRender,
  handleMoreRender,
  cardToRenderMobile,
}) => {
  return (
    <>
      {isError && <AlertActionElem text={errorMessage} />}
      {isLoading && (
        <ListContainer>
          {[1, 2, 3].map(() => (
            <SkeletonCardElem />
          ))}
        </ListContainer>
      )}
      {cardArrToRender.length < 1 && !isLoading && <EmptyElem />}
      {isSuccess && (
        <>
          <ListContainerDesctop>
            {cardArrToRender.map((e) => {
              return e.availableDayArr && e.availableDayArr.length > 0 ? (
                <Card
                  onClickHandler={onClickCardHandler}
                  selectedCategory={selectedCategory}
                  key={e?.id}
                  id={e?.id}
                  data={e}
                />
              ) : null;
            })}
            {isButtonRender && (
              <ButtonElemStyled
                padding="20px 31px"
                sizeText="small"
                type="submit"
                tid="PSYCHO_LIST.LIST.MORE_BUTTON"
                onClick={handleMoreRender}
              />
            )}
          </ListContainerDesctop>
          {cardToRenderMobile.length > 1 && (
            <HelperContainer
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <img src={arrowSvg} alt="arrow left" />
              <TextElem
                size="chip"
                color="textSecondary"
                tid="PSYCHO_LIST.LIST.MOBILE_HELPER_TEXT"
              />
              <ArrowRight src={arrowSvg} alt="arrow right" />
            </HelperContainer>
          )}
          <ListContainerMobile>
            <Swiper
              modules={[Virtual]}
              slidesPerView={1}
              spaceBetween={30}
              preventClicks={false}
              simulateTouch={false}
              virtual
            >
              {cardToRenderMobile.map((e, index) => {
                return e.availableDayArr && e.availableDayArr.length > 0 ? (
                  <SwiperSlide key={e.id} virtualIndex={index}>
                    <Card
                      onClickHandler={onClickCardHandler}
                      selectedCategory={selectedCategory}
                      key={e?.id}
                      id={e?.id}
                      data={e}
                    />
                  </SwiperSlide>
                ) : (
                  <></>
                );
              })}
            </Swiper>
          </ListContainerMobile>
          {/* <ListContainerMobile>
            {cardToRenderMobile.map((e) => (
              <Card
                onClickHandler={onClickCardHandler}
                selectedCategory={selectedCategory}
                key={e?.id}
                id={e?.id}
                data={e}
              />
            ))}
          </ListContainerMobile> */}
          {cardToRenderMobile.length > 1 && (
            <HelperContainerBottom
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <img src={arrowSvg} alt="arrow left" />
              <TextElem
                size="chip"
                color="textSecondary"
                tid="PSYCHO_LIST.LIST.MOBILE_HELPER_TEXT"
              />
              <ArrowRight src={arrowSvg} alt="arrow right" />
            </HelperContainerBottom>
          )}
        </>
      )}
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  max-width: max-content;
  span {
    font-size: 15px;
  }
`;

const HelperContainer = styled(FlexElem)`
  display: none;

  @media screen and (max-width: 630px) {
    display: flex;
    margin-bottom: ${Spacing(3)};
  }
`;

const HelperContainerBottom = styled(HelperContainer)`
  margin-bottom: unset;
  margin-top: ${Spacing(3)};
`;

const ArrowRight = styled.img`
  transform: rotate(180deg);
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(3)};
  justify-items: center;
`;

const ListContainerDesctop = styled(ListContainer)`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(3)};
  justify-items: center;

  @media screen and (max-width: 630px) {
    display: none;
  }
`;

const ListContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: 630px) {
    display: block;
  }
`;
