import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { i18n } from '../../lib/lang';
import { FILE_INTER } from '../file/constant';

export const USER_LIST_SHORT_MODULE_NAME = 'USER_LIST_SHORT_MODULE_NAME';

// export enum REQUEST_ENUM {
//   DEPRESSION = 1,
//   ANXIETY = 2,
//   PHOBIAS = 3,
//   FEAR = 33,
//   UNCONTROLLED_ANGER = 4,
//   GRIEF = 5,
//   LOSS = 31,
//   TRAUMA = 6,
//   PTSD = 32,
//   BURNOUT = 7,
//   STRESS = 8,
//   LOSS_OF_MEANING = 9,
//   SELF_ESTIMATION = 10,
//   UNCERTAINTY = 11,
//   PSYCHOSOMATICS = 12,
//   ADDICTION = 13,
//   EATING_DISORDERS = 14,
//   PANIC_ATTACKS = 15,
//   INTIMACY_PROBLEMS = 16,
//   PARENTING = 17,
//   EMOTIONAL_INTELLIGENCE = 18,
//   SPEECH_THERAPY_SESSIONS = 19,
//   SEXUAL_EDUCATION = 20,
//   NEURO_CORRECTION = 21,
//   SUPERVISION = 22,
//   MENTAL_DISORDERS = 23,
//   ENURESIS = 24,
//   ENCOPRESIS = 25,
//   SLEEP_DISORDERS = 26,
//   AUTOAGGRESSION = 27,
//   ADHD = 28,
//   SPEECH_DISORDERS = 29,
//   AUTISM = 30,
// }

export enum REQUEST_ENUM {
  FAMILY_CONFLICTS = 1,
  PARENT_CHILD_ISSUES = 2,
  LONELINESS = 3,
  SELF_IDENTIFICATION_CRISIS = 4,
  UNSTABLE_SELF_ESTIMATION = 5,
  LACK_OF_CONFIDENCE = 6,
  EMOTIONAL_DIFFICULTIES = 7,
  LOSS_OF_A_LOVED_ONE = 8,
  DIVORCE = 9,
  GRIEF = 10,
  PSYCHODIAGNOSTICS = 11,
  PSYCHOCORRECTION = 12,
  CAREER_COUNSELING = 13,
  ENURESIS = 14,
  ONCOPSYCHOLOGY = 15,
  DEPRESSION = 16,
  POSTPARTUM_DEPRESSION = 17,
  PANIC_ATTACKS = 18,
  ANXIETY = 19,
  PHOBIAS = 20,
  OCD = 21,
  GAD = 22,
  BIPOLAR_DISORDER = 23,
  EATING_DISORDERS = 24,
  MRO = 25,
  ZRO = 26,
  ADHD = 27,
  RAS = 28,
  SELF_HARM = 29,
  NERVOUS_TICS = 30,
  PSYCHOSOMATICS = 31,
  OBSESSIVE_STATES = 32,
  GAMING_ADDICTION = 33,
  NARCISSISTIC_DISORDER = 34,
  ALCOHOL_ADDICTION = 35,
  PTSD = 36,
  SCHIZOPHRENIA = 37,
  RELEASE_FROM_CAPTIVITY = 38,
  SUICIDAL_THOUGHTS = 39,
  MILITARY_PSYCHOLOGICAL_ASSISTANCE = 40,
  CHILDREN_UNDER_15 = 41,
  TEENAGERS_15_17 = 42,
  // EMDR = 43,
  // CBT = 44,
  // DBT = 45,
  // SCHEMA_THERAPY = 46,
  // SEXOLOGY = 47,
  // FAMILY_THERAPY = 48,
  // ART_THERAPY = 49,
  DRUG_ADDICTION = 50,
  IRRITABLE_BOWEL_SYNDROME = 51,
  HYPOCHONDRIA = 52,
  // POSITIVE_PSYCHOTHERAPY = 53,
  INSOMNIA = 54,
  ZMR = 55,
  ZPR = 56,
  INTELLECTUAL_DIFFICULTIES = 57,
  LEARNING_DIFFICULTIES = 58,
  WORK_WITH_SYNDROMES = 59,
}

// export enum METHOD_ENUM {
//   COGNITIVE_PROCESSING_THERAPY = 1,
//   PSYCHOANALYSIS = 2,
//   EXISTENTIAL_THERAPY = 3,
//   MILITARY_PSYCHOLOGY = 4,
//   ONCOPSYCHOLOGY = 5,
//   NEUROPSYCHOLOGY = 6,
//   TRANSACTIONAL_ANALYSIS = 7,
//   PSYCHODRAMA = 8,
//   ART_THERAPY = 9,
//   GESTALT_THERAPY = 10,
//   POSITIVE_PSYCHOTHERAPY = 11,
//   TRAUMA_FOCUSED_PSYCHOTHERAPY = 12,
//   EMDR_THERAPY = 13,
//   CATATHYMIC_IMAGINATIVE_THERAPY = 14,
//   SYSTEMIC_FAMILY_THERAPY = 15,
//   NLP = 16,
//   CLIENT_CENTERED_PSYCHOTHERAPY = 17,
//   INTEGRATIVE_PSYCHOTHERAPY = 18,
//   CAREER_GUIDANCE = 19,
//   LOGOTHERAPY = 20,
//   SOLUTION_FOCUSED_THERAPY = 21,
//   PSYCHIATRY = 22,
//   CRISIS_COUNSELING = 23,
//   FAMILY_PSYCHOTHERAPY = 24,
// }

export enum METHOD_ENUM {
  COGNITIVE_BEHAVIORAL_THERAPY = 1,
  GESTALT_THERAPY = 2,
  PSYCHOANALYSIS = 3,
  RELATIONAL_PSYCHOANALYSIS = 4,
  TRANSACTIONAL_ANALYSIS = 5,
  EXISTENTIAL_ANALYSIS = 6,
  PSYCHODRAMA = 7,
  ART_THERAPY = 8,
  POSITIVE_PSYCHOTHERAPY = 9,
  EMDR_THERAPY = 10,
  TRAUMA_FOCUSED_THERAPY = 11,
  BODY_ORIENTED_THERAPY = 12, // Тілесно-рухова терапія
  CATATHYMIC_IMAGINATIVE_THERAPY = 13,
  FAMILY_THERAPY = 14,
  SYSTEMIC_FAMILY_THERAPY = 15,
  CLIENT_CENTERED_THERAPY = 16,
  LOGOTHERAPY = 17,
  INTEGRATIVE_THERAPY = 18,
  NARRATIVE_EXPOSURE_THERAPY = 19, // Наративно-експозиційна терапія
  DIALECTICAL_BEHAVIOR_THERAPY = 20,
  SOLUTION_FOCUSED_THERAPY = 21,
  ACCEPTANCE_AND_COMMITMENT_THERAPY = 22, // Терапія прийняття та відповідальності
  MINDFULNESS = 23,
  EMOTION_FOCUSED_THERAPY = 24,
  NEUROPSYCHOLOGY = 25,
  SEXOLOGY = 26,
  NEURO_LINGUISTIC_PROGRAMMING = 27, // НЛП
  COACHING = 28,
  CAREER_COUNSELING = 29, // Профорієнтація
  MILITARY_PSYCHOLOGY = 30,
  ONCOPSYCHOLOGY = 31,
  PERINATAL_PSYCHOLOGY = 32,
  CRISIS_COUNSELING = 33,
  SPEECH_THERAPY = 34, // Консультація логопеда
  PSYCHIATRY = 35, // Консультація психіатра

  CBT = 36,
  DBT = 37,
  SCHEMA_THERAPY = 38,
}

export enum GENDER_ENUM {
  MALE = 1,
  FEMALE = 2,
}

export enum LGBT_FRIENDLY_ENUM {
  YES = 1,
  FEMALE = 2,
}

export enum LANGUAGE_ENUM {
  UA = 'UA',
  RU = 'RU',
  POL = 'POL',
  ENG = 'ENG',
  DE = 'DE',
}

// export const methodViewObj = {
//   [METHOD_ENUM.COGNITIVE_PROCESSING_THERAPY]: 'Когнітивна терапія обробки',
//   [METHOD_ENUM.PSYCHOANALYSIS]: 'Психоаналіз',
//   [METHOD_ENUM.EXISTENTIAL_THERAPY]: 'Екзистенційна терапія',
//   [METHOD_ENUM.MILITARY_PSYCHOLOGY]: 'Військова психологія',
//   [METHOD_ENUM.ONCOPSYCHOLOGY]: 'Онкопсихологія',
//   [METHOD_ENUM.NEUROPSYCHOLOGY]: 'Нейропсихологія',
//   [METHOD_ENUM.TRANSACTIONAL_ANALYSIS]: 'Транзакційний аналіз',
//   [METHOD_ENUM.PSYCHODRAMA]: 'Психодрама',
//   [METHOD_ENUM.ART_THERAPY]: 'АРТ-терапія',
//   [METHOD_ENUM.GESTALT_THERAPY]: 'Гештальт-терапія',
//   [METHOD_ENUM.POSITIVE_PSYCHOTHERAPY]: 'Позитивна психотерапія',
//   [METHOD_ENUM.TRAUMA_FOCUSED_PSYCHOTHERAPY]: 'Травмафокусована психотерапія',
//   [METHOD_ENUM.EMDR_THERAPY]: 'EMDR терапія травми',
//   [METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY]:
//     'Кататимно-імагінативна терапія',
//   [METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY]: 'Системно-сімейна терапія',
//   [METHOD_ENUM.NLP]: 'НЛП',
//   [METHOD_ENUM.CLIENT_CENTERED_PSYCHOTHERAPY]: 'Клієнт-центрована психотерапія',
//   [METHOD_ENUM.INTEGRATIVE_PSYCHOTHERAPY]: 'Інтегративна психотерапія',
//   [METHOD_ENUM.CAREER_GUIDANCE]: 'Профорієнтація',
//   [METHOD_ENUM.LOGOTHERAPY]: 'Логотерапія',
//   [METHOD_ENUM.SOLUTION_FOCUSED_THERAPY]: 'Терапія рішення',
//   [METHOD_ENUM.PSYCHIATRY]: 'Психіатрія',
//   [METHOD_ENUM.CRISIS_COUNSELING]: 'Кризове консультування',
//   [METHOD_ENUM.FAMILY_PSYCHOTHERAPY]: 'Сімейна психотерапія',
// };

export const methodViewObj = {
  [METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY]: 'Когнітивно-поведінкова терапія',
  [METHOD_ENUM.GESTALT_THERAPY]: 'Гештальт терапія',
  [METHOD_ENUM.PSYCHOANALYSIS]: 'Психоаналіз',
  [METHOD_ENUM.RELATIONAL_PSYCHOANALYSIS]: 'Реляційний психоаналіз',
  [METHOD_ENUM.TRANSACTIONAL_ANALYSIS]: 'Транзакційний аналіз',
  [METHOD_ENUM.EXISTENTIAL_ANALYSIS]: 'Екзистенційний аналіз',
  [METHOD_ENUM.PSYCHODRAMA]: 'Психодрама',
  [METHOD_ENUM.ART_THERAPY]: 'АРТ-терапія',
  [METHOD_ENUM.POSITIVE_PSYCHOTHERAPY]: 'Позитивна психотерапія',
  [METHOD_ENUM.EMDR_THERAPY]: 'Терапія травми EMDR',
  [METHOD_ENUM.TRAUMA_FOCUSED_THERAPY]: 'Травмафокусована терапія',
  [METHOD_ENUM.BODY_ORIENTED_THERAPY]: 'Тілесно-рухова терапія',
  [METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY]:
    'Кататимно-імагінативна терапія',
  [METHOD_ENUM.FAMILY_THERAPY]: 'Сімейна терапія',
  [METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY]: 'Системно-сімейна терапія',
  [METHOD_ENUM.CLIENT_CENTERED_THERAPY]: 'Клієнт-центрована терапія',
  [METHOD_ENUM.LOGOTHERAPY]: 'Логотерапія',
  [METHOD_ENUM.INTEGRATIVE_THERAPY]: 'Інтегративна терапія',
  [METHOD_ENUM.NARRATIVE_EXPOSURE_THERAPY]: 'Наративно-експозиційна терапія',
  [METHOD_ENUM.DIALECTICAL_BEHAVIOR_THERAPY]: 'Діалектично-поведінкова терапія',
  [METHOD_ENUM.SOLUTION_FOCUSED_THERAPY]:
    'Терапія, зосереджена на рішенні (BSFT)',
  [METHOD_ENUM.ACCEPTANCE_AND_COMMITMENT_THERAPY]:
    'Терапія прийняття та відповідальності (АСТ)',
  [METHOD_ENUM.MINDFULNESS]: 'Mindfulness',
  [METHOD_ENUM.EMOTION_FOCUSED_THERAPY]: 'Емоційно фокусована терапія (EFT)',
  [METHOD_ENUM.NEUROPSYCHOLOGY]: 'Нейропсихологія',
  [METHOD_ENUM.SEXOLOGY]: 'Сексологія',
  [METHOD_ENUM.NEURO_LINGUISTIC_PROGRAMMING]:
    'Нейро-лінгвістичне програмування (НЛП)',
  [METHOD_ENUM.COACHING]: 'Коучинг',
  [METHOD_ENUM.CAREER_COUNSELING]: 'Профорієнтація',
  [METHOD_ENUM.MILITARY_PSYCHOLOGY]: 'Військова психологія',
  [METHOD_ENUM.ONCOPSYCHOLOGY]: 'Онкопсихологія',
  [METHOD_ENUM.PERINATAL_PSYCHOLOGY]: 'Перинатальна психологія',
  [METHOD_ENUM.CRISIS_COUNSELING]: 'Кризове консультування',
  [METHOD_ENUM.SPEECH_THERAPY]: 'Консультація логопеда',
  [METHOD_ENUM.PSYCHIATRY]: 'Консультація психіатра',

  [METHOD_ENUM.CBT]: 'Сімейна терапія',
  [METHOD_ENUM.DBT]: 'Арт-терапія',
  [METHOD_ENUM.SCHEMA_THERAPY]: 'Позитивна психотерапія',
};

export const requestViewObj = {
  [REQUEST_ENUM.FAMILY_CONFLICTS]: "Конфлікти в сім'ї",
  [REQUEST_ENUM.PARENT_CHILD_ISSUES]: 'Дитячо-батьківські проблеми',
  [REQUEST_ENUM.LONELINESS]: 'Самотність',
  [REQUEST_ENUM.SELF_IDENTIFICATION_CRISIS]: 'Криза самовизначення',
  [REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION]: 'Нестабільна самооцінка',
  [REQUEST_ENUM.LACK_OF_CONFIDENCE]: 'Невпевненість у собі',
  [REQUEST_ENUM.EMOTIONAL_DIFFICULTIES]: 'Емоційні труднощі',
  [REQUEST_ENUM.LOSS_OF_A_LOVED_ONE]: 'Втрата близької людини',
  [REQUEST_ENUM.DIVORCE]: 'Розлучення',
  [REQUEST_ENUM.GRIEF]: 'Горе',
  [REQUEST_ENUM.PSYCHODIAGNOSTICS]: 'Психодіагностика',
  [REQUEST_ENUM.PSYCHOCORRECTION]: 'Психокорекція',
  [REQUEST_ENUM.CAREER_COUNSELING]: 'Профорієнтація',
  [REQUEST_ENUM.ENURESIS]: 'Енурез',
  [REQUEST_ENUM.ONCOPSYCHOLOGY]: 'Онкопсихологія',
  [REQUEST_ENUM.DEPRESSION]: 'Депресія',
  [REQUEST_ENUM.POSTPARTUM_DEPRESSION]: 'Післяпологова депресія',
  [REQUEST_ENUM.PANIC_ATTACKS]: 'Панічні атаки',
  [REQUEST_ENUM.ANXIETY]: 'Тривожність',
  [REQUEST_ENUM.PHOBIAS]: 'Фобії',
  [REQUEST_ENUM.OCD]: 'ОКР',
  [REQUEST_ENUM.GAD]: 'ГТР',
  [REQUEST_ENUM.BIPOLAR_DISORDER]: 'БАР',
  [REQUEST_ENUM.EATING_DISORDERS]: 'РХП',
  [REQUEST_ENUM.MRO]: 'МРО',
  [REQUEST_ENUM.ZRO]: 'ЗРО',
  [REQUEST_ENUM.ADHD]: 'РДУГ',
  [REQUEST_ENUM.RAS]: 'РАС',
  [REQUEST_ENUM.SELF_HARM]: 'Селфхарм',
  [REQUEST_ENUM.NERVOUS_TICS]: 'Нервові тики',
  [REQUEST_ENUM.PSYCHOSOMATICS]: 'Психосоматика',
  [REQUEST_ENUM.OBSESSIVE_STATES]: "Нав'язливі стани",
  [REQUEST_ENUM.GAMING_ADDICTION]: 'Ігрова залежність',
  [REQUEST_ENUM.NARCISSISTIC_DISORDER]: 'Нарцистичний розлад',
  [REQUEST_ENUM.ALCOHOL_ADDICTION]: 'Алкогольна залежність',
  [REQUEST_ENUM.PTSD]: 'ПТСР',
  [REQUEST_ENUM.SCHIZOPHRENIA]: 'Шизофренія',
  [REQUEST_ENUM.RELEASE_FROM_CAPTIVITY]: 'Вихід з полону',
  [REQUEST_ENUM.SUICIDAL_THOUGHTS]: 'Суїцидальні думки',
  [REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE]:
    'Психологічна допомога військовим',
  [REQUEST_ENUM.CHILDREN_UNDER_15]: 'Діти до 15 років',
  [REQUEST_ENUM.TEENAGERS_15_17]: 'Підлітки 15-17 років',
  [REQUEST_ENUM.DRUG_ADDICTION]: 'Наркотична залежність',
  [REQUEST_ENUM.IRRITABLE_BOWEL_SYNDROME]: 'Синдром подразненого кишківника',
  [REQUEST_ENUM.HYPOCHONDRIA]: 'Іпохондрія',
  [REQUEST_ENUM.INSOMNIA]: 'Інсомнія',
  [REQUEST_ENUM.ZMR]: 'ЗМР',
  [REQUEST_ENUM.ZPR]: 'ЗПР',
  [REQUEST_ENUM.INTELLECTUAL_DIFFICULTIES]: 'Інтелектуальні труднощі',
  [REQUEST_ENUM.LEARNING_DIFFICULTIES]: 'Труднощі у навчанні',
  [REQUEST_ENUM.WORK_WITH_SYNDROMES]: 'Робота з синдромами',
};

export const methodSlugViewObj = {
  [METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY]: 'kohnityvno-povedinkova-terapiya',
  [METHOD_ENUM.GESTALT_THERAPY]: 'heshtalt-terapiya',
  [METHOD_ENUM.PSYCHOANALYSIS]: 'psychoanaliz',
  [METHOD_ENUM.RELATIONAL_PSYCHOANALYSIS]: 'relyatsiynyy-psychoanaliz',
  [METHOD_ENUM.TRANSACTIONAL_ANALYSIS]: 'tranzaktsiynyy-analiz',
  [METHOD_ENUM.EXISTENTIAL_ANALYSIS]: 'ekzystentsiynyy-analiz',
  [METHOD_ENUM.PSYCHODRAMA]: 'psykhodrama',
  [METHOD_ENUM.ART_THERAPY]: 'art-terapiya',
  [METHOD_ENUM.POSITIVE_PSYCHOTHERAPY]: 'pozytyvna-psykhoterapiya',
  [METHOD_ENUM.EMDR_THERAPY]: 'emdr-terapiya-travmy',
  [METHOD_ENUM.TRAUMA_FOCUSED_THERAPY]: 'travmafokusovana-terapiya',
  [METHOD_ENUM.BODY_ORIENTED_THERAPY]: 'tilesno-rukhova-terapiya',
  [METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY]:
    'katatymno-imahinatyvna-terapiya',
  [METHOD_ENUM.FAMILY_THERAPY]: 'simeyna-terapiya',
  [METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY]: 'systemno-simeyna-terapiya',
  [METHOD_ENUM.CLIENT_CENTERED_THERAPY]: 'kliyent-tsentrovana-terapiya',
  [METHOD_ENUM.LOGOTHERAPY]: 'lohoterapiya',
  [METHOD_ENUM.INTEGRATIVE_THERAPY]: 'intehratyvna-terapiya',
  [METHOD_ENUM.NARRATIVE_EXPOSURE_THERAPY]: 'naratyvno-ekspozytsiyna-terapiya',
  [METHOD_ENUM.DIALECTICAL_BEHAVIOR_THERAPY]:
    'dilektychno-povedinkova-terapiya',
  [METHOD_ENUM.SOLUTION_FOCUSED_THERAPY]: 'terapiya-zoseredzhena-na-rishenni', // BSFT
  [METHOD_ENUM.ACCEPTANCE_AND_COMMITMENT_THERAPY]:
    'terapiya-pryynyattya-ta-vidpovidalnosti', // АСТ
  [METHOD_ENUM.MINDFULNESS]: 'mindfulness',
  [METHOD_ENUM.EMOTION_FOCUSED_THERAPY]: 'emotsiyno-fokusovana-terapiya', // EFT
  [METHOD_ENUM.NEUROPSYCHOLOGY]: 'neyropsykholohiya',
  [METHOD_ENUM.SEXOLOGY]: 'seksolohiya',
  [METHOD_ENUM.NEURO_LINGUISTIC_PROGRAMMING]:
    'neyro-linhvistychne-prohramuvannya', // НЛП
  [METHOD_ENUM.COACHING]: 'kouchynh',
  [METHOD_ENUM.CAREER_COUNSELING]: 'proforiyentatsiya',
  [METHOD_ENUM.MILITARY_PSYCHOLOGY]: 'viyskova-psykholohiya',
  [METHOD_ENUM.ONCOPSYCHOLOGY]: 'onkopsykholohiya',
  [METHOD_ENUM.PERINATAL_PSYCHOLOGY]: 'perynatalna-psykholohiya',
  [METHOD_ENUM.CRISIS_COUNSELING]: 'kryzove-konsultuvannya',
  [METHOD_ENUM.SPEECH_THERAPY]: 'konsultatsiya-lohopeda',
  [METHOD_ENUM.PSYCHIATRY]: 'konsultatsiya-psykhiatra',
  [METHOD_ENUM.CBT]: 'kpt',
  [METHOD_ENUM.DBT]: 'dpt',
  [METHOD_ENUM.SCHEMA_THERAPY]: 'skhema-terapiya',
};

export const requestSlugReverseViewObj: { [key: string]: REQUEST_ENUM } = {
  'konflikty-v-simyi': REQUEST_ENUM.FAMILY_CONFLICTS,
  'dityacho-batkivski-problemy': REQUEST_ENUM.PARENT_CHILD_ISSUES,
  samotnist: REQUEST_ENUM.LONELINESS,
  'kryza-samovyznachennya': REQUEST_ENUM.SELF_IDENTIFICATION_CRISIS,
  'nestabilna-samootsinka': REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION,
  'nevpevnenist-u-sobi': REQUEST_ENUM.LACK_OF_CONFIDENCE,
  'emotsiyni-trudnoschi': REQUEST_ENUM.EMOTIONAL_DIFFICULTIES,
  'vtrata-blyzkoi-lyudyny': REQUEST_ENUM.LOSS_OF_A_LOVED_ONE,
  rozluchennya: REQUEST_ENUM.DIVORCE,
  hore: REQUEST_ENUM.GRIEF,
  psykkhodiagnostyka: REQUEST_ENUM.PSYCHODIAGNOSTICS,
  psykkhokorektsiya: REQUEST_ENUM.PSYCHOCORRECTION,
  'profesiyena-oriyentatsiya': REQUEST_ENUM.CAREER_COUNSELING,
  enurez: REQUEST_ENUM.ENURESIS,
  onkopsykholohiya: REQUEST_ENUM.ONCOPSYCHOLOGY,
  depresiya: REQUEST_ENUM.DEPRESSION,
  'pislyap-depresiya': REQUEST_ENUM.POSTPARTUM_DEPRESSION,
  'panichni-ataky': REQUEST_ENUM.PANIC_ATTACKS,
  tryvozhnist: REQUEST_ENUM.ANXIETY,
  fobiyi: REQUEST_ENUM.PHOBIAS,
  okr: REQUEST_ENUM.OCD,
  htr: REQUEST_ENUM.GAD,
  bar: REQUEST_ENUM.BIPOLAR_DISORDER,
  rkhp: REQUEST_ENUM.EATING_DISORDERS,
  mro: REQUEST_ENUM.MRO,
  zro: REQUEST_ENUM.ZRO,
  rduh: REQUEST_ENUM.ADHD,
  ras: REQUEST_ENUM.RAS,
  selfharm: REQUEST_ENUM.SELF_HARM,
  'nervovi-tiky': REQUEST_ENUM.NERVOUS_TICS,
  psykkhosomatyka: REQUEST_ENUM.PSYCHOSOMATICS,
  "nav'yazlyvi-stany": REQUEST_ENUM.OBSESSIVE_STATES,
  'ihrova-zaleznist': REQUEST_ENUM.GAMING_ADDICTION,
  'nartsystychnyy-rozlad': REQUEST_ENUM.NARCISSISTIC_DISORDER,
  'alkoholna-zaleznist': REQUEST_ENUM.ALCOHOL_ADDICTION,
  ptsr: REQUEST_ENUM.PTSD,
  shyzofreniya: REQUEST_ENUM.SCHIZOPHRENIA,
  'vykhid-z-polonu': REQUEST_ENUM.RELEASE_FROM_CAPTIVITY,
  'suyitsydalni-dumky': REQUEST_ENUM.SUICIDAL_THOUGHTS,
  'psykholohichna-dopomoha-viyskovym':
    REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE,
  'dity-do-15-rokiv': REQUEST_ENUM.CHILDREN_UNDER_15,
  'pidlitky-15-17-rokiv': REQUEST_ENUM.TEENAGERS_15_17,
  'narkotychna-zaleznist': REQUEST_ENUM.DRUG_ADDICTION,
  'syndrom-podraznenoho-kyshkivnyka': REQUEST_ENUM.IRRITABLE_BOWEL_SYNDROME,
  ipokhondriya: REQUEST_ENUM.HYPOCHONDRIA,
  insomniya: REQUEST_ENUM.INSOMNIA,
  zmr: REQUEST_ENUM.ZMR,
  zpr: REQUEST_ENUM.ZPR,
  'intelektualni-trudnoschi': REQUEST_ENUM.INTELLECTUAL_DIFFICULTIES,
  'trudnoschi-u-navchanni': REQUEST_ENUM.LEARNING_DIFFICULTIES,
  'robota-z-syndromamy': REQUEST_ENUM.WORK_WITH_SYNDROMES,
};

export const methodSlugReverseViewObj: { [key: string]: METHOD_ENUM } = {
  'kohnityvno-povedinkova-terapiya': METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY,
  'heshtalt-terapiya': METHOD_ENUM.GESTALT_THERAPY,
  psychoanaliz: METHOD_ENUM.PSYCHOANALYSIS,
  'relyatsiynyy-psychoanaliz': METHOD_ENUM.RELATIONAL_PSYCHOANALYSIS,
  'tranzaktsiynyy-analiz': METHOD_ENUM.TRANSACTIONAL_ANALYSIS,
  'ekzystentsiynyy-analiz': METHOD_ENUM.EXISTENTIAL_ANALYSIS,
  psykhodrama: METHOD_ENUM.PSYCHODRAMA,
  'art-terapiya': METHOD_ENUM.ART_THERAPY,
  'pozytyvna-psykhoterapiya': METHOD_ENUM.POSITIVE_PSYCHOTHERAPY,
  'emdr-terapiya-travmy': METHOD_ENUM.EMDR_THERAPY,
  'travmafokusovana-terapiya': METHOD_ENUM.TRAUMA_FOCUSED_THERAPY,
  'tilesno-rukhova-terapiya': METHOD_ENUM.BODY_ORIENTED_THERAPY,
  'katatymno-imahinatyvna-terapiya': METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY,
  'simeyna-terapiya': METHOD_ENUM.FAMILY_THERAPY,
  'systemno-simeyna-terapiya': METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY,
  'kliyent-tsentrovana-terapiya': METHOD_ENUM.CLIENT_CENTERED_THERAPY,
  lohoterapiya: METHOD_ENUM.LOGOTHERAPY,
  'intehratyvna-terapiya': METHOD_ENUM.INTEGRATIVE_THERAPY,
  'naratyvno-ekspozytsiyna-terapiya': METHOD_ENUM.NARRATIVE_EXPOSURE_THERAPY,
  'dilektychno-povedinkova-terapiya': METHOD_ENUM.DIALECTICAL_BEHAVIOR_THERAPY,
  'terapiya-zoseredzhena-na-rishenni': METHOD_ENUM.SOLUTION_FOCUSED_THERAPY,
  'terapiya-pryynyattya-ta-vidpovidalnosti':
    METHOD_ENUM.ACCEPTANCE_AND_COMMITMENT_THERAPY,
  mindfulness: METHOD_ENUM.MINDFULNESS,
  'emotsiyno-fokusovana-terapiya': METHOD_ENUM.EMOTION_FOCUSED_THERAPY,
  neyropsykholohiya: METHOD_ENUM.NEUROPSYCHOLOGY,
  seksolohiya: METHOD_ENUM.SEXOLOGY,
  'neyro-linhvistychne-prohramuvannya':
    METHOD_ENUM.NEURO_LINGUISTIC_PROGRAMMING,
  kouchynh: METHOD_ENUM.COACHING,
  proforiyentatsiya: METHOD_ENUM.CAREER_COUNSELING,
  'viyskova-psykholohiya': METHOD_ENUM.MILITARY_PSYCHOLOGY,
  onkopsykholohiya: METHOD_ENUM.ONCOPSYCHOLOGY,
  'perynatalna-psykholohiya': METHOD_ENUM.PERINATAL_PSYCHOLOGY,
  'kryzove-konsultuvannya': METHOD_ENUM.CRISIS_COUNSELING,
  'konsultatsiya-lohopeda': METHOD_ENUM.SPEECH_THERAPY,
  'konsultatsiya-psykhiatra': METHOD_ENUM.PSYCHIATRY,
  kpt: METHOD_ENUM.CBT,
  dpt: METHOD_ENUM.DBT,
  'skhema-terapiya': METHOD_ENUM.SCHEMA_THERAPY,
};

export const requestSlugViewObj = {
  [REQUEST_ENUM.FAMILY_CONFLICTS]: 'konflikty-v-simyi',
  [REQUEST_ENUM.PARENT_CHILD_ISSUES]: 'dityacho-batkivski-problemy',
  [REQUEST_ENUM.LONELINESS]: 'samotnist',
  [REQUEST_ENUM.SELF_IDENTIFICATION_CRISIS]: 'kryza-samovyznachennya',
  [REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION]: 'nestabilna-samootsinka',
  [REQUEST_ENUM.LACK_OF_CONFIDENCE]: 'nevpevnenist-u-sobi',
  [REQUEST_ENUM.EMOTIONAL_DIFFICULTIES]: 'emotsiyni-trudnoschi',
  [REQUEST_ENUM.LOSS_OF_A_LOVED_ONE]: 'vtrata-blyzkoi-lyudyny',
  [REQUEST_ENUM.DIVORCE]: 'rozluchennya',
  [REQUEST_ENUM.GRIEF]: 'hore',
  [REQUEST_ENUM.PSYCHODIAGNOSTICS]: 'psykkhodiagnostyka',
  [REQUEST_ENUM.PSYCHOCORRECTION]: 'psykkhokorektsiya',
  [REQUEST_ENUM.CAREER_COUNSELING]: 'profesiyena-oriyentatsiya',
  [REQUEST_ENUM.ENURESIS]: 'enurez',
  [REQUEST_ENUM.ONCOPSYCHOLOGY]: 'onkopsykholohiya',
  [REQUEST_ENUM.DEPRESSION]: 'depresiya',
  [REQUEST_ENUM.POSTPARTUM_DEPRESSION]: 'pislyap пологова-depresiya',
  [REQUEST_ENUM.PANIC_ATTACKS]: 'panichni-ataky',
  [REQUEST_ENUM.ANXIETY]: 'tryvozhnist',
  [REQUEST_ENUM.PHOBIAS]: 'fobiyi',
  [REQUEST_ENUM.OCD]: 'okr',
  [REQUEST_ENUM.GAD]: 'htr',
  [REQUEST_ENUM.BIPOLAR_DISORDER]: 'bar',
  [REQUEST_ENUM.EATING_DISORDERS]: 'rkhp',
  [REQUEST_ENUM.MRO]: 'mro',
  [REQUEST_ENUM.ZRO]: 'zro',
  [REQUEST_ENUM.ADHD]: 'rduh',
  [REQUEST_ENUM.RAS]: 'ras',
  [REQUEST_ENUM.SELF_HARM]: 'selfharm',
  [REQUEST_ENUM.NERVOUS_TICS]: 'nervovi-tiky',
  [REQUEST_ENUM.PSYCHOSOMATICS]: 'psykkhosomatyka',
  [REQUEST_ENUM.OBSESSIVE_STATES]: "nav'yazlyvi-stany",
  [REQUEST_ENUM.GAMING_ADDICTION]: 'ihrova-zaleznist',
  [REQUEST_ENUM.NARCISSISTIC_DISORDER]: 'nartsystychnyy-rozlad',
  [REQUEST_ENUM.ALCOHOL_ADDICTION]: 'alkoholna-zaleznist',
  [REQUEST_ENUM.PTSD]: 'ptsr',
  [REQUEST_ENUM.SCHIZOPHRENIA]: 'shyzofreniya',
  [REQUEST_ENUM.RELEASE_FROM_CAPTIVITY]: 'vykhid-z-polonu',
  [REQUEST_ENUM.SUICIDAL_THOUGHTS]: 'suyitsydalni-dumky',
  [REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE]:
    'psykholohichna-dopomoha-viyskovym',
  [REQUEST_ENUM.CHILDREN_UNDER_15]: 'dity-do-15-rokiv',
  [REQUEST_ENUM.TEENAGERS_15_17]: 'pidlitky-15-17-rokiv',
  [REQUEST_ENUM.DRUG_ADDICTION]: 'narkotychna-zaleznist',
  [REQUEST_ENUM.IRRITABLE_BOWEL_SYNDROME]: 'syndrom-podraznenoho-kyshkivnyka',
  [REQUEST_ENUM.HYPOCHONDRIA]: 'ipokhondriya',
  [REQUEST_ENUM.INSOMNIA]: 'insomniya',
  [REQUEST_ENUM.ZMR]: 'zmr',
  [REQUEST_ENUM.ZPR]: 'zpr',
  [REQUEST_ENUM.INTELLECTUAL_DIFFICULTIES]: 'intelektualni-trudnoschi',
  [REQUEST_ENUM.LEARNING_DIFFICULTIES]: 'trudnoschi-u-navchanni',
  [REQUEST_ENUM.WORK_WITH_SYNDROMES]: 'robota-z-syndromamy',
};

export const genderViewObj = {
  [GENDER_ENUM.MALE]: {
    value: 1,
    label: 'Чоловіча',
  },
  [GENDER_ENUM.FEMALE]: {
    value: 2,
    label: 'Жіноча',
  },
};

export const languageViewObj = {
  [LANGUAGE_ENUM.UA]: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
  [LANGUAGE_ENUM.RU]: i18n.t('USER.USER.SELECT.LANGUAGE.RU'),
  [LANGUAGE_ENUM.POL]: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
  [LANGUAGE_ENUM.ENG]: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
  [LANGUAGE_ENUM.DE]: i18n.t('USER.USER.SELECT.LANGUAGE.DE'),
};

export const languageViewObjReverse = {
  Українська: LANGUAGE_ENUM.UA,
  Російська: LANGUAGE_ENUM.RU,
  Польська: LANGUAGE_ENUM.POL,
  Англійська: LANGUAGE_ENUM.ENG,
  Німецька: LANGUAGE_ENUM.DE,
};

export interface PSYCHOLOGIST_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  lastName?: string;
  nickname?: string;
  phone?: string | null;
  aboutMe?: string | null;
  aboutMyPath?: string | null;
  aboutMyService?: string | null;
  age?: number | null;
  gender?: GENDER_ENUM | null;
  timezone?: number;
  languageList?: LANGUAGE_ENUM[];
  requestList: REQUEST_ENUM[];
  requestCustom?: string | null;
  lgbtFrendly?: boolean | null;
  methodList?: METHOD_ENUM[] | null;
  methodCustom?: string | null;
  needGender?: GENDER_ENUM | null;
  photo?: FILE_INTER;
  email: string;
  isFop?: boolean;
  accountNumber?: string;
  bankName?: string;
  role: USER_ROLE;
  password: string;
  url?: string;
  yearExperience?: number | null;
  hourPractice?: number | null;
}

export interface PSYCHOLOGIST_ITEM_DATA_INTER
  extends Omit<
    PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
    | 'methodList'
    | 'requestList'
    | 'gender'
    | 'lgbtFrendly'
    | 'categoryList'
    | 'languageList'
  > {
  methodList?: OPTION_INTER[] | null;
  requestList?: OPTION_INTER[] | null;
  languageList?: OPTION_INTER[] | null;
  categoryList?: OPTION_INTER;
  gender?: number | null;
  lgbtFrendly?: string | null;
}

export interface USER_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  lastName?: string;
  nickname?: string;
  phone?: string | null;
  about?: string | null;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  timezone?: number;
  languageList?: LANGUAGE_ENUM[];
  requestList: REQUEST_ENUM[];
  requestCustom?: string | null;
  lgbtFrendly?: boolean | null;
  methodList?: METHOD_ENUM[] | null;
  methodCustom?: string | null;
  needGender?: GENDER_ENUM | null;
  needAge?: number | null;
  accountNumber?: string;
  bankName?: string;
  photo?: {
    url: string;
    id: string;
    name: string;
  };
  email: string;
  role: USER_ROLE;
  password: string;
  psychoCategory?: CATEGORY_ENUM;
  numberTimezone?: number; // по идее надо в ITEM_DATA_INTER
}

export interface USER_ITEM_LIST_DATA_RAW_INTER {
  list: USER_ITEM_DATA_RAW_INTER[];
}

export interface OPTION_INTER {
  value?: number | any | string;
  label?: string;
}

export interface USER_ITEM_DATA_INTER
  extends Omit<
    USER_ITEM_DATA_RAW_INTER,
    | 'methodList'
    | 'requestList'
    | 'gender'
    | 'needGender'
    | 'lgbtFrendly'
    | 'languageList'
  > {
  methodList?: OPTION_INTER[] | null;
  requestList?: OPTION_INTER[] | null;
  languageList?: OPTION_INTER[] | null;
  gender?: OPTION_INTER;
  needGender?: number | null;
  lgbtFrendly?: string | null;
  psychoCategoryView?: OPTION_INTER;
}

export interface USER_ITEM_LIST_DATA_INTER {
  list: USER_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/user/data',
  },
  LIST_SHORT: {
    TYPE: 'GET',
    URL: (param: string) => `/user/option-list/${param}`,
  },
};

export enum USER_ROLE {
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export type USER_TYPE = `${USER_ROLE}`;

export const CHIP_USER_VIEW = {
  [USER_ROLE.ADMIN]: 'rgba(32, 113, 187, 0.1)',
  [USER_ROLE.PSYCHOLOGIST]: 'rgba(49, 173, 76, 0.1)',
  [USER_ROLE.USER]: 'rgba(146, 78, 39, 0.1)',
};

export const CHIP_TEXT_USER_VIEW = {
  [USER_ROLE.ADMIN]: '#2071BB',
  [USER_ROLE.PSYCHOLOGIST]: '#31AD4C',
  [USER_ROLE.USER]: '#924E27',
};

export const USER_ROLE_OPTION_LIST = [
  {
    value: USER_ROLE.PSYCHOLOGIST,
    label: `${i18n.t('USER.ROLE.PSYCHOLOGIST')}`,
  },

  {
    value: USER_ROLE.USER,
    label: `${i18n.t('USER.ROLE.USER')}`,
  },

  {
    value: USER_ROLE.ADMIN,
    label: `${i18n.t('USER.ROLE.ADMIN')}`,
  },
];
