export function getHourLabel(hours: number | null): string | null {
    if (!hours) {
      return null;
    }
  
    // Останні дві цифри числа для перевірки на 11-19
    const lastTwoDigits = hours % 100;
  
    // Якщо останні дві цифри між 11 і 19, завжди використовуємо "годин"
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${hours} годин`;
    }
  
    // Остання цифра числа
    const lastDigit = hours % 10;
  
    // Вибір правильної форми слова
    if (lastDigit === 1) {
      return `${hours} година`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `${hours} години`;
    } else {
      return `${hours} годин`;
    }
  }

  export function getHourLabelWithoutNumber(hours: number | null): string | null {
    if (!hours) {
      return null;
    }
  
    // Останні дві цифри числа для перевірки на 11-19
    const lastTwoDigits = hours % 100;
  
    // Якщо останні дві цифри між 11 і 19, завжди використовуємо "годин"
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${hours} годин`;
    }
  
    // Остання цифра числа
    const lastDigit = hours % 10;
  
    // Вибір правильної форми слова
    if (lastDigit === 1) {
      return ` година`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return ` години`;
    } else {
      return ` годин`;
    }
  }