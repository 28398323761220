import React, { useState } from 'react';

import styled, { css } from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { FieldTextElem } from '../../common/field-text';
import { FormikValues } from 'formik';
import userIcon from '../../asset/svg/menu-app/user.svg';
import searchIcon from '../../asset/svg/common/search-new.svg';
import { TabSecondaryElem } from '../../common/tab-secondary';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
  setIndentify: Function;
  active: number;
  setActive: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  setValue,
  setIndentify,
  active,
  setActive,
}) => {
  const onChangeUser = (e: any) => {
    setIndentify(e.target.value);
    setFieldValue(FORM_VALUE_ENUM.USERS, e.target.value);
  };

  const tabList = [
    { tid: 'USER.ROLE.ALL', value: 0 },
    { tid: 'USER.ROLE.USER', value: 1 },
    { tid: 'USER.ROLE.PSYCHOLOGIST', value: 2 },
    { tid: 'USER.ROLE.ADMIN', value: 3 },
  ];

  return (
    <Container>
      <HeaderContainer>
        <img src={userIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="medium"
          size="medium"
          tid="USER.LIST.HEADER"
        />
      </HeaderContainer>
      <SelectContainer>
        <TabSecondaryElem
          tabList={tabList}
          active={active}
          setActive={setActive}
        />
        <StyledFieldText
          name={FORM_VALUE_ENUM.USERS}
          onChange={onChangeUser}
          onBlur={formik.handleBlur}
          placeholder="USER.FILTER.SEARCH"
          value={getFieldValue(FORM_VALUE_ENUM.USERS)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
        />
      </SelectContainer>
    </Container>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 166px;
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  // max-width: 830px;
  max-width: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(2)};
    // input {
    //   max-width: unset;
    // }
    max-width: unset;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const StyledFieldText = styled(FieldTextElem)`
  position: relative;
  input {
    padding-left: ${Spacing(10)};
    min-height: unset;
    max-width: 233px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    &:hover:not(:focus) {
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    }
    &:focus-within {
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    }
    line-height: 14px;
  }
  &::before {
    content: '';
    position: absolute;
    top: ${Spacing(4)};
    left: ${Spacing(4)};
    width: 16px;
    height: 16px;
    background-image: url(${searchIcon});
    background-size: cover;
    pointer-events: none;
    z-index: 15;
  }

  @media screen and (max-width: 1160px) {
    input {
      max-width: unset;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1068px;
  display: flex;
  justify-content: space-between;
  gap: ${Spacing(3)};
  margin: 0 auto;
  margin-top: ${Spacing(6)};
  margin-bottom: ${Spacing(5)};
  align-self: end;
  align-items: center;
  // padding: 0 ${Spacing(6)};

  @media screen and (max-width: 1400px) {
    justify-content: center;
  }

  @media screen and (max-width: 1160px) {
    padding: 0 ${Spacing(4)};
  }
`;
