import settingIcon from '../../asset/svg/menu-app/setting.svg';
import settingActiveIcon from '../../asset/svg/menu-app/setting-active.svg';
import userIcon from '../../asset/svg/menu-app/user.svg';
import userActiveIcon from '../../asset/svg/menu-app/user-active.svg';
import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import paymentActiveIcon from '../../asset/svg/menu-app/payment-active.svg';
import accountIcon from '../../asset/svg/menu-app/account.svg';
import accountActiveIcon from '../../asset/svg/menu-app/account-active.svg';
import noteIcon from '../../asset/svg/menu-app/note.svg';
import noteActiveIcon from '../../asset/svg/menu-app/note-active.svg';
import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import calendarActiveIcon from '../../asset/svg/menu-app/calendar-active.svg';
import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';
import psychoActiveIcon from '../../asset/svg/menu-app/psycho-list-active.svg';
import homeIcon from '../../asset/svg/menu-app/home.svg';
import homeActiveIcon from '../../asset/svg/menu-app/home-active.svg';
import newsLetterIcon from '../../asset/svg/menu-app/directbox-default.svg';
import newsLetterIconActive from '../../asset/svg/menu-app/directbox-default-active.svg';

import { USER_ROLE } from '../../data/auth/constant';

import { ACCOUNT_UPDATE_PAGE_PATH } from '../../page/account-profile-update';
import { CONSULTATION_LIST_PAGE_PATH } from '../../page/consultation-list';
import { PAYMENT_PAGE_PATH } from '../../page/payment';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import { SCHEDULE_PAGE_PATH } from '../../page/schedule';
import { SETTINGS_PAGE_PATH } from '../../page/settings';
import { USER_PAGE_PATH } from '../../page/user';
import { HOME_PAGE_PATH } from '../../page/landing';
import { NEWSLETTER_PAGE_PATH } from '../../page/newsletter';

export const MODULE_NAME = 'DESKTOP_MENU_APP_MODULE_NAME';

export const getMenuItems = (userRole: USER_ROLE) => [
  {
    title: 'NAVIGATION.TAB_APP.HOME',
    icon: homeIcon,
    iconActive: homeActiveIcon,
    path: HOME_PAGE_PATH,
    isVisible: true,
  },
  {
    title: 'NAVIGATION.TAB_APP.CONSULTATION_LIST',
    icon: noteIcon,
    iconActive: noteActiveIcon,
    path: CONSULTATION_LIST_PAGE_PATH,
    isVisible: true,
  },
  {
    title: 'NAVIGATION.TAB_APP.PSYCHO_LIST',
    icon: psychoIcon,
    iconActive: psychoActiveIcon,
    path: PSYCHO_LIST_PAGE_PATH,
    isVisible: userRole === USER_ROLE.USER,
  },
  {
    title: 'NAVIGATION.TAB_APP.PAYMENT',
    icon: paymentIcon,
    iconActive: paymentActiveIcon,
    path: PAYMENT_PAGE_PATH,
    isVisible: userRole === USER_ROLE.ADMIN,
  },
  {
    title: 'NAVIGATION.TAB_APP.USER',
    icon: userIcon,
    iconActive: userActiveIcon,
    path: USER_PAGE_PATH,
    isVisible: userRole === USER_ROLE.ADMIN,
  },
  {
    title: 'NAVIGATION.TAB_APP.SCHEDULE',
    icon: calendarIcon,
    iconActive: calendarActiveIcon,
    path: SCHEDULE_PAGE_PATH,
    isVisible: userRole === USER_ROLE.PSYCHOLOGIST,
  },
  {
    title: 'NAVIGATION.TAB_APP.ACCOUNT_UPDATE',
    icon: accountIcon,
    iconActive: accountActiveIcon,
    path: ACCOUNT_UPDATE_PAGE_PATH,
    isVisible:
      userRole === USER_ROLE.USER || userRole === USER_ROLE.PSYCHOLOGIST,
  },
  {
    title: 'NAVIGATION.TAB_APP.NEWSLETTER',
    icon: newsLetterIcon,
    iconActive: newsLetterIconActive,
    path: NEWSLETTER_PAGE_PATH,
    isVisible: userRole === USER_ROLE.ADMIN,
  },
  {
    title: 'NAVIGATION.TAB_APP.SETTINGS',
    icon: settingIcon,
    iconActive: settingActiveIcon,
    path: SETTINGS_PAGE_PATH,
    isVisible:
      userRole === USER_ROLE.USER ||
      userRole === USER_ROLE.PSYCHOLOGIST ||
      userRole === USER_ROLE.ADMIN,
  },
];
