import { components, DropdownIndicatorProps } from 'react-select';

import indicatorIcon from '../../../asset/svg/common/selectIndicator.svg';
import indicatorIconWhite from '../../../asset/svg/common/selectIndicator-white.svg';
import closeIcon from '../../../asset/svg/common/close-white.svg';
import indicatorTimezoneIcon from '../../../asset/svg/common/selectIndicator-timezone.svg';

export const multiValueContainer = ({ selectProps, data }: any) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex(
    (selected: any) => selected.label === label,
  );
  const isLastSelected = index === allSelected?.length - 1;
  const labelSuffix = isLastSelected ? '' : ', ';

  return (
    <span translate="no">
      {label}
      <span>{labelSuffix}</span>
    </span>
  );
};

export const ClearIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={closeIcon} alt="close" />
    </components.ClearIndicator>
  );
};

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  const isOpen = props.selectProps.menuIsOpen;
  return (
    <components.DropdownIndicator {...props}>
      {isOpen ? (
        <img src={indicatorIconWhite} alt="indicator" />
      ) : (
        <img src={indicatorIcon} alt="indicator" />
      )}
    </components.DropdownIndicator>
  );
};

export const DropdownTimezoneIndicator: React.FC<DropdownIndicatorProps> = (
  props,
) => {
  const isOpen = props.selectProps.menuIsOpen;
  return (
    <components.DropdownIndicator {...props}>
      {isOpen ? (
        <img src={indicatorTimezoneIcon} alt="indicator timezone" />
      ) : (
        <img src={indicatorTimezoneIcon} alt="indicator timezone" />
      )}
    </components.DropdownIndicator>
  );
};
