import { methodViewObj, requestViewObj } from '../../data/user/constant';
import { TIMEZONE_ENUM, TIMEZONE_VIEW } from '../../data/user/timezone';
import { getYearLabel } from '../../lib/util/yearConvert';
import { DATA_INTER, PSYCHO_ITEM_LIST_DATA_RAW_INTER } from './constant';

export const convert = (list: PSYCHO_ITEM_LIST_DATA_RAW_INTER): DATA_INTER => {
  return {
    list: list.list?.map((e) => ({
      ...e,
      requestList: e.requestList
        ? e.requestList?.map((item) => requestViewObj[item])
        : [],
      methodListString: e.requestList
        ? e.methodList?.map((item) => methodViewObj[item])
        : [],
      timezone: TIMEZONE_VIEW[e.timezone as TIMEZONE_ENUM],
      age: getYearLabel(Number(e.age)),
    })),
    isEmpty: !list.list?.length,
  };
};
