import {
  LANGUAGE_ENUM,
  languageViewObjReverse,
} from './../../data/user/constant';
import {
  CATEGORY_ENUM,
  CATEGORY_VIEW_SLUG,
} from './../profile-psycho-create-category/constant';
import dayjs from 'dayjs';

import {
  requestSlugReverseViewObj,
  requestViewObj,
  methodSlugReverseViewObj,
  methodViewObj,
  OPTION_INTER,
  methodSlugViewObj,
  METHOD_ENUM,
  requestSlugViewObj,
  languageViewObj,
} from '../../data/user/constant';
import {
  convertOptionArray,
  convertOptionStringArray,
} from '../../data/user/convert';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { CATEGORY_VIEW_SLUG_REVERSE } from '../profile-psycho-create-category/constant';
import { CATEGORY_VIEW } from '../profile-psycho/constant';
import {
  EXPRERIANCE_ENUM,
  EXPRERIANCE_VIEW,
  FILTER_OPTION_NAME,
  FORM_VALUE_ENUM,
  PRACTICE_ENUM,
  PRACTICE_VIEW,
  PRICE_ORDER_ENUM,
  PRICE_ORDER_VIEW,
} from '../psycho-filter/constant';
import { PSYCHO_FILTER_FORM_VALUE_INTER } from '../psycho-filter';

export const parseOptionValue = (
  value: string | null,
  viewObj: any,
  enumObj: any,
) => {
  if (!value) return null;

  return {
    label: viewObj[value as keyof typeof viewObj],
    value: enumObj ? Number(value) : value,
  };
};

export const parseOptionListValue = (
  value: string | null,
  reverseObj: any,
  viewObj: any,
) => {
  if (!value) return null;
  return convertOptionArray(
    value.split(',').map((e) => reverseObj[e]),
    viewObj,
  );
};

export const convert = (
  filterParam: Record<string, null | string>,
  defaultCategoryOption: OPTION_INTER,
) => {
  const dateRangeDefault = filterParam[FILTER_OPTION_NAME.DATE_START] &&
    filterParam[FILTER_OPTION_NAME.DATE_END] && [
      dayjs(filterParam[FILTER_OPTION_NAME.DATE_START]),
      dayjs(filterParam[FILTER_OPTION_NAME.DATE_END]),
    ];

  const dateDefault =
    filterParam[FILTER_OPTION_NAME.DATE] &&
    filterParam[FILTER_OPTION_NAME.DATE];

  const filterCategory = filterParam[FILTER_OPTION_NAME.CATEGORY]
    ? {
        label:
          CATEGORY_VIEW[
            CATEGORY_VIEW_SLUG_REVERSE[filterParam[FILTER_OPTION_NAME.CATEGORY]]
          ],
        value:
          CATEGORY_VIEW_SLUG_REVERSE[filterParam[FILTER_OPTION_NAME.CATEGORY]],
      }
    : null;

  const category = filterCategory || defaultCategoryOption;

  const converedData = {
    [FORM_VALUE_ENUM.SEARCH]: filterParam[FILTER_OPTION_NAME.SEARCH],
    [FORM_VALUE_ENUM.PRICE_START]: filterParam[FILTER_OPTION_NAME.PRICE_START]
      ? Number(filterParam[FILTER_OPTION_NAME.PRICE_START])
      : null,
    [FORM_VALUE_ENUM.PRICE_END]: filterParam[FILTER_OPTION_NAME.PRICE_END]
      ? Number(filterParam[FILTER_OPTION_NAME.PRICE_END])
      : null,

    [FORM_VALUE_ENUM.PRACTICE]: parseOptionValue(
      filterParam[FILTER_OPTION_NAME.PRACTICE],
      PRACTICE_VIEW,
      PRACTICE_ENUM,
    ),

    [FORM_VALUE_ENUM.LANGUAGE_LIST]: filterParam[FORM_VALUE_ENUM.LANGUAGE_LIST]
      ? filterParam[FORM_VALUE_ENUM.LANGUAGE_LIST].split(',').map((e) => ({
          label: e,
          value:
            languageViewObjReverse[e as keyof typeof languageViewObjReverse],
        }))
      : null,

    [FORM_VALUE_ENUM.EXPERIENCE]: parseOptionValue(
      filterParam[FILTER_OPTION_NAME.EXPERIENCE],
      EXPRERIANCE_VIEW,
      EXPRERIANCE_ENUM,
    ),

    [FORM_VALUE_ENUM.PRICE_ORDER]: parseOptionValue(
      filterParam[FILTER_OPTION_NAME.PRICE_ORDER],
      PRICE_ORDER_VIEW,
      PRICE_ORDER_ENUM,
    ),

    [FORM_VALUE_ENUM.DATE_START]: filterParam[FILTER_OPTION_NAME.DATE_START],
    [FORM_VALUE_ENUM.DATE_END]: filterParam[FILTER_OPTION_NAME.DATE_END],

    [FORM_VALUE_ENUM.REQUEST_LIST]: parseOptionListValue(
      filterParam[FILTER_OPTION_NAME.REQUEST],
      requestSlugReverseViewObj,
      requestViewObj,
    ),

    [FORM_VALUE_ENUM.METHOD_LIST]: parseOptionListValue(
      filterParam[FILTER_OPTION_NAME.METHOD],
      methodSlugReverseViewObj,
      methodViewObj,
    ),
    [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: category,
    [FORM_VALUE_ENUM.TIMEZONE]: parseOptionValue(
      filterParam[FILTER_OPTION_NAME.TIMEZONE],
      TIMEZONE_VIEW,
      TIMEZONE_ENUM,
    ),
    [FORM_VALUE_ENUM.POPULARITY_ORDER]: parseOptionValue(
      filterParam[FILTER_OPTION_NAME.POPULARITY_ORDER],
      PRICE_ORDER_VIEW,
      PRICE_ORDER_ENUM,
    ),
    [FORM_VALUE_ENUM.DATE_DEFAULT]: dateRangeDefault,
    [FORM_VALUE_ENUM.DATE]: dateDefault,
  };

  return converedData;
};
export const convertToUrl = (
  fieldData: Partial<PSYCHO_FILTER_FORM_VALUE_INTER>,
) => {
  const converedData = {
    [FILTER_OPTION_NAME.CATEGORY]:
      fieldData[FORM_VALUE_ENUM.PSYCHO_CATEGORY]?.value &&
      CATEGORY_VIEW_SLUG[
        fieldData[FORM_VALUE_ENUM.PSYCHO_CATEGORY]?.value as CATEGORY_ENUM
      ],
    [FILTER_OPTION_NAME.DATE]: fieldData[FORM_VALUE_ENUM.DATE] ?? undefined,

    [FILTER_OPTION_NAME.DATE_END]:
      fieldData[FORM_VALUE_ENUM.DATE_END] ?? undefined,
    [FILTER_OPTION_NAME.DATE_START]:
      fieldData[FORM_VALUE_ENUM.DATE_START] ?? undefined,
    [FILTER_OPTION_NAME.EXPERIENCE]:
      fieldData[FORM_VALUE_ENUM.EXPERIENCE]?.value ?? undefined,
    [FILTER_OPTION_NAME.METHOD]: fieldData[FORM_VALUE_ENUM.METHOD_LIST]?.length
      ? fieldData[FORM_VALUE_ENUM.METHOD_LIST]
          .map((e) => methodSlugViewObj[e.value as METHOD_ENUM])
          .join(',')
      : undefined,
    [FILTER_OPTION_NAME.LANGUAGE_LIST]: fieldData[FORM_VALUE_ENUM.LANGUAGE_LIST]
      ?.length
      ? fieldData[FORM_VALUE_ENUM.LANGUAGE_LIST]
          .map((e) => languageViewObj[e.value as LANGUAGE_ENUM])
          .join(',')
      : undefined,
    [FILTER_OPTION_NAME.REQUEST]: fieldData[FORM_VALUE_ENUM.REQUEST_LIST]
      ?.length
      ? fieldData[FORM_VALUE_ENUM.REQUEST_LIST]
          .map((e) => requestSlugViewObj[e.value as METHOD_ENUM])
          .join(',')
      : undefined,
    [FILTER_OPTION_NAME.POPULARITY_ORDER]:
      fieldData[FORM_VALUE_ENUM.POPULARITY_ORDER]?.value ?? undefined,
    [FILTER_OPTION_NAME.PRACTICE]:
      fieldData[FORM_VALUE_ENUM.PRACTICE]?.value ?? undefined,
    [FILTER_OPTION_NAME.PRICE_END]:
      fieldData[FORM_VALUE_ENUM.PRICE_END] ?? undefined,
    [FILTER_OPTION_NAME.PRICE_START]:
      fieldData[FORM_VALUE_ENUM.PRICE_START] ?? undefined,
    [FILTER_OPTION_NAME.PRICE_ORDER]:
      fieldData[FORM_VALUE_ENUM.PRICE_ORDER]?.value ?? undefined,
    [FILTER_OPTION_NAME.SEARCH]: fieldData[FORM_VALUE_ENUM.SEARCH] ?? undefined,
    [FILTER_OPTION_NAME.TIMEZONE]:
      fieldData[FORM_VALUE_ENUM.TIMEZONE]?.value ?? undefined,
  };

  return converedData;
};
