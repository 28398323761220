import React, { useState } from 'react';
import { IonModal } from '@ionic/react';

import { GridElem } from '../../../common/grid';
import styled, { css } from 'styled-components';
import { Spacing } from '../../../theme';
import { MESSAGE_ITEM_DATA } from '../constant';
import { AvatarElem } from '../../../common/avatar';
import { FlexElem } from '../../../common/flex';
import { TextElem } from '../../../common/text';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import uploadIcon from '../../../asset/svg/file/file-logo.svg';

const Elem: React.FC<{ data: MESSAGE_ITEM_DATA; userId?: string }> = ({
  data,
  userId,
}) => {
  const [modalPreview, setModalPreview] = useState('');

  const openModalPreview = (e: any, url: string) => {
    e.stopPropagation();
    setModalPreview(url);
  };

  const closeModalPreview = (e: any) => {
    e.stopPropagation();

    setModalPreview('');
  };

  const { content, id, createDate, name, photo, user, photoList, anyFileList } =
    data;
  const active = userId === user?.id;
  const openFileInNewTab = (e: any, url: string) => {
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  return (
    <Container key={id}>
      <AvatarElem size={32} userAvatar={photo} className="avatar" />
      <Content spacing={2} active={active}>
        <HeadContainer>
          <TextElem
            type="medium"
            color={active ? 'default' : 'textPrimary'}
            size="semiSmall"
            oneLine
          >
            {name}
          </TextElem>

          <div style={{ minWidth: '40px', textAlign: 'end' }}>
            <TextElem
              color={active ? 'default' : 'textSecondary'}
              size="verySmall"
            >
              {createDate}
            </TextElem>
          </div>
        </HeadContainer>

        <TextElem
          color={active ? 'default' : 'chip'}
          style={{ wordBreak: 'break-all' }}
          size="semiSmall"
        >
          {content}
        </TextElem>
        {!!photoList.length && (
          <PhotoContainer>
            {photoList?.map((photo) => (
              <FlexElem
                style={{ justifyContent: 'center', cursor: 'pointer' }}
                onClick={(e: any) => openModalPreview(e, photo?.url)}
              >
                <Image src={photo?.url} />

                <Modal isOpen={!!modalPreview} onDidDismiss={closeModalPreview}>
                  <img
                    src={modalPreview}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      margin: 'auto',
                    }}
                  />
                </Modal>
              </FlexElem>
            ))}
          </PhotoContainer>
        )}
        {!!anyFileList.length && (
          <FileContainer>
            {anyFileList?.map((file) => (
              <FileItem onClick={(e: any) => openFileInNewTab(e, file.url)}>
                <UploadIconStyled src={uploadIcon} alt="upload" />
                <TextElem tid={file.name} oneLine color="white" />
              </FileItem>
            ))}
          </FileContainer>
        )}
      </Content>
    </Container>
  );
};

const UploadIconStyled = styled.img`
  height: ${Spacing(4)};
  width: ${Spacing(4)};

  path {
    stroke: white;
    fill: white;
  }
`;

const PhotoContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;

  ${({ children }) => {
    const childCount = React.Children.count(children);

    if (childCount === 1) {
      return css`
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        justify-items: center;
      `;
    }

    if (childCount === 2) {
      return css`
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
      `;
    }

    if (childCount === 3) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        & > :nth-child(1) {
          grid-row: span 2;
        }
        & > :nth-child(3) {
          grid-column: 2 / 3;
        }
      `;
    }

    if (childCount === 4) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        & > :nth-child(3),
        & > :nth-child(4) {
          grid-column: 2 / 3;
        }
      `;
    }

    if (childCount === 5) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        & > :nth-child(1) {
          grid-row: span 2;
        }
        & > :nth-child(3),
        & > :nth-child(4),
        & > :nth-child(5) {
          grid-column: 2 / 3;
        }
      `;
    }

    if (childCount === 6) {
      return css`
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
      `;
    }

    if (childCount === 7) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        & > :nth-child(1) {
          grid-row: span 3;
        }
        & > :nth-child(2),
        & > :nth-child(3) {
          grid-column: 2 / 3;
        }
      `;
    }

    if (childCount === 8) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        & > :nth-child(1) {
          grid-row: span 3;
        }
        & > :nth-child(2),
        & > :nth-child(3),
        & > :nth-child(4),
        & > :nth-child(5) {
          grid-column: 2 / 3;
        }
      `;
    }

    if (childCount === 9) {
      return css`
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto auto;
      `;
    }

    if (childCount === 10) {
      return css`
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto auto;
        & > :nth-child(1),
        & > :nth-child(10) {
          grid-column: span 3;
        }
      `;
    }

    return css`
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: minmax(50px, auto);
    `;
  }}
`;

const FileContainer = styled(FlexElem)`
  justify-content: start;
  flex-wrap: wrap;
  min-width: 10px;
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  object-fit: cover;
  max-width: 350px;
  border-radius: 5px;
`;

const FileItem = styled(FlexElem)`
  gap: 6px;
  padding: ${Spacing(3)} ${Spacing(4)};
  cursor: pointer;
  width: auto;
  max-width: 200px;
  background-color: ${COLOR_DATA[COLOR_ENUM.DEFAULT]};

  border-radius: 7px;
  min-width: 10px;
`;

const HeadContainer = styled(FlexElem)`
  justify-content: space-between;
`;

const Content = styled(GridElem)<{ active: boolean }>`
  padding: ${Spacing(3)} ${Spacing(2)};
  border-radius: 3px 9px 9px;
  width: fit-content;
  max-width: 100%;
  min-width: 10px;

  background: #924e271a;
`;
const Container = styled(GridElem)`
  grid-template-columns: 32px auto min-content;
  gap: ${Spacing(3)};
  @media screen and (width<420px) {
    .avatar {
      height: 30px !important;
      width: 30px !important;
    }
  }
`;

// @ts-ignore
export const Modal = styled(IonModal)<{ minHeight?: number }>`
  pointer-events: none;
  --height: auto;

  --backdrop-opacity: 0;
  --box-shadow: none;

  background: rgba(20, 18, 26, 0.8);

  &.modal-handle::before {
    display: none;
    content: none;
  }

  ::part(content) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    top: 50%;
    width: 100%;
    left: 50%;
    max-width: 80%;
    height: max-content;
    border-radius: 16px;
  }

  & > div {
    margin: 0;
    /* margin-right: 40px; */
    max-width: 100%;
    padding: 0;
  }
`;

export { Elem as MessageItemElem };
