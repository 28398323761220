import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FileUploaderElem } from '../../common/file-uploader';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  FORM_VALUE_ENUM,
  CATEGORY_OPTION_LIST,
  FORM_VALUE_TYPE,
} from './constant';
import { LoaderElem } from '../../common/loader';
import { OPTION_INTER } from '../../data/user/constant';
import { handleChangeDefaultDurationCategory } from '../../data/category/utils';
import { DoubleContainerElem } from '../../common/double-container';

export const Component: React.FC<{
  onToggle: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  setVideo: React.Dispatch<React.SetStateAction<File | null>>;
  setDiplomaState: Function;
  setAddDiplomaCount: React.Dispatch<React.SetStateAction<number>>;
  setCertificateState: Function;
  addCertificateCount: number;
  setAddCertificateCount: React.Dispatch<React.SetStateAction<number>>;
  formik: FormikValues;
  getFieldValue: Function;
  getFieldError: Function;
  isFieldError: (name: FORM_VALUE_TYPE) => boolean;
  handleSubmit: Function;
  isSubmitDisabled?: boolean;
  isError?: boolean;
  getErrorMessage: Function;
  isSuccess?: boolean;
  setFieldValue: Function;
  setValue: Function;
  addDiplomaCount: number;
  onDeleteDiploma: Function;
  onDeleteCertificate: Function;
  onDeleteVideo: Function;
  isAddDiplomaDisabled: boolean;
  isAddCertificateDisabled: boolean;
}> = ({
  onToggle,
  isOpen,
  isLoading,
  setVideo,
  setDiplomaState,
  setAddDiplomaCount,
  setCertificateState,
  addCertificateCount,
  setAddCertificateCount,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  handleSubmit,
  isSubmitDisabled,
  isError,
  getErrorMessage,
  isSuccess,
  setFieldValue,
  setValue,
  addDiplomaCount,
  onDeleteDiploma,
  onDeleteCertificate,
  onDeleteVideo,
  isAddDiplomaDisabled,
  isAddCertificateDisabled,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}

      {isOpen && (
        <Container onSubmit={() => {}}>
          <SelectElem
            isDot
            placeholder="USER.PSYCHOLOGIST.CATEGORY_PLACEHOLDER"
            name={FORM_VALUE_ENUM.CATEGORY}
            onChange={(_name: string, value: OPTION_INTER) => {
              setFieldValue(FORM_VALUE_ENUM.CATEGORY, value);
              if (value?.value) {
                handleChangeDefaultDurationCategory(value.value, formik);
              }
            }}
            options={CATEGORY_OPTION_LIST}
            title="USER.PSYCHOLOGIST.CATEGORY_NAME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
            value={setValue(CATEGORY_OPTION_LIST, FORM_VALUE_ENUM.CATEGORY)}
          />
          <DoubleContainerElem>
            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.PRICE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="USER.PSYCHOLOGIST.PRICE"
              placeholder="USER.PSYCHOLOGIST.PRICE_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.PRICE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PRICE)}
              error={isFieldError(FORM_VALUE_ENUM.PRICE)}
              type="number"
            />
            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.DURATION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="USER.PSYCHOLOGIST.DURATION"
              placeholder="USER.PSYCHOLOGIST.DURATION_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.DURATION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DURATION)}
              error={isFieldError(FORM_VALUE_ENUM.DURATION)}
              type="number"
            />
          </DoubleContainerElem>
          <FieldTextAreaElem
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            title="USER.PSYCHOLOGIST.DESCRIPTION"
            placeholder="USER.PSYCHOLOGIST.CATEGORY.DESCRIPTION_PLACEHOLDER"
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />
          <FieldTextAreaElem
            name={FORM_VALUE_ENUM.WARNING}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.WARNING)}
            title="USER.PSYCHOLOGIST.CATEGORY.WARNING"
            placeholder="USER.PSYCHOLOGIST.CATEGORY.WARNING_PLACEHOLDER"
            errorMessage={getFieldError(FORM_VALUE_ENUM.WARNING)}
            error={isFieldError(FORM_VALUE_ENUM.WARNING)}
          />

          <FileUploaderElem
            title="COMMON.FILE.VIDEO_TITLE"
            onFileUpload={(e) => {
              setVideo(e.file);
            }}
            onRemove={onDeleteVideo}
            uploadType="video"
            dropZoneTitle="COMMON.FILE.VIDEO_CONTENT_TITLE"
          />
          <FileButtonContainer>
            {Array.from({ length: addDiplomaCount }).map((_, index) => (
              <FileUploaderElem
                // isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.DIPLOMA_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setDiplomaState(e);
                }}
                onRemove={onDeleteDiploma}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.DIPLOMA_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddDiplomaDisabled || addDiplomaCount >= 6}
              type="file"
              onClick={() => {
                if (addDiplomaCount < 6) {
                  setAddDiplomaCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer>

          <FileButtonContainer>
            {Array.from({ length: addCertificateCount }).map((_, index) => (
              <FileUploaderElem
                // isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.CERTIFICATE_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setCertificateState(e);
                }}
                onRemove={onDeleteCertificate}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.CERTIFICATE_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddCertificateDisabled || addCertificateCount >= 6}
              type="file"
              onClick={() => {
                if (addCertificateCount < 6) {
                  setAddCertificateCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer>
          <TitleContainer>
            <Dot />
            <TextElem
              tid="USER.PSYCHOLOGIST.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>
          <ButtonElem
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitDisabled}
            tid="CATEGORY.FORM.BUTTON"
          />
          {isError && <AlertActionElem text={getErrorMessage()} />}
          {isSuccess && (
            <AlertActionElem type="success" tid="CATEGORY.FORM.SUCCESS" />
          )}
        </Container>
      )}
    </>
  );
};

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
  align-self: start;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled(FormElem)`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;
