import { FormikValues } from 'formik';
import { i18n } from '../../lib/lang';
import { numberPositiveMin, required } from '../../lib/validation/service';
import { validation } from '../../lib/validation';

export const MODULE_NAME = 'PROFILE_PSYCHO_CREATE_CATEGORY_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  CATEGORY = 'category',
  DESCRIPTION = 'description',
  PRICE = 'price',
  VIDEO = 'video',
  DIPLOMA = 'diploma',
  CERTIFICATE = 'certificate',
  DURATION = 'duration',
  WARNING = 'warning',
}

export const config = {
  [FORM_VALUE_ENUM.WARNING]: [],
  [FORM_VALUE_ENUM.CATEGORY]: [required],
  [FORM_VALUE_ENUM.DESCRIPTION]: [],
  [FORM_VALUE_ENUM.DIPLOMA]: [],
  [FORM_VALUE_ENUM.VIDEO]: [],
  [FORM_VALUE_ENUM.CERTIFICATE]: [],
  [FORM_VALUE_ENUM.PRICE]: [required, numberPositiveMin(1000)],
  [FORM_VALUE_ENUM.DURATION]: [required, numberPositiveMin(50)],
};

export const validate = (values: FormikValues) => validation(values, config);

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export enum CATEGORY_ENUM {
  GROUP_PSYCHOTHERAPY = 1,
  CHILD_COUNSELING = 2,
  INDIVIDUAL_CONSULTATION = 3,
  CONSULTATION_OF_A_PSYCHIATRIST = 4,
  COUPLES_COUNSELING = 5,
  GROUP_PSYCHOTHERAPY_TEENAGER = 6,
  CLASSES_SPEECH_THERAPIST = 7,
  COACHING = 8,
  CHILD_CONSULTATION_PSYCHIATRIST = 9,
}

export const CATEGORY_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY'),
    value: CATEGORY_ENUM.GROUP_PSYCHOTHERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CHILD_COUNSELING'),
    value: CATEGORY_ENUM.CHILD_COUNSELING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.INDIVIDUAL_CONSULTATION'),
    value: CATEGORY_ENUM.INDIVIDUAL_CONSULTATION,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CONSULTATION_OF_A_PSYCHIATRIST'),
    value: CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.COUPLES_COUNSELING'),
    value: CATEGORY_ENUM.COUPLES_COUNSELING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY_TEENAGER'),
    value: CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CLASSES_SPEECH_THERAPIST'),
    value: CATEGORY_ENUM.CLASSES_SPEECH_THERAPIST,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.COACHING'),
    value: CATEGORY_ENUM.COACHING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CHILD_CONSULTATION_PSYCHIATRIST'),
    value: CATEGORY_ENUM.CHILD_CONSULTATION_PSYCHIATRIST,
  },
];

export const CATEGORY_VIEW = {
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY',
  ),
  [CATEGORY_ENUM.CHILD_COUNSELING]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CHILD_COUNSELING',
  ),
  [CATEGORY_ENUM.INDIVIDUAL_CONSULTATION]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.INDIVIDUAL_CONSULTATION',
  ),
  [CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CONSULTATION_OF_A_PSYCHIATRIST',
  ),
  [CATEGORY_ENUM.COUPLES_COUNSELING]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.COUPLES_COUNSELING',
  ),
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY_TEENAGER',
  ),

  [CATEGORY_ENUM.CLASSES_SPEECH_THERAPIST]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CLASSES_SPEECH_THERAPIST',
  ),

  [CATEGORY_ENUM.COACHING]: i18n.t('USER.PSYCHOLOGIST.SELECT.COACHING'),

  [CATEGORY_ENUM.CHILD_CONSULTATION_PSYCHIATRIST]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CHILD_CONSULTATION_PSYCHIATRIST',
  ),
};

export const CATEGORY_VIEW_SLUG = {
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY]: 'group-psychotherapy',
  [CATEGORY_ENUM.CHILD_COUNSELING]: 'child-counseling',
  [CATEGORY_ENUM.INDIVIDUAL_CONSULTATION]: 'individual-consultation',
  [CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST]:
    'consultation-of-a-psychiatrist',
  [CATEGORY_ENUM.COUPLES_COUNSELING]: 'couples-counseling',
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER]: 'group-psychotherapy-teenager',
  [CATEGORY_ENUM.CLASSES_SPEECH_THERAPIST]: 'classes-speech-therapist',
  [CATEGORY_ENUM.COACHING]: 'coaching',
  [CATEGORY_ENUM.CHILD_CONSULTATION_PSYCHIATRIST]:
    'child-consultation-of-a-psychiatrist',
};

export const CATEGORY_VIEW_SLUG_REVERSE: { [key: string]: CATEGORY_ENUM } = {
  'child-consultation-of-a-psychiatrist':
    CATEGORY_ENUM.CHILD_CONSULTATION_PSYCHIATRIST,
  'group-psychotherapy': CATEGORY_ENUM.GROUP_PSYCHOTHERAPY,
  'child-counseling': CATEGORY_ENUM.CHILD_COUNSELING,
  'individual-consultation': CATEGORY_ENUM.INDIVIDUAL_CONSULTATION,
  'consultation-of-a-psychiatrist':
    CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST,
  'couples-counseling': CATEGORY_ENUM.COUPLES_COUNSELING,
  'group-psychotherapy-teenager': CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER,
  'classes-speech-therapist': CATEGORY_ENUM.CLASSES_SPEECH_THERAPIST,
  coaching: CATEGORY_ENUM.COACHING,
};

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CATEGORY]: string;
  [FORM_VALUE_ENUM.DESCRIPTION]: string;
  [FORM_VALUE_ENUM.WARNING]: string;
  [FORM_VALUE_ENUM.PRICE]: string;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: 'category/create',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface FILE_STATE_INTER {
  id: string | number;
  file: File;
}
