import { format, isToday, isTomorrow, differenceInDays } from 'date-fns';
import { parseISO, addHours, formatISO } from 'date-fns';
import { uk } from 'date-fns/locale';

type OutputFormat = Record<string, { value: string; label: string }[]>;

export function convertToTimezoneAndGroup(
  dateArr: string[],
  timezoneOffset: number,
): OutputFormat {
  const now = new Date();
  let localizedDates: OutputFormat = {};

  const filteredDates = dateArr
    .map((utcDate) => {
      const date = parseISO(utcDate.replace('Z', ''));
      const localizedDate = addHours(date, timezoneOffset);
      return { utcDate, localizedDate };
    })
    // .filter(({ localizedDate }) => differenceInDays(localizedDate, now) <= 6)
    .sort((a, b) => a.localizedDate.getTime() - b.localizedDate.getTime());

  filteredDates.forEach(({ utcDate, localizedDate }) => {
    const label = format(localizedDate, 'HH:mm');
    let key: string;

    if (isToday(localizedDate)) {
      key = 'Сьогодні';
    } else if (isTomorrow(localizedDate)) {
      key = 'Завтра';
    } else if (differenceInDays(localizedDate, now) <= 5) {
      key = format(localizedDate, 'EEEE', { locale: uk });
      key = capitalize(key);
    } else {
      key = format(localizedDate, 'dd.MM.yy');
    }

    if (!localizedDates[key]) {
      localizedDates[key] = [];
    }

    localizedDates[key].push({ value: utcDate, label });
  });

  localizedDates = Object.fromEntries(
    Object.entries(localizedDates).sort(([keyA], [keyB]) => {
      const dateA = filteredDates.find(
        ({ localizedDate }) =>
          format(localizedDate, 'EEEE', { locale: uk }) === keyA ||
          format(localizedDate, 'dd.MM.yy') === keyA,
      )?.localizedDate;
      const dateB = filteredDates.find(
        ({ localizedDate }) =>
          format(localizedDate, 'EEEE', { locale: uk }) === keyB ||
          format(localizedDate, 'dd.MM.yy') === keyB,
      )?.localizedDate;
      return (dateA?.getTime() || 0) - (dateB?.getTime() || 0);
    }),
  );

  return trimObject(localizedDates);
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function trimObject(obj: OutputFormat, limit: number = 7): OutputFormat {
  return Object.fromEntries(Object.entries(obj).slice(0, limit));
}
