import React, { useEffect, useState } from 'react';

import { FORM_VALUE_ENUM, FORM_VALUE_INTER, FORM_VALUE_TYPE } from './constant';
import { Component } from './component';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { PSYCHO_FILTER_FORM_VALUE_INTER } from '.';
import { addParamToUrl } from '../../lib/util/urlParamUtil';
import { useHistory } from 'react-router';

export const Container: React.FC<{
  handleUpdateFilter: (
    updatedValue: Partial<PSYCHO_FILTER_FORM_VALUE_INTER>,
  ) => void;
  progressBarExist?: boolean;
  isCategoryFilter?: boolean;
  isOksFreeTitle?: boolean;
  filterInitialOption: FORM_VALUE_INTER;
  clearFilter: () => void;
}> = ({
  handleUpdateFilter,
  progressBarExist,
  isCategoryFilter,
  isOksFreeTitle,
  filterInitialOption,
  clearFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const config = {
    [FORM_VALUE_ENUM.REQUEST_LIST]: [],
    [FORM_VALUE_ENUM.METHOD_LIST]: [],
    [FORM_VALUE_ENUM.TIMEZONE]: [],
    [FORM_VALUE_ENUM.PRICE_ORDER]: [],
    [FORM_VALUE_ENUM.EXPERIENCE]: [],
    [FORM_VALUE_ENUM.POPULARITY_ORDER]: [],
    [FORM_VALUE_ENUM.PRACTICE]: [],
    [FORM_VALUE_ENUM.PRICE_START]: [],
    [FORM_VALUE_ENUM.PRICE_END]: [],
    [FORM_VALUE_ENUM.SEARCH]: [],
    [FORM_VALUE_ENUM.DATE_START]: [],
    [FORM_VALUE_ENUM.DATE_END]: [],
    [FORM_VALUE_ENUM.LANGUAGE_LIST]: [],
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onSubmitForm = () => {
    handleUpdateFilter(formik.values);
    onCloseModal();
  };

  const formik: FormikValues = useFormik({
    initialValues: { ...filterInitialOption },
    validate: (values) => {
      return validation(values, config);
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const onCancelForm = () => {
    clearFilter();

    setTimeout(() => {
      formik.resetForm();
      formik.setFieldValue(FORM_VALUE_ENUM.DATE_START, '');
      formik.setFieldValue(FORM_VALUE_ENUM.DATE_END, '');
      formik.setFieldValue(FORM_VALUE_ENUM.SEARCH, '');
      formik.setFieldValue(FORM_VALUE_ENUM.PRICE_START, '');
      formik.setFieldValue(FORM_VALUE_ENUM.PRICE_END, '');
      formik.setFieldValue(FORM_VALUE_ENUM.LANGUAGE_LIST, '');
      formik.setFieldValue(FORM_VALUE_ENUM.REQUEST_LIST, '');
      formik.setFieldValue(FORM_VALUE_ENUM.METHOD_LIST, '');
      formik.setFieldValue(FORM_VALUE_ENUM.PSYCHO_CATEGORY, '');
    }, 500);

    setIsOpen(false);
  };

  const handleArrayFieldChange = (name: string, e: any, viewObj: any) => {
    formik.setFieldValue(...e);
  };

  const onChangeTime = (_first: any, second: any) => {
    if (second && second[0]) {
      if (second[0]) {
        formik.setFieldValue(FORM_VALUE_ENUM.DATE_START, second[0]);
      }
    }

    if (second.length >= 2 && second[1]) {
      if (second[1]) {
        formik.setFieldValue(FORM_VALUE_ENUM.DATE_END, second[1]);
      }
    }

    if (second[0].length === 0) {
      formik.setFieldValue(FORM_VALUE_ENUM.DATE_START, '');
    }

    if (second[1].length === 0) {
      formik.setFieldValue(FORM_VALUE_ENUM.DATE_END, '');
    }
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    return false;
  };

  const getHasValue = () => {
    const keyToCheck = [
      FORM_VALUE_ENUM.REQUEST_LIST,
      FORM_VALUE_ENUM.METHOD_LIST,
      FORM_VALUE_ENUM.PRICE_ORDER,
      FORM_VALUE_ENUM.SEARCH,
      FORM_VALUE_ENUM.DATE_START,
      FORM_VALUE_ENUM.DATE_END,
      FORM_VALUE_ENUM.POPULARITY_ORDER,
      FORM_VALUE_ENUM.LANGUAGE_LIST,
      FORM_VALUE_ENUM.DATE,
    ];

    return keyToCheck.some((key) => {
      const value = formik.values[key];

      if (Array.isArray(value) ? value.length > 0 : value) {
        return true;
      }

      return false;
    });
  };

  const handleDateChange = (e: any) => {
    if (e instanceof Date) {
      const formattedDate = e.toLocaleDateString('en-CA');

      formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);

      addParamToUrl(FORM_VALUE_ENUM.DATE, '', history);
    } else {
      formik.setFieldValue(FORM_VALUE_ENUM.DATE, null);
    }
  };

  return (
    <Component
      handleDateChange={handleDateChange}
      hasValues={getHasValue()}
      isSubmitDisabled={isSubmitDisabled()}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      handleUpdateFilter={handleUpdateFilter}
      isOksFreeTitle={isOksFreeTitle}
      isCategoryFilter={!!isCategoryFilter}
      dateDefault={filterInitialOption[FORM_VALUE_ENUM.DATE_DEFAULT]}
      initialCategory={filterInitialOption[FORM_VALUE_ENUM.PSYCHO_CATEGORY]}
      isOpen={isOpen}
      onOpenModal={onOpenModal}
      onCloseModal={onCloseModal}
      onChangeTime={onChangeTime}
      onCancelForm={onCancelForm}
      onSubmitForm={onSubmitForm}
      handleArrayFieldChange={handleArrayFieldChange}
      formik={formik}
      isLoading={false}
      progressBarExist={progressBarExist}
    />
  );
};
