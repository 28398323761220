import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';

export const CardFilter: React.FC<{
  title: string;
  description: string;
  color: COLOR_ENUM;
  handleClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
}> = ({ title, description, color, handleClick, isActive, isDisabled }) => {
  const isClickDisabled = isActive || isDisabled;

  return (
    <CardContainer
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={!isClickDisabled ? handleClick : undefined}
    >
      <Title
        lineHeight="1em"
        size="main"
        type="medium"
        tid={title}
        color={color}
      />
      <Description
        lineHeight="1.3em"
        size="semiSmall"
        tid={description}
        color="textSecondary"
      />
    </CardContainer>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 400px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.DEFAULT]}px;
  }
  @media screen and (max-width: 340px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const CardContainer = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(5)};
  gap: 8px;
  align-items: start;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;

  cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${({ isDisabled }) =>
      !isDisabled && VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
    `}

  border: ${({ theme, isActive }) =>
    isActive
      ? `1px solid ${theme[COLOR_ENUM.DEFAULT]}`
      : `1px solid ${theme[COLOR_ENUM.WHITE]}`};

  @media screen and (max-width: 950px) {
    align-items: center;
  }
`;
