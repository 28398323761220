import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  scrollToSection,
  scrollToSectionCenter,
} from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { LANDING_QUESTION_ID_ENUM, SUBTITLE_LIST } from './constant';
import { GridElem } from '../../common/grid';
import { ReadMoreListElem } from '../../common/read-more-list';
import { useHistory } from 'react-router';
import { REQUEST_ENUM, requestSlugViewObj } from '../../data/user/constant';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import useIntersectionObserver from '../landing-depression-about/hook/useIntersectionObserver';

export const Component: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const activeSection = useIntersectionObserver();

  const handleOpenMoreText = useCallback(
    (sectionId) => {
      if (!isOpen) {
        setIsOpen(true);

        setTimeout(() => {
          scrollToSectionCenter(sectionId);
        }, 0);

        return;
      }
      setIsOpen(false);
    },
    [isOpen],
  );

  const history = useHistory();

  const handleRedirect = () => {
    history.push(
      `${PSYCHO_LIST_PAGE_PATH}?request=${
        requestSlugViewObj[REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE]
      },${requestSlugViewObj[REQUEST_ENUM.PTSD]},${
        requestSlugViewObj[REQUEST_ENUM.RELEASE_FROM_CAPTIVITY]
      },${requestSlugViewObj[REQUEST_ENUM.SELF_HARM]},${
        requestSlugViewObj[REQUEST_ENUM.LOSS_OF_A_LOVED_ONE]
      },${requestSlugViewObj[REQUEST_ENUM.GRIEF]}`,
    );
  };

  const reasonArrayText = [
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.FIRST',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.SECOND',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.THIRD',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.FOURTH',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.FIFTH',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.SIXTH',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.SEVENTH',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_LIST.EIGHTH',
    ),
  ];

  const symptomArrayText = [
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM_LIST.FIRST',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM_LIST.SECOND',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM_LIST.THIRD',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM_LIST.FOURTH',
    ),
  ];

  const treatmentArrayText = [
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_LIST.FIRST',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_LIST.SECOND',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_LIST.THIRD',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_LIST.FOURTH',
    ),
  ];

  return (
    <Wrapper>
      <TitleContainer>
        <TextElem
          className="title"
          color="textPrimary"
          size="large"
          type="medium"
          tid="KNOWLEDGE_LANDING.TITLE.MENTAL_TRAUMA_ABOUT"
          lineHeight="36.4px !important"
        />
      </TitleContainer>
      <BodyContainer>
        <Devider />
        <Question>
          {Object.values(LANDING_QUESTION_ID_ENUM).map((sectionId) => (
            <QuestionElem
              key={sectionId}
              active={activeSection == sectionId ? true : false}
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(sectionId);
                }
                scrollToSectionCenter(sectionId);
              }}
            >
              <TextElem
                color="textSecondary"
                type="regular"
                tid={`KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.${sectionId.toUpperCase()}`}
              />
            </QuestionElem>
          ))}
        </Question>
        {/* <Question>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.MENTAL_TRAUMA);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.MENTAL_TRAUMA);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.SYMPTOM);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.SYMPTOM);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA_SYMPTOM"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.REASON);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.REASON);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA_REASON"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.TREATMENT);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.TREATMENT);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA_TREATMENT"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.REMARK);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.REMARK);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA_REMARK"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.HELP);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.HELP);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA_HELP"
            />
          </QuestionElem>
        </Question> */}
        <Devider />
        <Answer>
          <Section id={LANDING_QUESTION_ID_ENUM.MENTAL_TRAUMA}>
            <TextElem
              color="textPrimary"
              type="light"
              size="label"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.FIRST"
            />
            <TextElem
              className="subtitle"
              color="textPrimary"
              type="medium"
              size="title"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.MENTAL_TRAUMA"
            />

            <TextElem
              color="textPrimary"
              type="light"
              size="label"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_FIRST"
            />
            <TextElem
              className="phrase"
              color="borderActive"
              type="medium"
              size="label"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.PHRASE.MENTAL_TRAUMA_START"
            />
          </Section>
          <ContainerMoreText isOpen={isOpen}>
            {/* SYMPTOM */}
            <Section id={LANDING_QUESTION_ID_ENUM.SYMPTOM}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM"
              />
              {/* <TextElem
              color="textPrimary"
              type="light"
              size="label"
              tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_SYMPTOM"
            /> */}
              <ul>
                {symptomArrayText.map((elem) => (
                  <ListItemUl>
                    <TextElem
                      color="textPrimary"
                      type="light"
                      size="label"
                      tid={elem}
                    />
                  </ListItemUl>
                ))}
              </ul>
            </Section>

            {/* DEPRESSION_VIEW */}
            <Section id={LANDING_QUESTION_ID_ENUM.REASON}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REASON_FIRST"
              />
              <ul>
                {reasonArrayText.map((elem) => (
                  <ListItemUl>
                    <TextElem
                      color="textPrimary"
                      type="light"
                      size="label"
                      tid={elem}
                    />
                  </ListItemUl>
                ))}
              </ul>
            </Section>

            {/* TREATMENT */}
            <Section id={LANDING_QUESTION_ID_ENUM.TREATMENT}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_FIRST"
              />

              <ul>
                {treatmentArrayText.map((elem) => (
                  <ListItemUl>
                    <TextElem
                      color="textPrimary"
                      type="light"
                      size="label"
                      tid={elem}
                    />
                  </ListItemUl>
                ))}
              </ul>
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_TREATMENT_SECOND"
              />
            </Section>

            <Section id={LANDING_QUESTION_ID_ENUM.REMARK}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.QUESTION.REMARK"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_FIRST"
              />
              <TextElem color="textPrimary" type="light" size="label">
                {i18n.t(
                  'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_SECOND',
                )}
                <TextElem
                  style={{ display: 'contents' }}
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_SECOND_NAME"
                />
              </TextElem>
              <TextElem color="textPrimary" type="light" size="label">
                {i18n.t(
                  'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_THIRD',
                )}
                <TextElem
                  style={{ display: 'contents' }}
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_THIRD_NAME"
                />
              </TextElem>
              <TextElem color="textPrimary" type="light" size="label">
                {i18n.t(
                  'KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_FOURTH',
                )}
                <TextElem
                  style={{ display: 'contents' }}
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_REMARK_FOURTH_NAME"
                />
              </TextElem>
            </Section>

            <Section className="help" id={LANDING_QUESTION_ID_ENUM.HELP}>
              <TextElem
                style={{ paddingBottom: '8px' }}
                className="phrase"
                color="borderActive"
                type="medium"
                size="label"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.PHRASE.MENTAL_TRAUMA"
              />
              <TextElem
                style={{ paddingBottom: '8px' }}
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_HELP_FIRST"
              />
              <GridElem spacing={0}>
                <TextElem
                  color="textPrimary"
                  type="light"
                  size="label"
                  tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_HELP_SECOND"
                />
                {/* <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_HELP_THIRD"
              /> */}
                <TextElem
                  color="textPrimary"
                  type="light"
                  size="label"
                  tid="KNOWLEDGE_LANDING.MENTAL_TRAUMA.TEXT.MENTAL_TRAUMA_HELP_FOURTH"
                />
              </GridElem>
            </Section>
            <section>
              <Button
                onClick={() => handleRedirect()}
                type="landing"
                color="white"
                sizeText="main"
                tid="KNOWLEDGE_LANDING.BUTTON_SPECIALIST"
              />
            </section>
          </ContainerMoreText>
          <ButtonMoreText
            onClick={() => handleOpenMoreText(null)}
            isOpen={isOpen}
          >
            <TextElem
              tid={
                isOpen
                  ? 'KNOWLEDGE_LANDING.BUTTON_MORE_TEXT_CLOSE'
                  : 'KNOWLEDGE_LANDING.BUTTON_MORE_TEXT_OPEN'
              }
              color="textPrimary"
              size="main"
              type="regular"
            />
            <ArrowImg
              src={
                isOpen
                  ? './assets/svg/faq/arrow-active.svg'
                  : './assets/svg/faq/arrow.svg'
              }
              alt="arrow"
            />
          </ButtonMoreText>
        </Answer>
      </BodyContainer>
    </Wrapper>
  );
};

const TitleContainer = styled.div`
  padding: 0 ${Spacing(30)} ${Spacing(10)};

  @media (max-width: 1325px) {
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 1024px) {
    padding-bottom: ${Spacing(5)};
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ArrowImg = styled.img`
  align-self: baseline;
  margin: auto 0;
`;

const ButtonMoreText = styled.div<{ isOpen: boolean }>`
  // display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
  // color: green;

  width: 100%;
  display: flex;
  flex-direction: row;
  // gap: ${Spacing(3)};
  justify-content: space-between;
  border-radius: 13px;
  border: 1px solid ${COLOR_DATA[COLOR_ENUM.BORDER]};
  padding: ${Spacing(4)} ${Spacing(5)};
`;

const ContainerMoreText = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  width: 100%;
  gap: ${Spacing(6)};
`;

const Wrapper = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${Spacing(350)};
  padding: 0 ${Spacing(3)};

  .title {
    font-size: 26px !important;
  }

  @media (max-width: 768px) {
    .title {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px !important;
    }
    padding: 0 ${Spacing(4)};
  }
`;

const Button = styled(ButtonElem)`
  // margin-top: ${Spacing(6)};
  padding: ${Spacing(5)} ${Spacing(7)};
  // max-width: 720px;
  &:not(:disabled) > * > * {
    font-weight: 500 !important;
  }
  span {
    line-height: normal !important;
  }
  max-height: ${Spacing(14)};
  width: 100%;

  @media (max-width: 1024px) {
    max-width: unset;
  }

  @media (max-width: 768px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px !important;
    }
  }
`;

const Section = styled.section`
  display: grid;
  gap: ${Spacing(4)};

  .help {
    gap: 16px !important;
  }

  @media (max-width: 768px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    }
    .phrase {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    }
    .subtitle {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
    }
    // .phrase {
    //   font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
    // }
  }
`;

const BodyContainer = styled(PaddingContainer)`
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  grid-template-columns: max-content 1fr;
  // background: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: 0 ${Spacing(30)} ${Spacing(14)};
  gap: ${Spacing(18)};

  @media (max-width: 1325px) {
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    // gap: ${Spacing(8)};
    gap: 0;
    padding-bottom: ${Spacing(8)};
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Question = styled.ol`
  display: flex;
  min-width: ${Spacing(86)};
  max-width: ${Spacing(86)};
  width: 100%;
  height: max-content;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: ${Spacing(4)};
  counter-reset: my-counter;
  position: sticky;
  top: ${Spacing(26)};
  margin-top: ${Spacing(1)};

  // padding-top: ${Spacing(5)};
  // padding-left: ${Spacing(5.5)}; //компенсація ширини маркерів

  @media (max-width: 1024px) {
    padding-top: ${Spacing(7)};
    padding-bottom: ${Spacing(7)};
    margin-top: 0;
    min-width: unset;
    position: unset;
    top: unset;
  }
`;

const Devider = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background-color: ${COLOR_DATA[COLOR_ENUM.BORDER]};

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const ListItemUl = styled.li`
  padding: 0;
  list-style: none;
  padding-left: ${Spacing(6)};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    // top: 50%;
    top: 10px;
    left: ${Spacing(3)};
    // left: 12px;
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const ListOl = styled.ol`
  margin: 0;
  counter-reset: my-counter;
`;

const ListItemOl = styled.li`
  list-style: none;
  padding: 2px 0;
  padding-left: ${Spacing(5)};

  position: relative;

  &::before {
    content: counter(my-counter) '. '; /* Виведення значення лічильника */
    counter-increment: my-counter; /* Збільшення лічильника на 1 */
    position: absolute;
    left: 0;
    top: 6px;
    // transform: translateY(-50%);
  }
`;

const QuestionElem = styled.li<{ active: boolean }>`
  list-style: none;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};

  position: relative;
  padding-left: ${Spacing(5)};

  &::before {
    content: counter(my-counter) '. '; /* Виведення значення лічильника */
    counter-increment: my-counter; /* Збільшення лічильника на 1 */
    position: absolute;
    left: 0;
    top: 0px;
    // transform: translateY(-50%);
  }

  &:hover {
    span {
      cursor: pointer;
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
      text-decoration: underline;
    }
    &::before {
      cursor: pointer;
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
    }
  }
  ${({ active }) =>
    active &&
    css`
      span {
        cursor: pointer;
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
        text-decoration: underline;
      }
      &::before {
        cursor: pointer;
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
      }
    `}
`;

const Answer = styled(GridElem)`
  width: 100%;
  max-width: 720px;
  gap: ${Spacing(6)};

  section > .title {
    // padding-bottom: ${Spacing(4)};
    font-size: 26px !important;
  }

  span {
    line-height: 25.6px;
  }

  @media (max-width: 1024px) {
    section > .title {
      // padding-bottom: ${Spacing(3)};
    }
    padding-top: ${Spacing(5)};
    max-width: unset;
  }

  @media (max-width: 768px) {
    section > .title {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px !important;
    }

    gap: ${Spacing(5)};
  }
`;
