export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum LANDING_ID_ENUM {
  HOME = 'MAIN',
  SERVICES = 'SERVICES',
  FAQ = 'FAQ',
  KNOWLEDGE = 'KNOWLEDGE',
}

export type LANDING_ID_TYPE = `${LANDING_ID_ENUM}`;

export interface LANDING_ID_PROPS {
  handleNavClick: (
    id: LANDING_ID_TYPE,
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const LINK = [
  {
    href: 'LANDING.HOME_FOOTER.LINK_LIST.PRIVACY_POLICY_LINK',
    tid: 'LANDING.HOME_FOOTER.LINK_LIST.PRIVACY_POLICY',
  },
  {
    href: 'LANDING.HOME_FOOTER.LINK_LIST.TERMS_OF_USE_LINK',
    tid: 'LANDING.HOME_FOOTER.LINK_LIST.TERMS_OF_USE',
  },
  // {
  //   href: 'LANDING.HOME_FOOTER.LINK_LIST.PUBLIC_CONTRACT_SPECIALIST_LINK',
  //   tid: 'LANDING.HOME_FOOTER.LINK_LIST.PUBLIC_CONTRACT_SPECIALIST',
  // },
  {
    href: 'LANDING.HOME_FOOTER.LINK_LIST.PUBLIC_CONTRACT_CLIENT_LINK',
    tid: 'LANDING.HOME_FOOTER.LINK_LIST.PUBLIC_CONTRACT_CLIENT',
  },
  {
    href: 'LANDING.HOME_FOOTER.LINK_LIST.COOKIE_POLICY_LINK',
    tid: 'LANDING.HOME_FOOTER.LINK_LIST.COOKIE_POLICY',
  },
];
