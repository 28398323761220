import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import { PreviewFile } from './preview-file';
import { ReadMoreListElem } from '../../../common/read-more-list';
import { getHourLabel } from '../../../lib/util/hourConvert';
import { getYearLabel } from '../../../lib/util/yearConvert';
import { i18n } from '../../../lib/lang';
import { FlexElem } from '../../../common/flex';

export const FileComponent: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  return (
    <>
      <ContainerExperience>
        <Block>
          <TextElemStyled
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.EXPERIENCE"
          />
          {data?.yearExperience || data?.hourPractice ? (
            <FlexElem spacing={2} className="experience">
              {data?.yearExperience && (
                <>
                  {/* <Dot /> */}
                  <Description
                    type="regular"
                    color="textSecondary"
                    size="semiSmall"
                    oneLine
                  >
                    <Description
                      type="medium"
                      color="textPrimary"
                      size="semiSmall"
                      tid={getYearLabel(data.yearExperience) ?? undefined}
                    />
                    {i18n.t('CONSULTATION.USER.YEAR_EXPERIENCE')}
                  </Description>
                </>
              )}

              {data?.hourPractice && (
                <>
                  {data?.yearExperience && <Dot />}

                  <Description
                    type="regular"
                    color="textSecondary"
                    size="semiSmall"
                    oneLine
                  >
                    <Description
                      type="medium"
                      color="textPrimary"
                      tid={getHourLabel(data.hourPractice) ?? undefined}
                    />
                    {i18n.t('CONSULTATION.USER.HOUR_PRACTICE')}
                  </Description>
                </>
              )}
            </FlexElem>
          ) : (
            <TextElemStyled
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </Block>
        <Block>
          <TextElemStyled
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.LANGUAGE"
          />
          {data?.languageList && data?.languageList.length > 0 ? (
            <WrapperForHorizontal>
              <ReadMoreListElem listLimit={7} list={data?.languageList ?? []} />
            </WrapperForHorizontal>
          ) : (
            <TextElemStyled
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </Block>
      </ContainerExperience>

      <Container>
        <InfoContainer>
          <TextElemStyled
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.CERTIFICATE"
          />
          <FileContainer>
            <SliderContainer>
              {data?.certificate && data?.certificate.length > 0 ? (
                data.certificate.map((e) => <PreviewFile {...e} />)
              ) : (
                <TextElemStyled
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.NOT_SET"
                />
              )}
            </SliderContainer>
          </FileContainer>
        </InfoContainer>
        <InfoContainer>
          <TextElemStyled
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.DIPLOMA"
          />
          <FileContainer>
            <SliderContainer>
              {data?.diploma && data?.diploma.length > 0 ? (
                data.diploma.map((e) => <PreviewFile key={e.id} {...e} />)
              ) : (
                <TextElemStyled
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid="USER.PSYCHO.PROFILE.NOT_SET"
                />
              )}
            </SliderContainer>
          </FileContainer>
        </InfoContainer>
      </Container>
    </>
  );
};

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 13px;
  }
  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`;

const Block = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(7.75)} ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(5)};
  }

  .experience:first-child {
    display: none;
  }
`;

const Dot = styled.div`
  margin: auto 0;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
`;

const WrapperForHorizontal = styled.div`
  width: 100%;
  ul {
    display: flex;
    justify-content: start;
    gap: ${Spacing(2)};
    flex-wrap: wrap;
    span {
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

      @media screen and (max-width: 1160px) {
        font-size: 13px;
      }
      @media screen and (max-width: 420px) {
        font-size: 12px;
      }
    }
    li:first-child {
      padding-left: unset;

      &::before {
        content: unset;
      }
    }
  }
`;

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const FileContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-wrap: wrap;

  @media screen and (max-width: 1160px) {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  min-width: ${Spacing(105)};
  flex-wrap: wrap;

  @media screen and (max-width: 1160px) {
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
`;

const Container = styled.div`
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // flex-wrap: wrap;
  gap: ${Spacing(3)};

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    // grid-template-columns: 1fr;
  }
`;

const ContainerExperience = styled.div`
  display: flex;
  gap: ${Spacing(3)};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(5)};
  }
`;
