import { useEffect, useRef } from 'react';

const useWidgetFabStyles = () => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    const applyWidgetFabStyles = () => {
      const execute = () => {
        const widgetFab = document
          .querySelector('sp-live-chat')
          ?.shadowRoot?.querySelector('.widget-fab') as HTMLElement;

        if (widgetFab) {
          widgetFab.style.bottom = '250px';
        }
      };

      if (isFirstRun.current) {
        setTimeout(() => {
          execute();
          isFirstRun.current = false;
        }, 500);
      } else {
        execute();
      }
    };

    const widgetWrapper = document
      .querySelector('sp-live-chat')
      ?.shadowRoot?.querySelector('.widget-wrapper') as HTMLElement;

    if (widgetWrapper) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            if (
              mutation.addedNodes.length > 0 ||
              mutation.removedNodes.length > 0
            ) {
              applyWidgetFabStyles();
            }
          }
        }
      });

      observer.observe(widgetWrapper, {
        childList: true,
        subtree: true,
      });

      applyWidgetFabStyles();

      return () => {
        observer.disconnect();
      };
    }
  }, []);
};

export default useWidgetFabStyles;
