import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { HomeHeaderContainer } from '../../epic/home-header';
import { HomeFooterContainer } from '../../epic/home-footer';
import { LANDING_ID_ENUM } from '../../epic/home-header/constant';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { LandingMentalTraumaAboutContainer } from '../../epic/landing-mentalTrauma-about';
import { LandingAdvantageContainer } from '../../epic/landing-advantage';
import { LandingDepressionHeroContainer } from '../../epic/landing-depression-hero';
import { KnowledgeLandingVideoContainer } from '../../epic/landing-video';
import { ThemesOfTherapy } from '../../epic/themes-of-therapy';
import { TITLE } from '../../epic/themes-of-therapy/constant';
import { LANDING_THEME_ID } from './constant';
import { LandingMentalTraumaHeroContainer } from '../../epic/landing-mentalTrauma-hero';
import { LandingSelfEsteemHeroContainer } from '../../epic/landing-selfEsteem-hero';
import { LandingDepressionAboutContainer } from '../../epic/landing-depression-about';
import { LandingSelfEsteemAboutContainer } from '../../epic/landing-selfEsteem-about';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import { PsychoFilterListContainer } from '../../epic/psycho-filter-list';

const TITLES: Record<string, string> = {
  [LANDING_THEME_ID.DEPRESSION]: 'Сяйво - Депресія',
  [LANDING_THEME_ID.SELF_ESTEEM]: 'Сяйво - Самооцінка',
  [LANDING_THEME_ID.MENTAL_TRAUMA]: 'Сяйво - Психологічна травма',
};

export const Page: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const sectionPsychoListRef = useRef<HTMLDivElement>(null);
  const id = String(match.params.id);

  useEffect(() => {
    const title =
      TITLES[id] || 'Сяйво - Психологічна платформа для всієї родини';
    document.title = title;
  }, [id]);

  return (
    <PageElem background="active">
      <LayoutAppElem oneColumn>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeaderContainer />
        </SectionHome>

        <SectionHome>
          {id === LANDING_THEME_ID.DEPRESSION && (
            <LandingDepressionHeroContainer />
          )}
          {id === LANDING_THEME_ID.SELF_ESTEEM && (
            <LandingSelfEsteemHeroContainer />
          )}
          {id === LANDING_THEME_ID.MENTAL_TRAUMA && (
            <LandingMentalTraumaHeroContainer />
          )}
        </SectionHome>

        <SectionVideo>
          <KnowledgeLandingVideoContainer id={id} />
        </SectionVideo>

        <SectionAbout>
          {id === LANDING_THEME_ID.DEPRESSION && (
            <LandingDepressionAboutContainer />
          )}
          {id === LANDING_THEME_ID.SELF_ESTEEM && (
            <LandingSelfEsteemAboutContainer />
          )}
          {id === LANDING_THEME_ID.MENTAL_TRAUMA && (
            <LandingMentalTraumaAboutContainer />
          )}
        </SectionAbout>
        <SectionPsychoList ref={sectionPsychoListRef} id="psychoList">
          <PsychoFilterListContainer isPopularitySort cardLimit={5} />
        </SectionPsychoList>
        <SectionService>
          <LandingAdvantageContainer />
        </SectionService>
        <SectionService>
          <ThemesOfTherapy title={TITLE.KNOWLEDGE_LANDING} />
        </SectionService>

        <SectionFooter>
          <HomeFooterContainer />
        </SectionFooter>
      </LayoutAppElem>
    </PageElem>
  );
};

const ListContainer = styled.div`
  display: flex;
  max-width: 400px;
`;

const ArrowImg = styled.img`
  align-self: baseline;
  margin: auto 0;
`;

const ButtonMoreText = styled.div<{ isOpen: boolean }>`
  margin-top: ${Spacing(6)};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 13px;
  border: 1px solid ${COLOR_DATA[COLOR_ENUM.BORDER]};
  padding: ${Spacing(4)} ${Spacing(5)};
`;

const SectionHome = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const SectionService = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div {
    background-image: none !important;
  }
`;

const SectionPsychoList = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  max-width: ${Spacing(350)};
  padding: 0 ${Spacing(30)} ${Spacing(14)};
  margin: 0 auto;

  .title {
    justify-content: center;
  }

  @media (max-width: 1325px) {
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 768px) {
    padding-left: ${Spacing(4)};
    padding-right: ${Spacing(4)};
  }
`;

const SectionVideo = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionAbout = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionFooter = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
