import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { ModalTimeChange } from './frame/modal';
import { FormikValues } from 'formik';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{
  dayArr?: string[];
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  currentDate?: {
    timeView: string;
    dayView: string;
  };
  isTimeLoading: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  timezone: number;
  isFieldDisabled: Function;
  timeOption: {
    label: string;
    value: string;
  }[];
  isSuccess: boolean;
  isChangeTimeDisabled: boolean;
  handleCreateManualPayment: Function;
  isPaid?: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  isSuccess,
  isChangeTimeDisabled,
  handleCreateManualPayment,
  isPaid,
  dayArr,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="CONSULTATION.SUCCESS_MANUAL" />
      )}
      <MainContainer>
        <TextElem
          lineHeight="0.8em"
          tid="CONSULTATION.USER.PAYMENT.TITLE"
          type="medium"
          size="main"
          color="textPrimary"
        />

        <Block>
          {isPaid ? (
            <ChipSuccess>
              <TextElem
                tid="CONSULTATION.USER.PAID"
                color="success"
                size="semiSmall"
              />
            </ChipSuccess>
          ) : (
            <ButtonElemStyled
              onClick={handleCreateManualPayment}
              disabled={isSubmitDisabled()}
              type="submit"
              sizeText="semiSmall"
              tid="CONSULTATION.ADMIN.MANUAL_PAYMENT"
            />
          )}
          <ModalTimeChange
            dayArr={dayArr}
            isChangeTimeDisabled={isChangeTimeDisabled}
            isFieldError={isFieldError}
            getFieldError={getFieldError}
            getFieldValue={getFieldValue}
            setFieldValue={setFieldValue}
            setValue={setValue}
            formik={formik}
            isSubmitDisabled={isSubmitDisabled}
            timeOption={timeOption}
            isFieldDisabled={isFieldDisabled}
            isTimeLoading={isTimeLoading}
          />
        </Block>
      </MainContainer>
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(4)};
  height: max-content;
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: ${Spacing(3)} ${Spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const Block = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

    span {
      font-size: 14px;
    }
  }
`;
