import React, { ReactNode } from 'react';
import { IonSkeletonText } from '@ionic/react';
import { Spacing } from '../../theme';

import styled from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { GridElem } from '../grid';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<{}> = ({}) => {
  return (
    <Wrapper>
      <PhotoSkeleton animated />
      <Card spacing={4}>
        <TitleSkeleton width={50} animated />

        <Container>
          <SkeletonFieldPercent width={70} animated />
          <SkeletonFieldPercent width={60} animated />
          <SkeletonFieldPercent width={65} animated />
        </Container>
        <ChipContainer>
          <SkeletonChip animated />
          <SkeletonChip animated />
        </ChipContainer>
        <SkeletonFieldPercent width={20} animated />
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.SKELETON]}px;
  display: grid;
  grid-template-columns: 180px 1fr;
  width: 100%;
  gap: ${Spacing(4)};
  align-items: center;

  @media screen and (max-width: 400px) {
    display: flex;
  }
`;

const PhotoSkeleton = styled(IonSkeletonText)`
  height: 180px;
  width: 180px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.SKELETON]}px;
`;

const ChipContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const SkeletonChip = styled(IonSkeletonText)`
  height: 28px;
  width: 76px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  margin: 0;
`;

const Card = styled(GridElem)`
  width: 100%;
`;

const SkeletonFieldPercent = styled(IonSkeletonText)<{ width: number }>`
  height: 12px;
  width: ${({ width }) => width}%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
  margin: 0;
`;

const TitleSkeleton = styled(SkeletonFieldPercent)`
  height: 16px;
`;

const Container = styled(GridElem)`
  align-content: flex-start;
  gap: 6px;
`;
