import { CATEGORY_VIEW } from '../../epic/profile-psycho-create-category/constant';
import { convertDay } from '../../lib/lang/service';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { TIMEZONE_ENUM } from '../user/timezone';
import {
  PAYMENT_ITEM_DATA_RAW_INTER,
  PAYMENT_ITEM_DATA_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
  PAYMENT_VALUTE_SYMBOL,
} from './constant';

export const convertPayment = (
  payment: PAYMENT_ITEM_DATA_RAW_INTER,
  timezone?: TIMEZONE_ENUM,
): PAYMENT_ITEM_DATA_INTER => {
  const { day, time } = convertDateToLocal(
    payment?.consultation?.date,
    timezone || 2,
  );

  return {
    ...payment,
    createDate: convertDay(payment.createDate),
    valute: PAYMENT_VALUTE_SYMBOL[payment.valute],
    categoryView: CATEGORY_VIEW[payment?.consultation?.category],
    dateView: `${day}-${time}`,
  };
};

export const convertPaymentList = (
  paymentList: PAYMENT_ITEM_DATA_RAW_INTER[],
  timezone?: TIMEZONE_ENUM,
): PAYMENT_ITEM_LIST_DATA_INTER => {
  return {
    list: paymentList?.map((payment: PAYMENT_ITEM_DATA_RAW_INTER) => {
      return convertPayment(payment, timezone);
    }),
    isEmpty: !paymentList?.length,
  };
};
