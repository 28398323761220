import styled from 'styled-components';

import emptySvg from '../../../asset/svg/common/direct-normal.svg';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';

export const EmptyElem: React.FC = () => {
  return (
    <Container>
      <EmptyImg src={emptySvg} alt="empty elem" />
      <TextElem
        fontFamily="e-UkraineHead"
        size="main"
        color="textSecondary"
        tid="COMMON.LIST.EMPTY"
      />
    </Container>
  );
};

const EmptyImg = styled.img`
  width: ${Spacing(12)};
  height: ${Spacing(12)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(4)};
`;
