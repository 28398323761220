import React, { useEffect, useState } from 'react';

import { Component } from './component';
import { i18n } from '../../lib/lang';

export const Container: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const onSubmit = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    const handleClick = () => {
      setIsVisible(true);
    };

    const button = document.querySelector('.open-widget');
    if (button) {
      button.addEventListener('click', handleClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const link = i18n.t('COMMON.WIDGET.LINK');

  return (
    <Component
      link={link}
      onCancel={() => setIsVisible(false)}
      onSubmit={onSubmit}
      isVisible={isVisible}
    />
  );
};
