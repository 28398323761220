import React from 'react';
import ReactDOM from 'react-dom';

import { DATA_INTER, USER_INTER } from '../constant';
import { ButtonBackElem } from '../../../common/button-back';
import { PSYCHO_LIST_PAGE_PATH } from '../../../page/psycho-list';
import styled, { css } from 'styled-components';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { useHistory } from 'react-router';
import { ButtonElem } from '../../../common/button';
import { COLOR_ENUM } from '../../../theme/color';
import ArrowLeftSvg from '../../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';
import { PSYCHO_PROFILE_PAGE_PATH_DYNAMIC } from '../../../page/psycho-profile';
import { FlexElem } from '../../../common/flex';
import arrowRight from '../../../asset/svg/common/arrow-right-full.svg';
import { CATEGORY_ENUM } from '../../profile-psycho-create-category/constant';
import { CATEGORY_VIEW } from '../../profile-psycho/constant';
import { i18n } from '../../../lib/lang';
import { STORE_INTER } from '../../../data/auth/constant';
import { useSelector } from '../../../lib/store';
import { AUTH_MODULE_NAME } from '../../../data/auth';
import { HomeHeaderContainer } from '../../home-header';
import { ProgressStepBar } from '../../../common/progress-step-bar';
import { STEP_LIST } from '../../../common/progress-step-bar/constant';

export const HeaderOrder: React.FC<{
  id?: string;
  user?: USER_INTER;
  category?: number;
}> = ({ id, category, user }) => {
  const history = useHistory();

  const handleAction = (path?: string | boolean) => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    } else if (path === true) {
      if (history.length > 1) {
        history.go(-1);
      } else {
        history.push(PSYCHO_PROFILE_PAGE_PATH_DYNAMIC(id || ''));
      }
    }
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;
  return (
    <>
      {ReactDOM.createPortal(
        <>
          <HeaderContainer>
            {!isLogged && <HomeHeaderContainer />}
            <Wrapper isLogged={isLogged}>
              <ProgressStepBar
                step={STEP_LIST.ORDER}
                text={i18n.t('ORDER.PROGRESS_BAR')}
              />
            </Wrapper>
          </HeaderContainer>
        </>,
        document.getElementById('layoutApp') || document.body,
      )}
      <FlexElemStyled justifyContent="center">
        <BreadCrumbs isLogged={isLogged}>
          <TextElem
            color="textSecondary"
            type="regular"
            size="semiSmall"
            tid={i18n.t('ORDER.BREAD_CRUMB_PSYCHO')}
            // lineHeight="14px"
          />
          <img src={arrowRight} alt="" />

          <TextElem
            oneLine
            color="textSecondary"
            type="regular"
            size="semiSmall"
          >
            {user?.name}
            {user?.lastName && (
              <>
                {' '}
                <TextElem
                  color="textSecondary"
                  type="regular"
                  size="semiSmall"
                  tid={user.lastName}
                  // lineHeight="14px"
                />
              </>
            )}
          </TextElem>
          <img src={arrowRight} alt="" />
          <TextElem
            color="textPrimary"
            type="regular"
            size="semiSmall"
            tid={
              category
                ? CATEGORY_VIEW[category as CATEGORY_ENUM]
                : 'виникла помилка'
            }
            // lineHeight="14px"
            oneLine
          />
        </BreadCrumbs>
      </FlexElemStyled>
      <FlexElem alignItems="center" spacing={5}>
        <ButtonComponent onClick={() => handleAction(true)}>
          <img src={ArrowLeftSvg} alt="arrow" />
        </ButtonComponent>
        <TextStyled
          color="textPrimary"
          type="medium"
          size="heading"
          tid="ORDER.ITEM.HEADER"
        />
      </FlexElem>
    </>
  );
};

const FlexElemStyled = styled(FlexElem)`
  margin-top: ${Spacing(6)};
`;

const Wrapper = styled.div<{ isLogged?: boolean | null }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(6)};
  margin: 0 auto;
  width: 100%;
  margin-top: ${({ isLogged }) => (isLogged ? `${Spacing(3)}` : '108px')};
`;

const BreadCrumbs = styled.div<{ isLogged?: boolean | null }>`
  align-items: center;
  display: flex;
  justify-content: start;
  gap: ${Spacing(1.5)};
  margin: 0 calc(auto - 20px);
  overflow-x: auto;
  flex-wrap: wrap;
  margin-bottom: ${Spacing(1)};

  @media screen and (max-width: 420px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
    }
  }
`;

const TextStyled = styled(TextElem)`
  margin: 0 auto;
  @media screen and (max-width: 1160px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
  }
`;

const ButtonComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  img {
    max-width: unset;
  }

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const HeaderContainer = styled.div<{ isLogged?: boolean | null }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: end;
  margin: 0 auto;
  margin-top: ${({ isLogged }) =>
    isLogged ? `${Spacing(85)}` : '0'}; // 84px висота header + gap 32px = 116px
  margin: 0 auto;
  // margin-top: ${Spacing(8)};
  // padding: 0px 16px;
  align-items: center;
  padding: 0 ${Spacing(4)};
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px + ${Spacing(10 * 2)}
  );

  @media screen and (max-width: 1160px) {
    max-width: calc(
      ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
    );
  }
`;
