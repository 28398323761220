import React from 'react';
import { FormikValues } from 'formik';

import styled, { css } from 'styled-components';
import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';
import filterIcon from '../../asset/svg/button/filter.svg';
import filterColorIcon from '../../asset/svg/button/filter-color.svg';

import {
  EXPRERIANCE_OPTION_LIST,
  FILTER_OPTION_NAME,
  FORM_VALUE_ENUM,
  POPULARITY_ORDER_OPTION_LIST,
  PRACTICE_OPTION_LIST,
  PRICE_ORDER_OPTION_LIST,
} from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import {
  METHOD_OPTION_LIST,
  REQUEST_OPTION_LIST,
} from '../profile-psycho-update-specialization/constant';
import { COLOR_ENUM } from '../../theme/color';
import { multiValueContainer } from '../../common/select/frame/multi-value';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { HomeHeaderContainer } from '../home-header';
import {
  methodSlugViewObj,
  OPTION_INTER,
  requestSlugViewObj,
} from '../../data/user/constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FieldTimeInputElem } from '../../common/field-time-input';
import { PsychoCategoryFilterContainer } from '../psycho-category-filter';
import { PSYCHO_FILTER_FORM_VALUE_INTER } from '.';
import { ModalElem } from '../../common/modal';
import { FlexElem } from '../../common/flex';
import { LANGUAGE_OPTION_LIST } from '../profile-user-update-data/constant';
import { DatePickerElem } from '../../common/date-picker';

export const Component: React.FC<{
  isLoading?: boolean;
  formik: FormikValues;
  handleArrayFieldChange: (name: string, e: any, viewObj: any) => void;
  onSubmitForm: () => void;
  onCancelForm: () => void;
  onChangeTime: (_first: any, second: any) => void;
  isOpen: boolean;
  onOpenModal: Function;
  onCloseModal: Function;
  dateDefault: any;
  progressBarExist?: boolean;
  isCategoryFilter: boolean;
  isOksFreeTitle?: boolean;
  getFieldError: Function;
  isFieldError: Function;
  isSubmitDisabled: boolean;
  handleUpdateFilter: (
    updatedValue: Partial<PSYCHO_FILTER_FORM_VALUE_INTER>,
  ) => void;
  hasValues: boolean;
  initialCategory: OPTION_INTER | null;
  handleDateChange: (e: any) => void;
}> = ({
  isLoading,
  formik,
  handleArrayFieldChange,
  onSubmitForm,
  onCancelForm,
  onChangeTime,
  isOpen,
  onOpenModal,
  onCloseModal,
  dateDefault,
  progressBarExist = true,
  isCategoryFilter,
  isOksFreeTitle,
  handleUpdateFilter,
  getFieldError,
  isFieldError,
  isSubmitDisabled,
  hasValues,
  initialCategory,
  handleDateChange,
}) => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;

  return (
    <>
      {!isLogged && <HomeHeaderContainer />}

      <Wrapper>
        <Wrapper>
          <Container isLogged={isLogged}>
            <MainContainer>
              {isCategoryFilter && (
                <PsychoCategoryFilterContainer
                  initialCategory={
                    initialCategory ? initialCategory : { value: 3, label: '' }
                  }
                  isOksFreeTitle={isOksFreeTitle}
                  handleUpdateFilter={handleUpdateFilter}
                />
              )}

              <FilterContainer>
                <HeaderContainer>
                  <img src={psychoIcon} alt="filter image" />
                  <TextElem
                    fontFamily={isOksFreeTitle ? 'Oks-Free' : 'e-Ukraine'}
                    type={isOksFreeTitle ? 'regular' : 'medium'}
                    color="textPrimary"
                    size="medium"
                    oneLine
                    tid={
                      isOksFreeTitle
                        ? 'PSYCHO_LIST.FILTER.HEADER_PAGE_LANDING'
                        : 'PSYCHO_LIST.FILTER.HEADER_PAGE'
                    }
                  />
                </HeaderContainer>

                <FlexElemStyled
                  onClick={() => onOpenModal()}
                  alignItems="center"
                  justifyContent="end"
                >
                  <FilterText
                    tid="PSYCHO_LIST.FILTER.TITLE_BUTTON"
                    size="main"
                    color="textPrimary"
                  />
                  <ButtonContainer>
                    <FilterImg
                      src={hasValues ? filterColorIcon : filterIcon}
                      alt="filter image"
                    />
                  </ButtonContainer>
                </FlexElemStyled>

                <ModalElem
                  titleTid="PSYCHO_LIST.FILTER.HEADER"
                  icon={filterColorIcon}
                  open={isOpen}
                  onClose={onCloseModal}
                >
                  <SelectContainerDesktop>
                    {/* <DoubleContainer>
                      <SelectElem
                        isClearable
                        dynamic
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        defaultValue={
                          formik?.values?.[FORM_VALUE_ENUM.PSYCHO_CATEGORY] ||
                          ''
                        }
                        placeholder="PSYCHO_LIST.FILTER.CATEGORY"
                        name={FORM_VALUE_ENUM.PSYCHO_CATEGORY}
                        options={CATEGORY_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.PSYCHO_CATEGORY,
                          formik,
                        )}
                        error={isFieldError(
                          FORM_VALUE_ENUM.PSYCHO_CATEGORY,
                          formik,
                        )}
                        isDisabled={isLoading}
                        value={
                          formik?.values?.[FORM_VALUE_ENUM.PSYCHO_CATEGORY]
                        }
                      />

                      <SelectElem
                        isClearable
                        dynamic
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        defaultValue={formik.values[FORM_VALUE_ENUM.TIMEZONE]}
                        placeholder="PSYCHO_LIST.FILTER.TIMEZONE"
                        name={FORM_VALUE_ENUM.TIMEZONE}
                        options={TIMEZONE_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.TIMEZONE,
                          formik,
                        )}
                        error={isFieldError(FORM_VALUE_ENUM.TIMEZONE, formik)}
                        isDisabled={isLoading}
                        value={formik.values[FORM_VALUE_ENUM.TIMEZONE]}
                      />
                    </DoubleContainer> */}
                    {/* {formik.values[FORM_VALUE_ENUM.PSYCHO_CATEGORY] && (
                      <DoubleContainer>
                        <FieldTextElem
                          onChange={(event: any) => {
                            formik.handleChange(event);
                          }}
                          name={FORM_VALUE_ENUM.PRICE_START}
                          onBlur={formik.handleBlur}
                          placeholder="PSYCHO_LIST.FILTER.PRICE_START_PLACEHOLDER"
                          value={formik.values[FORM_VALUE_ENUM.PRICE_START]}
                          errorMessage={getFieldError(
                            FORM_VALUE_ENUM.PRICE_START,
                          )}
                          error={isFieldError(FORM_VALUE_ENUM.PRICE_START)}
                          type="number"
                          min={1}
                        />
                        <FieldTextElem
                          name={FORM_VALUE_ENUM.PRICE_END}
                          onChange={(event: any) => {
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="PSYCHO_LIST.FILTER.PRICE_END_PLACEHOLDER"
                          value={formik.values[FORM_VALUE_ENUM.PRICE_END]}
                          errorMessage={getFieldError(
                            FORM_VALUE_ENUM.PRICE_END,
                          )}
                          error={isFieldError(FORM_VALUE_ENUM.PRICE_END)}
                          type="number"
                          min={2}
                        />
                      </DoubleContainer>
                    )} */}
                    <DoubleContainer>
                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.METHOD"
                        isMulti
                        name={FORM_VALUE_ENUM.METHOD_LIST}
                        options={METHOD_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.METHOD_LIST,
                          formik,
                        )}
                        error={isFieldError(
                          FORM_VALUE_ENUM.METHOD_LIST,
                          formik,
                        )}
                        value={formik.values[FORM_VALUE_ENUM.METHOD_LIST]}
                        onChange={(...e: any) => {
                          handleArrayFieldChange(
                            FILTER_OPTION_NAME.METHOD,
                            e,
                            methodSlugViewObj,
                          );
                        }}
                        customComponents={{
                          MultiValueContainer: multiValueContainer,
                        }}
                        isDisabled={isLoading}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      />

                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.REQUEST"
                        isMulti
                        name={FORM_VALUE_ENUM.REQUEST_LIST}
                        options={REQUEST_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.REQUEST_LIST,
                          formik,
                        )}
                        error={isFieldError(
                          FORM_VALUE_ENUM.REQUEST_LIST,
                          formik,
                        )}
                        value={formik.values[FORM_VALUE_ENUM.REQUEST_LIST]}
                        onChange={(...e: any) => {
                          handleArrayFieldChange(
                            FILTER_OPTION_NAME.REQUEST,
                            e,
                            requestSlugViewObj,
                          );
                        }}
                        customComponents={{
                          MultiValueContainer: multiValueContainer,
                        }}
                        isDisabled={isLoading}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      />
                    </DoubleContainer>
                    <DoubleContainer>
                      <DatePickerElem
                        name={FORM_VALUE_ENUM.DATE}
                        placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
                        handleChange={handleDateChange}
                        value={formik.values[FORM_VALUE_ENUM.DATE]}
                      />
                      <SelectElem
                        placeholder="USER.USER.LANGUAGE_PLACEHOLDER"
                        isMulti
                        isClearable
                        name={FORM_VALUE_ENUM.LANGUAGE_LIST}
                        options={LANGUAGE_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.LANGUAGE_LIST,
                        )}
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        value={formik.values[FORM_VALUE_ENUM.LANGUAGE_LIST]}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        customComponents={{
                          MultiValueContainer: multiValueContainer,
                        }}
                      />
                    </DoubleContainer>

                    <FieldTextElem
                      name={FORM_VALUE_ENUM.SEARCH}
                      onChange={(event: any) => {
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="PSYCHO_LIST.FILTER.SEARCH"
                      value={formik.values[FORM_VALUE_ENUM.SEARCH]}
                    />
                    {/* <FieldTimeInputElem
                      defaultValue={dateDefault}
                      onChange={onChangeTime}
                    /> */}
                    <Divider />
                    {/* 
                    <DoubleContainer>
                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.PRACTICE"
                        name={FORM_VALUE_ENUM.PRACTICE}
                        options={PRACTICE_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.PRACTICE,
                          formik,
                        )}
                        error={isFieldError(FORM_VALUE_ENUM.PRACTICE, formik)}
                        value={formik.values[FORM_VALUE_ENUM.PRACTICE]}
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        isDisabled={isLoading}
                      />
                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.POPULARITY_ORDER"
                        name={FORM_VALUE_ENUM.POPULARITY_ORDER}
                        options={POPULARITY_ORDER_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.POPULARITY_ORDER,
                          formik,
                        )}
                        error={isFieldError(
                          FORM_VALUE_ENUM.POPULARITY_ORDER,
                          formik,
                        )}
                        value={formik.values[FORM_VALUE_ENUM.POPULARITY_ORDER]}
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        isDisabled={isLoading}
                      />
                    </DoubleContainer> */}
                    <DoubleContainer>
                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.PRICE_ORDER"
                        name={FORM_VALUE_ENUM.PRICE_ORDER}
                        options={PRICE_ORDER_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.REQUEST_LIST,
                          formik,
                        )}
                        error={isFieldError(
                          FORM_VALUE_ENUM.PRICE_ORDER,
                          formik,
                        )}
                        value={formik.values[FORM_VALUE_ENUM.PRICE_ORDER]}
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        isDisabled={isLoading}
                      />

                      <SelectElem
                        isClearable
                        placeholder="PSYCHO_LIST.FILTER.EXPERIENCE"
                        name={FORM_VALUE_ENUM.EXPERIENCE}
                        options={EXPRERIANCE_OPTION_LIST}
                        errorMessage={getFieldError(
                          FORM_VALUE_ENUM.EXPERIENCE,
                          formik,
                        )}
                        error={isFieldError(FORM_VALUE_ENUM.EXPERIENCE, formik)}
                        value={formik.values[FORM_VALUE_ENUM.EXPERIENCE]}
                        onChange={(...e: any) => {
                          formik.setFieldValue(...e);
                        }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isDisabled={isLoading}
                      />
                    </DoubleContainer>

                    <SubmitContainer>
                      <ButtonElemStyled
                        onClick={onCancelForm}
                        type="cancel"
                        tid="PSYCHO_LIST.FILTER.CANCEL"
                      />
                      <ButtonElemStyled
                        disabled={isSubmitDisabled}
                        onClick={onSubmitForm}
                        type="submit"
                        tid="PSYCHO_LIST.FILTER.SUBMIT"
                      />
                    </SubmitContainer>
                  </SelectContainerDesktop>
                </ModalElem>
              </FilterContainer>
            </MainContainer>
          </Container>
        </Wrapper>
      </Wrapper>
    </>
  );
};

const FlexElemStyled = styled(FlexElem)`
  cursor: pointer;
  width: unset;

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  }
`;

const FilterText = styled(TextElem)`
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  min-height: 56px;

  @media screen and (max-width: 650px) {
    span {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 380px) {
    padding: ${Spacing(4)} ${Spacing(4)};
    span {
      font-size: 13px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(5)};
  margin-bottom: ${Spacing(5)};

  @media screen and (max-width: 630px) {
    margin-bottom: ${Spacing(4)};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  cursor: pointer;
  z-index: 500;

  @media screen and (max-width: 630px) {
    padding: ${Spacing(3)};
  }
`;

const FilterImg = styled.img`
  width: ${Spacing(4)};
  height: ${Spacing(4)};
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(6)};
  }
`;

const Wrapper = styled.div<{ isLogged?: boolean | null }>`
  ${({ isLogged }) =>
    !isLogged &&
    css`
      gap: ${Spacing(6)};
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1070px) {
    display: none;
  }

  @media screen and (max-width: 1160px) {
    display: flex;

    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
    }
  }
`;

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(3)};

  @media screen and (max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

const SubmitContainer = styled(DoubleContainer)`
  margin-top: ${Spacing(2)};

  @media screen and (max-width: 350px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SelectContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
  justify-content: end;
  z-index: 600;
  width: 100%;
  max-width: 600px;
`;

const Container = styled.div<{ isLogged?: boolean | null }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
  margin: 0 auto;
  margin-top: ${({ isLogged }) => (isLogged ? `${Spacing(3)}` : '20px')};
  align-self: end;
  align-items: center;

  @media screen and (max-width: 1160px) {
    flex-direction: column;
    gap: ${Spacing(6)};
  }
`;
