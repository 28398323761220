import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';

import {
  MESSAGE_ITEM_DATA,
  MESSAGE_ITEM_DATA_RAW,
} from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { HttpRequest } from '../../lib/http';
import { convertMessage, convertMessageList } from '../../data/message/convert';

interface useRoomSocketProps {
  consultationId: string | number;
  userId?: string;
  setChatMessage: React.Dispatch<React.SetStateAction<MESSAGE_ITEM_DATA[]>>;
}

const useRoomSocket = ({
  consultationId,
  setChatMessage,
  userId,
}: useRoomSocketProps) => {
  const { socket, initializeSocket, sendMessage } = useSocket();
  console.log('initializeSocket', socket);

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const bearer = (
    HttpRequest.defaults.headers.common['Authorization'] as string
  )?.replace('Bearer ', '');

  useEffect(() => {
    if (!state?.user?.token && !bearer) {
      return;
    }

    initializeSocket(BASE_URL as string, {
      auth: {
        token: state?.user?.token || bearer,
        consultationId,
      },
    });
  }, []);

  useEffect(() => {
    if (!state?.user?.token && !bearer) {
      return;
    }

    if (socket) {
      socket.on('consultationMessages', (messages: MESSAGE_ITEM_DATA_RAW[]) => {
        setChatMessage(convertMessageList(messages, userId));
      });

      socket.on('chat', (message: MESSAGE_ITEM_DATA_RAW) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return [...previousMessages, convertMessage(message, userId)];
        });
      });

      const handleBeforeUnload = () => {
        socket.disconnect();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        handleBeforeUnload();
      };
    }
  }, [socket]);
  return {
    sendMessage,
    isConnected: socket?.connected,
  };
};

export default useRoomSocket;
