import React from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { ProfilePsychoContainer } from '../../epic/profile-psycho';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { HomeFooterContainer } from '../../epic/home-footer';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import styled from 'styled-components';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;

  return (
    <>
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size={!isLogged ? 'large' : 'mediumLarge'}>
            <LayoutSectionElem>
              <ProfilePsychoContainer />
            </LayoutSectionElem>
          </LayoutElem>
          {!isLogged && <HomeFooterContainer />}
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
