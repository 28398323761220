import { i18n } from '../../lib/lang';

export const TIMEZONE_OPTION_LIST = [
  { id: 14, value: 14, label: i18n.t('USER.DATA.TIMEZONE.14') },
  { id: 13, value: 13, label: i18n.t('USER.DATA.TIMEZONE.13') },
  { id: 12.75, value: 12.75, label: i18n.t('USER.DATA.TIMEZONE.1275') },
  { id: 12, value: 12, label: i18n.t('USER.DATA.TIMEZONE.12') },
  { id: 11, value: 11, label: i18n.t('USER.DATA.TIMEZONE.11') },
  { id: 10.5, value: 10.5, label: i18n.t('USER.DATA.TIMEZONE.105') },
  { id: 10, value: 10, label: i18n.t('USER.DATA.TIMEZONE.10') },
  { id: 9.5, value: 9.5, label: i18n.t('USER.DATA.TIMEZONE.95') },
  { id: 9, value: 9, label: i18n.t('USER.DATA.TIMEZONE.9') },
  { id: 8.75, value: 8.75, label: i18n.t('USER.DATA.TIMEZONE.875') },
  { id: 8, value: 8, label: i18n.t('USER.DATA.TIMEZONE.8') },
  { id: 7, value: 7, label: i18n.t('USER.DATA.TIMEZONE.7') },
  { id: 6.5, value: 6.5, label: i18n.t('USER.DATA.TIMEZONE.65') },
  { id: 6, value: 6, label: i18n.t('USER.DATA.TIMEZONE.6') },
  { id: 5.75, value: 5.75, label: i18n.t('USER.DATA.TIMEZONE.575') },
  { id: 5.5, value: 5.5, label: i18n.t('USER.DATA.TIMEZONE.55') },
  { id: 5, value: 5, label: i18n.t('USER.DATA.TIMEZONE.5') },
  { id: 4.5, value: 4.5, label: i18n.t('USER.DATA.TIMEZONE.45') },
  { id: 4, value: 4, label: i18n.t('USER.DATA.TIMEZONE.4') },
  { id: 3, value: 3, label: i18n.t('USER.DATA.TIMEZONE.3') },
  { id: 2, value: 2, label: i18n.t('USER.DATA.TIMEZONE.2') },
  { id: 1, value: 1, label: i18n.t('USER.DATA.TIMEZONE.1') },
  { id: 0, value: 0, label: i18n.t('USER.DATA.TIMEZONE.0') },
  { id: -1, value: -1, label: i18n.t('USER.DATA.TIMEZONE.-1') },
  { id: -2, value: -2, label: i18n.t('USER.DATA.TIMEZONE.-2') },
  { id: -2.5, value: -2.5, label: i18n.t('USER.DATA.TIMEZONE.-25') },
  { id: -3, value: -3, label: i18n.t('USER.DATA.TIMEZONE.-3') },
  { id: -4, value: -4, label: i18n.t('USER.DATA.TIMEZONE.-4') },
  { id: -5, value: -5, label: i18n.t('USER.DATA.TIMEZONE.-5') },
  { id: -6, value: -6, label: i18n.t('USER.DATA.TIMEZONE.-6') },
  { id: -7, value: -7, label: i18n.t('USER.DATA.TIMEZONE.-7') },
  { id: -8, value: -8, label: i18n.t('USER.DATA.TIMEZONE.-8') },
  { id: -9, value: -9, label: i18n.t('USER.DATA.TIMEZONE.-9') },
  { id: -9.5, value: -9.5, label: i18n.t('USER.DATA.TIMEZONE.-95') },
  { id: -10, value: -10, label: i18n.t('USER.DATA.TIMEZONE.-10') },
  { id: -11, value: -11, label: i18n.t('USER.DATA.TIMEZONE.-11') },
];

export enum TIMEZONE_ENUM {
  GREENWICH = 0,
  CENTRAL_EUROPEAN_TIME = 1,
  EASTERN_EUROPEAN_TIME = 2,
  KYIV_STANDARD_TIME = 3,
  GULF_STANDARD_TIME = 4,
  PAKISTAN_STANDARD_TIME = 5,
  BANGLADESH_STANDARD_TIME = 6,
  INDOCHINA_TIME = 7,
  CHINA_STANDARD_TIME = 8,
  JAPAN_STANDARD_TIME = 9,
  AUSTRALIAN_EASTERN_STANDARD_TIME = 10,
  SOLOMON_ISLANDS_TIME = 11,
  FIJI_TIME = 12,
  TONGA_TIME = 13,
  LINE_ISLANDS_TIME = 14,
  CHATHAM_ISLANDS_TIME = 12.75,
  LORD_HOWE_STANDARD_TIME = 10.5,
  AUSTRALIAN_CENTRAL_STANDARD_TIME = 9.5,
  AUSTRALIAN_CENTRAL_WESTERN_STANDARD_TIME = 8.75,
  COCOS_ISLANDS_TIME = 6.5,
  NEPAL_TIME = 5.75,
  INDIA_STANDARD_TIME = 5.5,
  AFGHANISTAN_TIME = 4.5,
  AZORES_TIME = -1,
  SOUTH_GEORGIA_TIME = -2,
  NEWFOUNDLAND_STANDARD_TIME = -2.5,
  ARGENTINA_TIME = -3,
  ATLANTIC_STANDARD_TIME = -4,
  EASTERN_STANDARD_TIME = -5,
  CENTRAL_STANDARD_TIME = -6,
  MOUNTAIN_STANDARD_TIME = -7,
  PACIFIC_STANDARD_TIME = -8,
  ALASKA_STANDARD_TIME = -9,
  MARQUESAS_TIME = -9.5,
  HAWAII_ALEUTIAN_STANDARD_TIME = -10,
  SAMOA_STANDARD_TIME = -11,
}

export const TIMEZONE_VIEW = {
  [TIMEZONE_ENUM.GREENWICH]: i18n.t('USER.DATA.TIMEZONE.0'),
  [TIMEZONE_ENUM.CENTRAL_EUROPEAN_TIME]: i18n.t('USER.DATA.TIMEZONE.1'),
  [TIMEZONE_ENUM.EASTERN_EUROPEAN_TIME]: i18n.t('USER.DATA.TIMEZONE.2'),
  [TIMEZONE_ENUM.KYIV_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.3'),
  [TIMEZONE_ENUM.GULF_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.4'),
  [TIMEZONE_ENUM.PAKISTAN_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.5'),
  [TIMEZONE_ENUM.BANGLADESH_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.6'),
  [TIMEZONE_ENUM.INDOCHINA_TIME]: i18n.t('USER.DATA.TIMEZONE.7'),
  [TIMEZONE_ENUM.CHINA_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.8'),
  [TIMEZONE_ENUM.JAPAN_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.9'),
  [TIMEZONE_ENUM.AUSTRALIAN_EASTERN_STANDARD_TIME]: i18n.t(
    'USER.DATA.TIMEZONE.10',
  ),
  [TIMEZONE_ENUM.SOLOMON_ISLANDS_TIME]: i18n.t('USER.DATA.TIMEZONE.11'),
  [TIMEZONE_ENUM.FIJI_TIME]: i18n.t('USER.DATA.TIMEZONE.12'),
  [TIMEZONE_ENUM.TONGA_TIME]: i18n.t('USER.DATA.TIMEZONE.13'),
  [TIMEZONE_ENUM.LINE_ISLANDS_TIME]: i18n.t('USER.DATA.TIMEZONE.14'),
  [TIMEZONE_ENUM.CHATHAM_ISLANDS_TIME]: i18n.t('USER.DATA.TIMEZONE.1275'),
  [TIMEZONE_ENUM.LORD_HOWE_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.105'),
  [TIMEZONE_ENUM.AUSTRALIAN_CENTRAL_STANDARD_TIME]: i18n.t(
    'USER.DATA.TIMEZONE.95',
  ),
  [TIMEZONE_ENUM.AUSTRALIAN_CENTRAL_WESTERN_STANDARD_TIME]: i18n.t(
    'USER.DATA.TIMEZONE.875',
  ),
  [TIMEZONE_ENUM.COCOS_ISLANDS_TIME]: i18n.t('USER.DATA.TIMEZONE.65'),
  [TIMEZONE_ENUM.NEPAL_TIME]: i18n.t('USER.DATA.TIMEZONE.575'),
  [TIMEZONE_ENUM.INDIA_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.55'),
  [TIMEZONE_ENUM.AFGHANISTAN_TIME]: i18n.t('USER.DATA.TIMEZONE.45'),
  [TIMEZONE_ENUM.AZORES_TIME]: i18n.t('USER.DATA.TIMEZONE.-1'),
  [TIMEZONE_ENUM.SOUTH_GEORGIA_TIME]: i18n.t('USER.DATA.TIMEZONE.-2'),
  [TIMEZONE_ENUM.NEWFOUNDLAND_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-25'),
  [TIMEZONE_ENUM.ARGENTINA_TIME]: i18n.t('USER.DATA.TIMEZONE.-3'),
  [TIMEZONE_ENUM.ATLANTIC_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-4'),
  [TIMEZONE_ENUM.EASTERN_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-5'),
  [TIMEZONE_ENUM.CENTRAL_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-6'),
  [TIMEZONE_ENUM.MOUNTAIN_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-7'),
  [TIMEZONE_ENUM.PACIFIC_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-8'),
  [TIMEZONE_ENUM.ALASKA_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-9'),
  [TIMEZONE_ENUM.MARQUESAS_TIME]: i18n.t('USER.DATA.TIMEZONE.-95'),
  [TIMEZONE_ENUM.HAWAII_ALEUTIAN_STANDARD_TIME]: i18n.t(
    'USER.DATA.TIMEZONE.-10',
  ),
  [TIMEZONE_ENUM.SAMOA_STANDARD_TIME]: i18n.t('USER.DATA.TIMEZONE.-11'),
};

export const TIMEZONE_FORMAT = {
  [TIMEZONE_ENUM.GREENWICH]: `+00:00`,
  [TIMEZONE_ENUM.CENTRAL_EUROPEAN_TIME]: `+01:00`,
  [TIMEZONE_ENUM.EASTERN_EUROPEAN_TIME]: `+02:00`,
  [TIMEZONE_ENUM.KYIV_STANDARD_TIME]: `+03:00`,
  [TIMEZONE_ENUM.GULF_STANDARD_TIME]: `+04:00`,
  [TIMEZONE_ENUM.PAKISTAN_STANDARD_TIME]: `+05:00`,
  [TIMEZONE_ENUM.BANGLADESH_STANDARD_TIME]: `+06:00`,
  [TIMEZONE_ENUM.INDOCHINA_TIME]: `+07:00`,
  [TIMEZONE_ENUM.CHINA_STANDARD_TIME]: `+08:00`,
  [TIMEZONE_ENUM.JAPAN_STANDARD_TIME]: `+09:00`,
  [TIMEZONE_ENUM.AUSTRALIAN_EASTERN_STANDARD_TIME]: `+10:00`,
  [TIMEZONE_ENUM.SOLOMON_ISLANDS_TIME]: `+11:00`,
  [TIMEZONE_ENUM.FIJI_TIME]: `+12:00`,
  [TIMEZONE_ENUM.TONGA_TIME]: `+13:00`,
  [TIMEZONE_ENUM.LINE_ISLANDS_TIME]: `+14:00`,
  [TIMEZONE_ENUM.CHATHAM_ISLANDS_TIME]: `-12:45`,
  [TIMEZONE_ENUM.LORD_HOWE_STANDARD_TIME]: `+10:30`,
  [TIMEZONE_ENUM.AUSTRALIAN_CENTRAL_STANDARD_TIME]: `+09:30`,
  [TIMEZONE_ENUM.AUSTRALIAN_CENTRAL_WESTERN_STANDARD_TIME]: `+08:45`,
  [TIMEZONE_ENUM.COCOS_ISLANDS_TIME]: `+06:30`,
  [TIMEZONE_ENUM.NEPAL_TIME]: `+05:45`,
  [TIMEZONE_ENUM.INDIA_STANDARD_TIME]: `+05:30`,
  [TIMEZONE_ENUM.AFGHANISTAN_TIME]: `+04:30`,
  [TIMEZONE_ENUM.AZORES_TIME]: `-01:00`,
  [TIMEZONE_ENUM.SOUTH_GEORGIA_TIME]: `-02:00`,
  [TIMEZONE_ENUM.NEWFOUNDLAND_STANDARD_TIME]: `-02:30`,
  [TIMEZONE_ENUM.ARGENTINA_TIME]: `-03:00`,
  [TIMEZONE_ENUM.ATLANTIC_STANDARD_TIME]: `-04:00`,
  [TIMEZONE_ENUM.EASTERN_STANDARD_TIME]: `-05:00`,
  [TIMEZONE_ENUM.CENTRAL_STANDARD_TIME]: `-06:00`,
  [TIMEZONE_ENUM.MOUNTAIN_STANDARD_TIME]: `-07:00`,
  [TIMEZONE_ENUM.PACIFIC_STANDARD_TIME]: `-08:00`,
  [TIMEZONE_ENUM.ALASKA_STANDARD_TIME]: `-09:00`,
  [TIMEZONE_ENUM.MARQUESAS_TIME]: `-09:30`,
  [TIMEZONE_ENUM.HAWAII_ALEUTIAN_STANDARD_TIME]: `-10:00`,
  [TIMEZONE_ENUM.SAMOA_STANDARD_TIME]: `-11:00`,
};
