import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';

import { TextElem } from '../../common/text';
import { ModalTimeChange } from './frame/modal';
import { FormikValues } from 'formik';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { USER_ROLE } from '../../data/auth/constant';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  currentDate?: {
    timeView: string;
    dayView: string;
  };
  isTimeLoading: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  timezone: number;
  isFieldDisabled: boolean;
  timeOption: {
    label: string;
    value: string;
  }[];
  isSuccess: boolean;
  role?: USER_ROLE;
  isChangeTimeDisabled: boolean;
  dayArr?: string[];
}> = ({
  isLoading,
  isError,
  errorMessage,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  isSuccess,
  role,
  currentDate,
  isChangeTimeDisabled,
  dayArr,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SETTINGS.CONSULTATION.SUCCESS" />
      )}
      <MainContainer>
        <TextElem
          tid="CONSULTATION.USER.REASIGN.TITLE"
          type="medium"
          size="main"
          color="textPrimary"
        />
        <TextElem
          tid="CONSULTATION.USER.REASIGN.DESCRIPTION"
          size="input"
          type="light"
          color="textSecondary"
        />
        {(role === USER_ROLE.USER || role === USER_ROLE.PSYCHOLOGIST) && (
          <ModalTimeChange
            dayArr={dayArr}
            isChangeTimeDisabled={isChangeTimeDisabled}
            isFieldError={isFieldError}
            getFieldError={getFieldError}
            getFieldValue={getFieldValue}
            setFieldValue={setFieldValue}
            setValue={setValue}
            formik={formik}
            isSubmitDisabled={isSubmitDisabled}
            timeOption={timeOption}
            isFieldDisabled={isFieldDisabled}
            isTimeLoading={isTimeLoading}
          />
        )}
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(3)};
  height: max-content;
`;
