import React from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { CATALOG_ITEM_LIST, TITLE } from './constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
// import backgroundImage from '../../asset/background.jpg';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { LANDING_THEME_ID } from '../../page/knowledge-landing/constant';
import { KNOWLEDGE_LANDING_PAGE_PATH_DYNAMIC } from '../../page/knowledge-landing';
import { requestSlugViewObj, REQUEST_ENUM } from '../../data/user/constant';

export const Component: React.FC<{ title?: TITLE }> = ({
  title = TITLE.KNOWLEDGE,
}) => {
  const history = useHistory();

  const handleRedirect = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: LANDING_THEME_ID,
  ) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) {
      return;
    }
    e.preventDefault();

    if (id === LANDING_THEME_ID.DEPRESSION) {
      history.push(
        `${KNOWLEDGE_LANDING_PAGE_PATH_DYNAMIC(id)}?request=${
          requestSlugViewObj[REQUEST_ENUM.DEPRESSION]
        }`,
      );
    }

    if (id === LANDING_THEME_ID.MENTAL_TRAUMA) {
      history.push(
        `${KNOWLEDGE_LANDING_PAGE_PATH_DYNAMIC(id)}?request=${
          requestSlugViewObj[REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE]
        },${requestSlugViewObj[REQUEST_ENUM.PTSD]},${
          requestSlugViewObj[REQUEST_ENUM.RELEASE_FROM_CAPTIVITY]
        },${requestSlugViewObj[REQUEST_ENUM.SELF_HARM]},${
          requestSlugViewObj[REQUEST_ENUM.LOSS_OF_A_LOVED_ONE]
        },${requestSlugViewObj[REQUEST_ENUM.GRIEF]}`,
      );
    }

    if (id === LANDING_THEME_ID.SELF_ESTEEM) {
      history.push(
        `${KNOWLEDGE_LANDING_PAGE_PATH_DYNAMIC(id)}?request=${
          requestSlugViewObj[REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION]
        },${requestSlugViewObj[REQUEST_ENUM.LACK_OF_CONFIDENCE]}
        `,
      );
    }

    scrollToSection(LANDING_ID_ENUM.HOME);
  };

  return (
    <CardContainer>
      {title == TITLE.KNOWLEDGE ? (
        <CardHeader>
          <Icon src="./assets/svg/file.svg" alt="file" />
          <CardContainerTitle size="heading" fontFamily="e-UkraineHead">
            {i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_TEXT.TITLE')}
          </CardContainerTitle>
        </CardHeader>
      ) : (
        <CardHeader style={{ paddingBottom: '24px' }}>
          {/* <Icon src="./assets/svg/file.svg" alt="file" /> */}
          <TextElem
            size="large"
            color="textPrimary"
            type="medium"
            fontFamily="e-UkraineHead"
          >
            {i18n.t('KNOWLEDGE_LANDING.TITLE.THEME')}
          </TextElem>
        </CardHeader>
      )}

      <GridContainer spacing={6} size="list">
        {CATALOG_ITEM_LIST.map((service, index) => (
          <CardBlock
            href={`/knowledge/landing/${service.id}`}
            index={service.id}
            key={index}
            page={title}
            onClick={(e) => handleRedirect(e, service.id)}
          >
            <CardText>
              <Card>
                <ImgWrap>
                  <CardImg src={service.imgSrc} alt={service.imgAlt} />
                </ImgWrap>
                <div>
                  <CardTitle size="label" type="medium" tid={service.title} />
                  <CardSubtitle
                    size="semiSmall"
                    type="light"
                    color="textSecondary"
                    tid={service.subtitle}
                  />
                </div>
              </Card>
            </CardText>
          </CardBlock>
        ))}
      </GridContainer>
    </CardContainer>
  );
};

const ImgWrap = styled.div<{ index?: number }>`
  overflow: hidden;
  // max-width: 328px;
  height: 200px;
  // height: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 50% 50%;
  // padding: 0 ${Spacing(5)};
`;

const CardContainer = styled.div`
  // background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  // padding: ${Spacing(14)} ${Spacing(18)};
  padding: ${Spacing(14)} ${Spacing(4)};
  margin: 0 auto;
  width: 100%;
  // max-width: ${Spacing(350)};
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  // @media (max-width: 1325px) {
  //   padding: ${Spacing(14)} ${Spacing(16)};
  // }

  @media (max-width: 768px) {
    padding: ${Spacing(10)} ${Spacing(4)};
  }
`;

const CardBlock = styled.a<{ page: TITLE; index: string }>`
  text-decoration: none;
  display: grid;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid ${COLOR_DATA[COLOR_ENUM.MODAL_BACKGROUND]};
  border-radius: ${Spacing(4)};
  padding: ${Spacing(5)} ${Spacing(5)};
  gap: ${Spacing(5)};
  width: 100%;
  height: 100%;
  background-color: ${COLOR_DATA[COLOR_ENUM.MODAL_BACKGROUND]};

  ${({ index }) => {
    if (index == LANDING_THEME_ID.SELF_ESTEEM) {
      return css``;
    }
    if (index == LANDING_THEME_ID.DEPRESSION) {
      return css`
        img {
          transform: scaleX(-1) scaleY(1);
        }
      `;
    }
    if (index == LANDING_THEME_ID.MENTAL_TRAUMA) {
      return css``;
    }
  }}

  @media (max-width: 768px) {
    // max-width: 355px;
    padding: ${Spacing(5)};
  }
`;

const CardText = styled.div`
  display: grid;
  gap: 0px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
  gap: 12px;

  @media (max-width: 768px) {
    span {
      text-align: center;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px;
    }
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const CardTitle = styled(TextElem)`
  font-size: ${Spacing(4.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    padding-bottom: ${Spacing(1.5)};
  }
`;

const CardContainerTitle = styled(TextElem)`
  // padding-bottom: ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
`;

const CardSubtitle = styled(TextElem)`
  // font-size: ${Spacing(3.5)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]} !important;
  // line-height: 160%;
  line-height: 21px;
  align-self: bottom;

  @media (max-width: 768px) {
    line-height: 18px;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.CHIP]}px;
  }
`;

const CardImg = styled.img`
  // padding-bottom: ${Spacing(4)};
  width: 100%;
  height: 200px;
  object-fit: cover;
  // height: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    max-width: 328px;
  }
  @media screen and (max-width: 480px) {
    max-width: unset;
  }
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: ${Spacing(4)};
  // row-gap: ${Spacing(8)};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(CardElem)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  gap: ${Spacing(5)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media (max-width: 768px) {
    gap: ${Spacing(4)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 3fr 2fr;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  }
`;

export default Component;
