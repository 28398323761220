import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { useHistory } from 'react-router';
import { LANDING_ID_ENUM } from '../../epic/home-header/constant';

export const Elem: React.FC<{}> = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(`/home`);
    scrollToSection(LANDING_ID_ENUM.HOME);
  };

  return (
    <AuthHeader>
      <LogoContainer>
        <LogoSvg
          onClick={() => handleRedirect()}
          src="./assets/svg/header/syayvoLogo.svg"
          alt="syaivo Logo"
        />
      </LogoContainer>
    </AuthHeader>
  );
};

const LogoSvg = styled.img`
  width: 129px;
  height: 46px;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(2.25)};
`;

const AuthHeader = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: center;
  padding: ${Spacing(28)} 0 0;
  align-items: center;

  margin-bottom: ${Spacing(3)};
`;
