import React from 'react';
import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { TextElem } from '../../text';
import { Spacing } from '../../../theme';

export const CustomUserOption: React.FC<OptionProps<any>> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <OptionContainer>
        <TextElem
          type="regular"
          size="semiSmall"
          color="textSecondary"
          tid={`${data.label} - `}
        />
        <TextElem
          type="regular"
          size="semiSmall"
          color="textSecondary"
          tid={data.email}
        />
      </OptionContainer>
    </components.Option>
  );
};

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};
`;
