import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useDispatch, useSelector } from '../../lib/store';
import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { appPreloadAllLocalStateData } from '../../data/app';
import { actionTimezone } from '../profile-psycho-update-data/action';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';

export const Container: React.FC = () => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isLogged = state.logged;

  const [isTimezoneChangeModal, setIsTimezoneChangeModal] = useState(false);
  const offsetInMinute = new Date().getTimezoneOffset();
  const offsetInHour = -offsetInMinute / 60;

  const isConfirmEmail = state?.isConfirmEmail;

  const isAuth = isLogged && isConfirmEmail;
  const timezone =
    auth?.user?.timezone !== undefined && auth?.user?.timezone !== null
      ? Number(auth?.user?.timezone)
      : null;

  const localTimezoneObj = localStorage.getItem('diferentTimezone');
  const diferentTimezone = localTimezoneObj ? JSON.parse(localTimezoneObj) : {};

  function isTimezoneMatch(offsetInHour: number, timezone?: number | null) {
    if (timezone === null || typeof timezone !== 'number') return false;

    if (diferentTimezone.hasOwnProperty(timezone)) {
      return diferentTimezone[timezone] === offsetInHour;
    }

    return false;
  }

  const isShowModal =
    timezone !== offsetInHour &&
    isAuth &&
    !isTimezoneMatch(offsetInHour, timezone);

  useEffect(() => {
    if (isShowModal && isLogged) {
      setIsTimezoneChangeModal(true);
    }
  }, [timezone]);

  const onSaveTimezoneModal = (timezoneValue?: number | null) => {
    const diferentTimezone = {
      [timezoneValue ? String(timezoneValue) : String(timezone)]: offsetInHour,
    };

    localStorage.setItem('diferentTimezone', JSON.stringify(diferentTimezone));

    setIsTimezoneChangeModal(false);
  };

  const onSuccess = () => {
    if (isTimezoneChangeModal) {
      setIsTimezoneChangeModal(false);
    }

    dispatch(appPreloadAllLocalStateData());

    return query.invalidateQueries(MODULE_NAME);
  };

  const query = useQueryClient();
  const dispatch = useDispatch();

  const updateTimezoneAction = useMutation(actionTimezone, {
    onSuccess,
  });

  const onCloseTimezoneModal = () => {
    setIsTimezoneChangeModal(false);
  };

  const changedTimezone = {
    label: TIMEZONE_VIEW[offsetInHour as TIMEZONE_ENUM],
    value: offsetInHour,
  };

  const defaultTimezone = {
    label: TIMEZONE_VIEW[timezone as TIMEZONE_ENUM],
    value: timezone,
  };

  const handleUpdateTimezone = (timezone: number) => {
    return updateTimezoneAction.mutate(timezone);
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER =
      updateTimezoneAction.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };
  return (
    <Component
      errorMessage={getErrorMessage()}
      isError={updateTimezoneAction.isError}
      isLoading={updateTimezoneAction.isLoading}
      onSaveTimezoneModal={onSaveTimezoneModal}
      isOpen={isTimezoneChangeModal}
      onClose={onCloseTimezoneModal}
      defaultTimezone={defaultTimezone}
      changedTimezone={changedTimezone}
      handleUpdate={handleUpdateTimezone}
    />
  );
};
