import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { REVIEW_LIST } from './constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';
import { FlexElem } from '../../common/flex';

import star from '../../asset/svg/common/star.svg';

export const Component: React.FC<{}> = () => {
  return (
    <CardContainer>
      <CardHeader>
        <CardContainerTitle size="mainHeadTitle" fontFamily="Oks-Free">
          {i18n.t('LANDING.HOME_REVIEW.TITLE')}
        </CardContainerTitle>
        <CardContainerSubtitle>
          {i18n.t('LANDING.HOME_REVIEW.SUBTITLE')}
        </CardContainerSubtitle>
      </CardHeader>
      <GridContainer>
        {REVIEW_LIST.map((service, index) => (
          <CardBlock key={index}>
            <Profile>
              <CardImg src={service.imgSrc} alt={service.imgAlt} />
              <GridElem spacing={3} style={{ width: '100%' }}>
                <FlexElem spacing={2} style={{ width: 'max-content' }}>
                  <TextElem
                    color="textPrimary"
                    type="medium"
                    size="label"
                    lineHeight="18px"
                    // tid="Софія"
                    // style={{ constants: 'pre-line' }}
                    // oneLine
                  >
                    {`${service.name} ${service.surname}`}
                    {/* {'Софія '} */}
                    {/* {i18n.t('Софія ')}
                    <TextElem
                      // style={{ constants: 'pre-line' }}
                      color="textPrimary"
                      type="medium"
                      size="label"
                      tid="Зотова"
                      lineHeight="18px"
                      oneLine
                    /> */}
                  </TextElem>
                  {/* <CardTitle>{service.title}</CardTitle> */}
                  <FlexElem spacing={0.5} style={{ width: 'max-content' }}>
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                  </FlexElem>
                </FlexElem>
                <TextElem
                  color="textSecondary"
                  type="light"
                  size="small"
                  // tid="Категоряі або запит роботи з яким прийшов клієнт"
                  tid={service.subtitle}
                />
                {/* <CardSubtitle>{service.subtitle}</CardSubtitle> */}
              </GridElem>
            </Profile>
            <TextElem
              style={{ width: '100%' }}
              color="textSecondary"
              size="semiSmall"
              type="regular"
              tid={service.description}
            />
            {/* <ButtonElemStyled tid="COMMON.SIGN_UP" /> */}
          </CardBlock>
        ))}
      </GridContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  // background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-size: cover;
  // padding: ${Spacing(20)} ${Spacing(18)} ${Spacing(6)};
  padding: ${Spacing(20)} ${Spacing(4)} ${Spacing(6)};
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  // max-width: ${Spacing(350)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  @media (max-width: 1325px) {
    padding: ${Spacing(16)} ${Spacing(4)} ${Spacing(15)};
  }

  @media (max-width: 768px) {
    padding: ${Spacing(8)} ${Spacing(4)}; // было 20
  }

  @media (max-width: 480px) {
    // padding: ${Spacing(4)};
  }
`;

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  padding: 28px;
  border-radius: 12px;
  width: 100%;
  gap: ${Spacing(5)};
`;

const Profile = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 768px) {
    // flex-direction: column;
    // margin: 0 auto;
    // width: max-content;
  }
`;

const CardHeader = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
  width: 100%;
`;

const CardTitle = styled(TextElem)`
  font-size: ${Spacing(4.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const CardContainerTitle = styled(TextElem)`
  padding-bottom: ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 768px) {
    font-size: ${Spacing(15)};
  }

  @media (max-width: 480px) {
    font-size: ${Spacing(10)};
  }
`;

const CardContainerSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-align: center;
  @media (max-width: 768px) {
    font-size: ${Spacing(3)};
  }

  @media (max-width: 480px) {
    font-size: ${Spacing(2.5)};
  }
`;

const CardSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
`;

const CardImg = styled.img`
  // padding-bottom: ${Spacing(4)};
  max-width: 60px;
  max-height: 60px;
  border-radius: 10px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 768px) {
    // flex-direction: column;
    // max-height: unset;
    display: none;
    max-width: 100%;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  // flex-wrap: wrap;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  // grid-template-columns: 1fr 1fr 1fr;
  row-gap: ${Spacing(8)};
  column-gap: 16px;
  // padding: 72px;
  // overflow-x: auto;

  overflow: hidden;

  @media (max-width: 1325px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    // padding: 0 64px;
  }
`;

const Card = styled(CardElem)`
  padding: 5px;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

export default Component;
