import React from 'react';
import styled, { css } from 'styled-components';

import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { GridElem } from '../../common/grid';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { i18n } from '../../lib/lang';
import { LANDING_ID_ENUM, LANDING_ID_PROPS, LINK } from './constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';

export const Component: React.FC<LANDING_ID_PROPS> = ({ handleNavClick }) => {
  return (
    <Wrapper>
      <FooterContainer>
        <HomeHeader>
          <LogoContainer>
            <img src="./assets/svg/header/syayvoLogo.svg" alt="syaivo Logo" />
          </LogoContainer>
          <ButtonsContainer>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.TELEGRAM`)}>
                <img
                  src="./assets/svg/telegram_logo_black.svg"
                  alt="telegram_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.FACEBOOK`)}>
                <img
                  src="./assets/svg/facebook_logo_black.svg"
                  alt="facebook_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.INSTAGRAM`)}>
                <img
                  src="./assets/svg/instagram_logo_black.svg"
                  alt="instagram_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.YOUTUBE`)}>
                <IconStyled
                  src="./assets/svg/youtube_logo_black.svg"
                  alt="instagram_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.TIKTOK`)}>
                <IconStyled
                  src="./assets/svg/tiktok_logo_black.svg"
                  alt="instagram_logo"
                />
              </a>
            </ButtonElem>
          </ButtonsContainer>
        </HomeHeader>
        <GridContainer>
          <FlexContainer>
            <TextElem
              size="main"
              color="textPrimary"
              type="medium"
              tid="LANDING.HOME_FOOTER.TITLE.SERVICE"
            />
            {Object.values(LANDING_ID_ENUM).map((section, index, array) => (
              <NavLink
                key={section}
                // href={`#${section}`}
                href={
                  index === array.length - 2
                    ? '/faq'
                    : index === array.length - 1
                    ? '/knowledge'
                    : `/home#${section}`
                }
                onClick={
                  index === array.length - 2 || index === array.length - 1
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                        handleNavClick(section);
                        window.location.href = `/home#${section}`;
                      }
                }
                // onClick={handleNavClick(section)}
                // isActive={activeSection === section}
              >
                <TextLink
                  oneLine
                  color="textSecondary"
                  size="semiSmall"
                  tid={i18n.t(`LANDING.HOME_FOOTER.NAV_BAR.${section}`)}
                />
              </NavLink>
            ))}
          </FlexContainer>

          {/* <LogoContainer>
            <Dot />
          </LogoContainer> */}
          <FlexContainer>
            <TextElem
              size="main"
              color="textPrimary"
              type="medium"
              tid="LANDING.HOME_FOOTER.TITLE.POLICY"
            />
            {LINK.map((link) => {
              const hrefText = i18n.t(link.href);

              return (
                <Link key={link.tid} target="_blank" href={hrefText}>
                  <TextLink
                    oneLine
                    color="textSecondary"
                    size="semiSmall"
                    tid={link.tid}
                  />
                </Link>
              );
            })}
          </FlexContainer>
          <FlexContainer>
            <TextElem
              size="main"
              color="textPrimary"
              type="medium"
              tid="LANDING.HOME_FOOTER.TITLE.CONTACT"
            />
            {/* <ContactContainer> */}
            <AMail href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.MAIN')}`}>
              <TextElemMail
                color="textSecondary"
                size="semiSmall"
                tid="LANDING.HOME_FOOTER.MAIL.MAIN"
              />
            </AMail>
            <AMail href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.INFO')}`}>
              <TextElemMail
                color="textSecondary"
                size="semiSmall"
                tid="LANDING.HOME_FOOTER.MAIL.INFO"
              />
            </AMail>

            {/* <Dot /> */}
            <AMail
              href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.PARTNER')}`}
            >
              <TextElemMail
                color="textSecondary"
                size="semiSmall"
                tid="LANDING.HOME_FOOTER.MAIL.PARTNER"
              />
            </AMail>
            <AMail
              href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.SERVICE')}`}
            >
              <TextElemMail
                color="textSecondary"
                size="semiSmall"
                tid="LANDING.HOME_FOOTER.MAIL.SERVICE"
              />
            </AMail>
            <PhoneNumberText href="tel:+380980119429">
              <TextElem
                color="textSecondary"
                size="semiSmall"
                tid="LANDING.HOME_FOOTER.NUMBER"
              />
            </PhoneNumberText>
            {/* </ContactContainer> */}
          </FlexContainer>
          {/* <NavBarFooter> */}
          <FlexContainer style={{ gap: '12px' }}>
            <TextElem
              color="textPrimary"
              size="semiSmall"
              tid="LANDING.HOME_FOOTER.COPYRIGHT"
              lineHeight="14px"
            />
            <TextElem
              color="textSecondary"
              size="semiSmall"
              tid="LANDING.HOME_FOOTER.COPYRIGHT_SUB"
              lineHeight="14px"
            />
          </FlexContainer>
          {/* </NavBarFooter> */}
        </GridContainer>
      </FooterContainer>
    </Wrapper>
  );
};

const IconStyled = styled.img`
  width: ${Spacing(6)};
  height: ${Spacing(6)};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 ${Spacing(3)};
`;

const AMail = styled.a`
  line-height: 1em;
`;

const TextElemMail = styled(TextElem)`
  text-decoration: underline;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
  }
`;
const PhoneNumberText = styled.a`
  span {
    // text-decoration: underline;
    transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

    &:hover {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
    }
  }
`;

export const PaddingContainer = styled.div`
  padding: ${Spacing(3)};
  margin: 0 auto;
  display: grid;
  gap: ${Spacing(5)};
  display: flex;
  width: 100%;

  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(6 * 2)}
  );

  @media (max-width: 768px) {
    padding-top: ${Spacing(6)};
  }
`;

const TextLink = styled(TextElem)`
  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }
`;

const GridContainer = styled(GridElem)`
  font-family: 'e-Ukraine', sans-serif;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, max-content);
  width: 100%;

  @media screen and (max-width: 1161px) {
    grid-template-columns: 1fr 1fr;
    gap: ${Spacing(8)};
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: ${Spacing(8)};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  flex-wrap: wrap;
  width: 100%;

  flex-direction: column;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

  @media (max-width: 360px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
    }
  }
`;

const ButtonsContainer = styled.div`
  @media (max-width: 768px) {
    gap: ${Spacing(2)};
    justify-content: space-around;
  }

  @media (max-width: 350px) {
    display: flex;
    justify-content: space-between;
    padding: ${Spacing(4)};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(4)};

  @media (max-width: 768px) {
    gap: ${Spacing(2)};
  }
`;

const HomeHeader = styled.div`
  padding: 0px ${Spacing(6)};
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: ${Spacing(21)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  z-index: 20;

  @media (max-width: 768px) {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: ${Spacing(4)};
  }

  @media (max-width: 350px) {
    display: block;
  }
`;

const FooterContainer = styled.div`
  padding: ${Spacing(8)} ${Spacing(15)} ${Spacing(9)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(8)};

  @media (max-width: 768px) {
    padding: ${Spacing(8)} ${Spacing(5)};
  }

  @media (max-width: 480px) {
    padding: ${Spacing(2.5)};
  }
`;

const NavLink = styled.a<{ isActive?: boolean }>`
  text-decoration: none;
  display: inline-block;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }
`;
