import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { UserListContainer } from '../../epic/user-list';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { LayoutFooterElem } from '../../common/layout-footer';
import { HeaderContainer } from '../../epic/header';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { UserListFilter } from '../../epic/user-list-filter';
import { FILTER_OPTION } from '../../epic/user-list-filter/constant';
import { HomeFooterContainer } from '../../epic/home-footer';

export const Page: React.FC = () => {
  const [filterOption, setFilterOption] = useState<FILTER_OPTION>();

  const setOption = (e: any) => {
    setFilterOption(e);
  };
  return (
    <>
      {ReactDOM.createPortal(
        <UserListFilter setOption={setOption} />,
        document.getElementById('layoutApp') || document.body,
      )}
      <PageElem>
        <LayoutAppElem>
          {/* <HeaderContainer
            icon={userIcon}
            title="USER.LIST.HEADER"
            size="default"
          /> */}
          <LayoutElem size="default">
            <LayoutSectionElem spacing={8}>
              <UserListContainer filterOption={filterOption} />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
