import React, { useState } from 'react';
import styled from 'styled-components';

import { GridElem } from '../../../common/grid';
import { AuthRecoveryResendContainer } from '../../auth-recovery-resend';
import { TimerElem } from '../../../common/timer';
import { TIMER_CONFIG } from '../constant';
import { AuthVerificationResendContainer } from '../../auth-verification-resend';

export const ResendComponent: React.FC<{ email?: string }> = ({
  email = '',
}) => {
  const [active, setActive] = useState<boolean | null>(null);

  const handleSetActive = (status: boolean) => () => {
    setActive(status);
  };

  return (
    <GridElem spacing={5} size="mod">
      <AuthVerificationResendContainer
        actionCb={handleSetActive(false)}
        active={active}
      />
      <Message>
        <TimerElem
          setVisible={handleSetActive(true)}
          visible={active}
          minutes={TIMER_CONFIG.minutes}
          seconds={TIMER_CONFIG.seconds}
        />
      </Message>
    </GridElem>
  );
};
const Message = styled.div`
  text-align: center;
  & > * {
    display: inline;
  }
`;
