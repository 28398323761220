import React from 'react';

import styled, { css } from 'styled-components';
import { COLOR_DATA, COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
  SIZE_ICON_DATA,
  SIZE_ICON_TYPE,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE, ROLE_ENUM, ROLE_TYPE } from './constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { Spacing } from '../../theme';
import uploadSvg from '../../asset/svg/file/arrow-upload.svg';
import uploadInactiveSvg from '../../asset/svg/file/arrow-upload-inactive.svg';
import addSvg from '../../asset/svg/common/add-dark.svg';

export const Elem: React.FC<PROPS_TYPE> = ({
  children,
  tid,
  tvalue,
  color = 'default',
  disabled = false,
  onClick,
  iconSize = 'small',
  iconLeft,
  textType = 'medium',
  ref,
  className,
  sizeText = 'default',
  iconRight,
  type = ROLE_ENUM.BUTTON,
  padding,
  textColor,
}) => {
  const stateUploadIcon = disabled ? uploadInactiveSvg : uploadSvg;
  const uploadIcon = type === ROLE_ENUM.UPLOAD ? stateUploadIcon : null;
  const addIcon = type === ROLE_ENUM.ADD ? addSvg : null;

  const isIcon = uploadIcon || iconLeft || iconRight || addIcon;

  const handleClick = (e: any) => {
    if (onClick) onClick(e);
  };

  return (
    <ButtonStyled
      onClick={handleClick}
      disabled={disabled}
      className={className}
      ref={ref}
      type={type}
      padding={padding}
    >
      <Content isIcon={isIcon}>
        {uploadIcon && (
          <Icon
            disabled={disabled}
            src={uploadIcon}
            iconSize="small"
            color={color}
          />
        )}

        {iconLeft && (
          <Icon
            disabled={disabled}
            src={iconLeft}
            iconSize={iconSize}
            color={color}
          />
        )}
        {tid ? (
          <TextElem
            tid={tid}
            tvalue={tvalue}
            size={sizeText}
            type={textType}
            color={color}
          />
        ) : (
          children
        )}
        {iconRight && (
          <Icon
            disabled={disabled}
            src={iconRight}
            iconSize={iconSize}
            color={color}
          />
        )}
        {addIcon && <Icon src={addIcon} iconSize="medium" color={color} />}
      </Content>
    </ButtonStyled>
  );
};
const ButtonStyled = styled.button<{
  type: ROLE_TYPE | any;
  padding?: string;
  textColor?: COLOR_TYPE;
}>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  width: 100%;
  font-family: 'e-Ukraine', sans-serif !important;

  ${({ disabled, type }) =>
    type === ROLE_ENUM.CANCEL &&
    css`
      padding: 15px ${Spacing(8)};
      background-color: rgba(168, 47, 47, 0.1);
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

      span {
        color: ${({ theme }) => theme[COLOR_ENUM.NO_SUCCESS]} !important;
        line-height: 1em;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.MEDIUM
        ]} !important;
      }

      ${disabled &&
      css`
        opacity: 0.7;
      `}

      ${!disabled &&
      css`
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }

        &:active {
          cursor: pointer;
          opacity: 0.8;
        }
      `}
    `}

  ${({ disabled, type }) =>
    type === ROLE_ENUM.UPLOAD &&
    css`
      padding: ${Spacing(5)} ${Spacing(8)};
      font-family: 'e-UkraineHead' !important;
      background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

      span {
        color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
        line-height: 1em;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.REGULAR
        ]} !important;
      }

      ${disabled &&
      css`
        opacity: 0.7;
      `}

      ${!disabled &&
      css`
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      `}

        &:active {
        cursor: pointer;
        opacity: 0.8;
      }
    `}

    
  ${({ disabled, type }) =>
    type === ROLE_ENUM.SECONDARY &&
    css`
      padding: ${Spacing(5)} ${Spacing(8)};
      font-family: 'e-UkraineHead' !important;
      background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

      span {
        color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
        line-height: 1em;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.REGULAR
        ]} !important;
      }

      ${disabled &&
      css`
        opacity: 0.7;
      `}

      ${!disabled &&
      css`
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      `}

        &:active {
        cursor: pointer;
        opacity: 0.8;
      }
    `}

  ${({ disabled, type }) =>
    type === ROLE_ENUM.SUBMIT &&
    css`
      padding: ${Spacing(5)} ${Spacing(8)};
      font-family: 'e-UkraineHead' !important;
      background-color: ${COLOR_DATA[COLOR_ENUM.DEFAULT]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

      & > * > * {
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.REGULAR
        ]} !important;
        line-height: 1em;
      }

      span {
        color: ${COLOR_DATA[COLOR_ENUM.WHITE]} !important;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.MEDIUM
        ]} !important;
      }

      :disabled {
        opacity: 0.7;
        background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};

        span {
          color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]} !important;
        }
      }

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }

        &:active {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}
    
  ${({ disabled, type }) =>
    type === ROLE_ENUM.HELP &&
    css`
      padding: ${Spacing(5)} ${Spacing(8)};
      font-family: 'e-UkraineHead' !important;
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
      background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

      & > * > * {
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.REGULAR
        ]} !important;
        line-height: 1em;
      }

      span {
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]} !important;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.MEDIUM
        ]} !important;
      }

      :disabled {
        opacity: 0.7;

        span {
          font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
          color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]} !important;
        }
      }

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }

        &:active {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}

  ${({ disabled, type }) =>
    type === ROLE_ENUM.SAVE &&
    css`
      padding: ${Spacing(5)} ${Spacing(8)};
      font-family: 'e-UkraineHead' !important;
      background-color: ${COLOR_DATA[COLOR_ENUM.INPUT]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

      & > * > * {
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
        line-height: 1em;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.MEDIUM
        ]} !important;
      }

      ${disabled &&
      css`
        opacity: 0.7;
      `}

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }

        &:active {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}

  ${({ theme, disabled, type, padding }) =>
    type === ROLE_ENUM.ADD &&
    css`
      padding: 18px;
      background-color: unset;
      border: 1px solid ${theme[COLOR_ENUM.BORDER]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.DEFAULT
      ]};
      div {
        justify-content: space-between !important;
      }

      ${disabled &&
      css`
        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
          opacity: ${VALUE_OPACITY_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};
        }
      `}
      &:not(:disabled) {
        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_PRIMARY]};
        }

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
        &:active {
          opacity: 0.9;
        }
      }
    `}


  ${({ theme, disabled, type, padding }) =>
    type === ROLE_ENUM.CHANGE &&
    css`
      padding: ${padding ? padding : '19px 20px'};

      ${disabled &&
      css`
        background-color: ${theme[COLOR_ENUM.BUTTON_DISABLED]};

        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        background: ${theme[COLOR_ENUM.BUTTON_SECONDARY]};
        & > * > * {
          color: ${theme[COLOR_ENUM.DEFAULT]};
        }

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}

    ${({ theme, disabled, type, padding }) =>
    type === ROLE_ENUM.BUTTON &&
    css`
      padding: ${padding ? padding : '20px'};
      background: ${theme[COLOR_ENUM.DEFAULT_OPACITY]};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

      span {
        color: ${theme[COLOR_ENUM.DEFAULT]};
        line-height: 1em;
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.DEFAULT]}px;
        font-weight: ${SIZE_FONT_WEIGHT_DATA[
          SIZE_FONT_WEIGHT_ENUM.MEDIUM
        ]} !important;
      }

      ${disabled &&
      css`
        background-color: #924e271a;

        span {
          color: #924e2780;
        }
      `}

      &:not(:disabled) {
        &:hover {
          cursor: pointer;

          &:not(:active) {
            background-color: #924e2726;
          }
        }

        &:active {
          background-color: #924e2740;
        }
      }
    `}

      ${({ theme, disabled, type }) =>
    type === ROLE_ENUM.NAV_BUTTON &&
    css`
      padding: 19px ${Spacing(4)};
      max-height: 54px;
      background: ${COLOR_DATA[COLOR_ENUM.TRANSPARENT]};
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

      ${disabled &&
      css`
        background-color: ${theme[COLOR_ENUM.BUTTON_DISABLED]};
        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        & > * > * {
          color: ${COLOR_DATA[COLOR_ENUM.LOGIN]};
          font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.REGULAR
          ]} !important;
        }

        &:hover {
          background-color: ${theme[COLOR_ENUM.BUTTON_SECONDARY_HOVER]};
          color: ${theme[COLOR_ENUM.FILE_TITLE]};
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}
     ${({ theme, disabled, type }) =>
    type === ROLE_ENUM.LOGIN &&
    css`
      padding: ${Spacing(3)} ${Spacing(6)};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
      ${disabled &&
      css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_DISABLED]};
        & > * > * {
          color: ${COLOR_DATA[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        background: ${COLOR_DATA[COLOR_ENUM.BUTTON_LOGIN_HEADER]};
        color: ${COLOR_DATA[COLOR_ENUM.BUTTON_PRIMARY_SECOND]};
        border-radius: ${SIZE_BORDER_RADIUS_DATA[
          SIZE_BORDER_RADIUS_ENUM.LANDING
        ]}px;

        & > * > * {
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.MEDIUM
          ]} !important;
        }

        &:hover {
          background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_PRIMARY_SECOND]};
          color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}
        ${({ theme, disabled, type }) =>
    type === ROLE_ENUM.REGISTER &&
    css`
      padding: ${Spacing(3)} ${Spacing(6)};
      ${disabled &&
      css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_DISABLED]};
        & > * > * {
          color: ${COLOR_DATA[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_PRIMARY_SECOND]};
        color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
        border-radius: ${SIZE_BORDER_RADIUS_DATA[
          SIZE_BORDER_RADIUS_ENUM.LANDING
        ]}px;

        & > * > * {
          font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.MEDIUM
          ]} !important;
        }

        &:hover {
          background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_SECONDARY_HOVER]};
          color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}
     ${({ theme, disabled, type }) =>
    type === ROLE_ENUM.SOCIAL &&
    css`
      padding: 2px;
      height: 30px;
      width: 40px;
      ${disabled &&
      css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_DISABLED]};
        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        background: ${COLOR_DATA[COLOR_ENUM.TRANSPARENT]};
        color: ${COLOR_DATA[COLOR_ENUM.LOGIN]};
        border-radius: ${SIZE_BORDER_RADIUS_DATA[
          SIZE_BORDER_RADIUS_ENUM.CIRCLE
        ]}px;

        & > * > * {
          font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.REGULAR
          ]} !important;
        }

        &:hover {
          background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_SECONDARY_HOVER]};
          color: ${COLOR_DATA[COLOR_ENUM.BUTTON_TEXT]};
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}
    ${({ theme, disabled, type }) =>
    type === ROLE_ENUM.LANDING &&
    css`
      padding: 15px ${Spacing(8)};
      @media (max-width: 768px) {
        padding: ${Spacing(5)} ${Spacing(8)};
        font-size: ${Spacing(4)} !important;
      }
      width: fit-content;
      font-family: 'e-UkraineHead' !important;
      max-height: ${Spacing(17.5)};
      ${disabled &&
      css`
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_DISABLED]};
        & > * > * {
          color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
        }
      `}

      &:not(:disabled) {
        background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_PRIMARY_SECOND]};
        color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
        border-radius: ${SIZE_BORDER_RADIUS_DATA[
          SIZE_BORDER_RADIUS_ENUM.LANDING
        ]}px;
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;

        & > * > * {
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.REGULAR
          ]} !important;
          @media (max-width: 768px) {
            font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
          }
        }

        &:hover {
          background-color: ${COLOR_DATA[COLOR_ENUM.BUTTON_SECONDARY_HOVER]};
          color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
          cursor: pointer;
          opacity: 0.8;
        }
      }
    `}

  padding: ${({ padding }) => padding && padding};
`;

const Content = styled.div<{ isIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isIcon }) => (isIcon ? 'start' : 'center')};
  text-align: center;
  width: 100%;
  gap: 11px;
`;

const Icon = styled.img<{
  color?: COLOR_TYPE;
  iconSize: SIZE_ICON_TYPE;
  disabled?: boolean;
}>`
  ${({ color = COLOR_ENUM.DEFAULT, iconSize, disabled }) => css`
    fill: ${({ theme }) => theme[color]};
    opacity: ${disabled && '0.5'};

    height: ${SIZE_ICON_DATA[iconSize]}px;
    width: ${SIZE_ICON_DATA[iconSize]}px;
  `}
`;
