import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = ({
  payload,
  type,
}: {
  payload: FORM_VALUE_INTER;
  type: 'test' | 'all';
}) => {
  return HttpRequest({
    method: API.MAIN.TYPE,
    url: API.MAIN.URL(type),
    data: payload,
  });
};
