import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { FAQProps, question } from './constant';

// import activeIcon from './assets/svg/landing/arrow.svg';

export const Component: React.FC<FAQProps> = ({
  activeQuestion,
  toggleQuestion,
  activeIcon,
}) => {
  return (
    <BodyContainer>
      <AboutContainer>
        <AboutBlock>
          <AboutText>
            <AboutHeadTitle>
              <HeadTitle>
                <Icon src="./assets/svg/file.svg" alt="file" />

                <HeadTitlePrimary>
                  {i18n.t('LANDING.HOME_FAQ.TITLE')}
                </HeadTitlePrimary>

                {/* <HeadTitleHighlight>
                  {i18n.t('LANDING.HOME_FAQ.SUBTITLE')}
                </HeadTitleHighlight> */}
              </HeadTitle>
            </AboutHeadTitle>

            <QuestionBlock>
              {question.map((q, index) => {
                return (
                  <QuestionChildBlock
                    key={index}
                    onClick={() => toggleQuestion(index)}
                  >
                    <QuestionTextGroup>
                      <QuestionTextMain tid={q.tid} />
                      <ArrowImg
                        src={
                          activeQuestion === index
                            ? activeIcon
                            : './assets/svg/faq/arrow.svg'
                        }
                        alt="arrow"
                      />
                    </QuestionTextGroup>
                    {activeQuestion === index && (
                      <QuestionTextSecond isOpen={activeQuestion === index}>
                        {q.subTitleFirst && (
                          <TextElem
                            tid={q.subTitleFirst}
                            type="medium"
                            color="textPrimary"
                          />
                        )}
                        {q.answerFirst && i18n.t(q.answerFirst)}

                        {q.subTitleSecond && (
                          <TextElem
                            tid={q.subTitleSecond}
                            type="medium"
                            color="textPrimary"
                          />
                        )}
                        {q.answerSecond && i18n.t(q.answerSecond)}
                        {q.subTitleThird && (
                          <TextElem
                            tid={q.subTitleThird}
                            type="medium"
                            color="textPrimary"
                          />
                        )}
                        {q.answerThird && i18n.t(q.answerThird)}
                        {q.subTitleFourth && (
                          <TextElem
                            tid={q.subTitleFourth}
                            type="medium"
                            color="textPrimary"
                          />
                        )}
                        {q.answerFourth && i18n.t(q.answerFourth)}

                        {/* {q.answerTid && i18n.t(q.answerTid)} */}
                      </QuestionTextSecond>
                    )}
                  </QuestionChildBlock>
                );
              })}
            </QuestionBlock>
          </AboutText>
        </AboutBlock>
      </AboutContainer>
    </BodyContainer>
  );
};

const BodyContainer = styled(PaddingContainer)`
  padding: 0;
  gap: ${Spacing(2.5)};
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const ArrowImg = styled.img`
  align-self: baseline;
  margin: auto 0;
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: ${Spacing(14)} ${Spacing(30)};
  margin: 0 auto;
  min-height: 530px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};

  @media (max-width: 1350px) {
    padding: ${Spacing(14)} ${Spacing(20)};
  }

  @media (max-width: 768px) {
    padding: ${Spacing(10)} ${Spacing(15)};
  }

  @media (max-width: 480px) {
    padding: ${Spacing(10)} ${Spacing(5)};
  }
`;

const AboutBlock = styled.div`
  display: grid;
  gap: ${Spacing(8)};
  text-align: center;
`;

const AboutText = styled.div`
  display: grid;
  gap: ${Spacing(8)};
`;

const AboutHeadTitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const HeadTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  // display: grid;
  gap: ${Spacing(3)};

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const HeadTitlePrimary = styled.p`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px;
  // font-size: 26px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const QuestionBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(3)};
  align-items: center;
`;

const QuestionTextGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  // gap: ${Spacing(3)};
  justify-content: space-between;
`;

const QuestionChildBlock = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 13px;
  border: 1px solid white;
  flex-direction: column;
  justify-content: space-between;
  gap: ${Spacing(3)};
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};

  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  }

  :active {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
    // background-color: #e7f1ff;
  }
`;

const QuestionTextMain = styled(TextElem)`
  display: flex;
  justify-content: flex-start;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  // line-height: 30px;
  line-height: 18px;
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const QuestionTextSecond = styled(TextElem)<{ isOpen?: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
  // line-height: 160%;
  line-height: 22.4px;
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  white-space: pre-line;
  // max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  /* Максимальна висота */
  // transition: max-height 20s ease; /* Плавний перехід */
`;

const HeadTitleHighlight = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]}px;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
`;
