import { OPTION_INTER } from '../../data/user/constant';
import { FORM_VALUE_INTER } from './constant';

export const convert = (values: FORM_VALUE_INTER) => ({
  ...values,
  requestList: values.requestList.map((e: OPTION_INTER) => e.value),
  languageList: values.languageList.map((e: OPTION_INTER) => e.value),
  methodList: values.methodList.map((e: OPTION_INTER) => e.value),
  lgbtFrendly: values.lgbtFrendly,
  gender: values?.gender?.value,
  timezone: Number(values?.timezone),
  phone: values?.phone === '+380' ? null : values.phone,
});
