import { OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PSYCHO_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: `/user/request`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum FORM_VALUE_ENUM {
  REQUEST_LIST = 'requestList',
  POPULARITY_ORDER = 'popularityOrder',
  METHOD_LIST = 'methodList',
  PSYCHO_CATEGORY = 'psychoCategory',
  TIMEZONE = 'timezone',
  PRICE_ORDER = 'priceOrder',
  EXPERIENCE = 'experience',
  PRACTICE = 'practice',
  PRICE_START = 'priceStart',
  PRICE_END = 'priceEnd',
  SEARCH = 'search',
  DATE_START = 'startDate',
  DATE_END = 'endDate',
  DATE_DEFAULT = 'dateDefault',
  DATE = 'date',
  LANGUAGE_LIST = 'languageList',
}

export enum FILTER_OPTION_NAME {
  REQUEST = 'request',
  METHOD = 'method',
  CATEGORY = 'category',
  TIMEZONE = 'timezone',
  PRICE_ORDER = 'priceOrder',
  EXPERIENCE = 'experience',
  PRACTICE = 'practice',
  PRICE_START = 'priceStart',
  PRICE_END = 'priceEnd',
  SEARCH = 'search',
  DATE_START = 'dateStart',
  DATE_END = 'dateEnd',
  POPULARITY_ORDER = 'popularityOrder',
  LANGUAGE_LIST = 'languageList',
  DATE = 'date',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.REQUEST_LIST]: OPTION_INTER[] | null;
  [FORM_VALUE_ENUM.METHOD_LIST]: OPTION_INTER[] | null;
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.TIMEZONE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.PRICE_ORDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.EXPERIENCE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.PRACTICE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.PRICE_START]: number | null;
  [FORM_VALUE_ENUM.PRICE_END]: number | null;
  [FORM_VALUE_ENUM.SEARCH]: string | null;
  [FORM_VALUE_ENUM.DATE_START]: string | null;
  [FORM_VALUE_ENUM.DATE_END]: string | null;
  [FORM_VALUE_ENUM.POPULARITY_ORDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.LANGUAGE_LIST]: OPTION_INTER[] | null;
  [FORM_VALUE_ENUM.DATE_DEFAULT]: any;
  [FORM_VALUE_ENUM.DATE]: string | any;
}

export enum EXPRERIANCE_ENUM {
  ABOVE = 1,
  LESS = 2,
}

export enum PRACTICE_ENUM {
  ABOVE = 1,
  LESS = 2,
}

export enum PRICE_ORDER_ENUM {
  ABOVE = 1,
  LESS = 2,
}

export enum POPULARITY_ORDER_ENUM {
  ABOVE = 1,
  LESS = 2,
}

export const POPULARITY_ORDER_OPTION_LIST = [
  {
    value: POPULARITY_ORDER_ENUM.ABOVE,
    label: i18n.t('PSYCHO_LIST.FILTER.ABOVE'),
  },
  {
    value: POPULARITY_ORDER_ENUM.LESS,
    label: i18n.t('PSYCHO_LIST.FILTER.LESS'),
  },
];

export const SORT_OPTION_LIST = [
  {
    value: 1,
    label: i18n.t('PSYCHO_LIST.FILTER.ABOVE'),
  },
  {
    value: 2,
    label: i18n.t('PSYCHO_LIST.FILTER.LESS'),
  },
];

export const PRICE_ORDER_OPTION_LIST = [
  {
    value: PRICE_ORDER_ENUM.ABOVE,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.PRICE_ORDER.ABOVE'),
  },
  {
    value: PRICE_ORDER_ENUM.LESS,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.PRICE_ORDER.LESS'),
  },
];

export const PRACTICE_OPTION_LIST = [
  {
    value: PRACTICE_ENUM.ABOVE,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.PRACTICE.ABOVE'),
  },
  {
    value: PRACTICE_ENUM.LESS,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.PRACTICE.LESS'),
  },
];

export const EXPRERIANCE_OPTION_LIST = [
  {
    value: EXPRERIANCE_ENUM.ABOVE,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.EXPRERIANCE.ABOVE'),
  },
  {
    value: EXPRERIANCE_ENUM.LESS,
    label: i18n.t('PSYCHO_LIST.FILTER.SORT.EXPRERIANCE.LESS'),
  },
];

export const EXPRERIANCE_VIEW = {
  [EXPRERIANCE_ENUM.ABOVE]: i18n.t('PSYCHO_LIST.FILTER.SORT.EXPRERIANCE.ABOVE'),
  [EXPRERIANCE_ENUM.LESS]: i18n.t('PSYCHO_LIST.FILTER.SORT.EXPRERIANCE.LESS'),
};

export const PRACTICE_VIEW = {
  [PRACTICE_ENUM.ABOVE]: i18n.t('PSYCHO_LIST.FILTER.SORT.PRACTICE.ABOVE'),
  [PRACTICE_ENUM.LESS]: i18n.t('PSYCHO_LIST.FILTER.SORT.PRACTICE.LESS'),
};

export const PRICE_ORDER_VIEW = {
  [PRICE_ORDER_ENUM.ABOVE]: i18n.t('PSYCHO_LIST.FILTER.SORT.PRICE_ORDER.ABOVE'),
  [PRICE_ORDER_ENUM.LESS]: i18n.t('PSYCHO_LIST.FILTER.SORT.PRICE_ORDER.LESS'),
};
