import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  PROPS_TYPE,
} from './constant';
import { Component } from './component';

import { validation } from '../../lib/validation';
import { FormikValues, useFormik } from 'formik';
import { required } from '../../lib/validation/service';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { createManualAction, updateAction } from './action';
import { CONSULTATION_ITEM_MODULE_NAME } from '../consultation-item';
import { CONSULTATION_STATUS } from '../../data/consultation/constant';
import { useFetchTime } from '../../lib/util/useFetchTime';
import { useFetchDay } from '../../lib/util/useFetchDay';

export const Container: React.FC<PROPS_TYPE> = ({
  clientTimezone = 0,
  psychologistId,
  consultationId,
  currentDate,
  isPaid,
  status,
  duration,
}) => {
  const queryClient = useQueryClient();

  const isChangeTimeDisabled = false;

  const onSuccess = () => {
    queryClient.invalidateQueries(CONSULTATION_ITEM_MODULE_NAME);
  };

  const action = useMutation(updateAction, { onSuccess });
  const manualAction = useMutation(createManualAction, { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DATE]: '',
    [FORM_VALUE_ENUM.TIME]: '',
  };

  const handleCreateManualPayment = () => {
    manualAction.mutate({ consultationId });
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER | any) => {
      return action.mutate({
        consultationId,
        payload: {
          ...values,
          date: values.time,
        },
      });
    },
  });

  const {
    timeOption,
    isLoading: isTimeLoading,
    isError: isTimeError,
  } = useFetchTime({
    selectedDate: formik.values[FORM_VALUE_ENUM.DATE],
    psychologistId: psychologistId || '',
    clientTimezone,
    duration,
  });

  const {
    dayArr,
    isLoading: isDayLoading,
    isError: isDayError,
    isSuccess: isDaySuccess,
    refetch,
  } = useFetchDay({
    psychologistId: psychologistId || '',
    duration: duration,
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (status === CONSULTATION_STATUS.CANCEL) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (isTimeLoading) {
      return true;
    }

    if (isDayLoading) {
      return true;
    }
  };

  const isFieldDisabled = () => {
    if (status === CONSULTATION_STATUS.CANCEL) {
      return true;
    }

    if (isTimeLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }

    if (manualAction.isError && !manualAction.isLoading && getErrorMessage()) {
      return true;
    }

    if (isTimeError && !isTimeLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const manualError: ACTION_ERROR_INTER =
      manualAction.error as ACTION_ERROR_INTER;

    if (manualError) {
      return manualError.message;
    }

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    if (name === FORM_VALUE_ENUM.DATE) {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, '');
    }

    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      dayArr={dayArr}
      isPaid={isPaid}
      handleCreateManualPayment={handleCreateManualPayment}
      isChangeTimeDisabled={isChangeTimeDisabled}
      isLoading={action.isLoading || manualAction.isLoading}
      isSuccess={action.isSuccess || manualAction.isSuccess}
      currentDate={currentDate}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isTimeLoading={isTimeLoading}
      timeOption={timeOption}
      timezone={clientTimezone}
      setValue={setValue}
      setFieldValue={setFieldValue}
      formik={formik}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      isFieldDisabled={isFieldDisabled}
    />
  );
};
