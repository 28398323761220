import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRef } from 'react';

import {
  CHIP_TEXT_USER_VIEW,
  CHIP_USER_VIEW,
  USER_ITEM_DATA_INTER,
} from '../constant';
import { Spacing } from '../../../theme';
import { CardElem } from '../../../common/card';
import { TextElem } from '../../../common/text';
import { UserItemUpdateContainer } from '../../../epic/user-item-update';
import editIcon from '../../../asset/svg/common/edit-2.svg';
import { COLOR_ENUM } from '../../../theme/color';

import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { ChipElem } from '../../../common/chip';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';
import { useWindowSize } from 'usehooks-ts';

interface Props extends USER_ITEM_DATA_INTER {
  updateVisible: string | null;
  setUpdateVisible: Function;
}

const Elem: React.FC<Props> = ({
  id,
  email,
  role,
  password,
  createDate,
  setUpdateVisible,
  name,
  updateVisible,
  lastName,
  ...props
}) => {
  const { width } = useWindowSize();

  const handleUpdateVisible = () => {
    setUpdateVisible(id);
  };

  const handleCloseModal = () => {
    setUpdateVisible(null);
  };

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);

  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible, width]);

  return (
    <Card
      handleClick={handleUpdateVisible}
      height={height}
      updVisible={updateVisible === id}
      // disabled={true}
    >
      {updateVisible === id && (
        <DivStyled ref={updRef}>
          <UpdateWrapper>
            <UserItemUpdateContainer
              data={{ createDate, id, email, name, role, password, ...props }}
              close={handleCloseModal}
            />
          </UpdateWrapper>
        </DivStyled>
      )}
      <EllipsisContainerStyled style={{ paddingRight: `${Spacing(5)}` }}>
        {/* <FlexElem spacing={2}> */}
        {name && lastName && (
          <TextElemStyled
            className="hovering"
            color="textPrimary"
            type="medium"
            size="semiSmall"
            oneLine
          >
            {name} {lastName}
          </TextElemStyled>
        )}
        {email && (
          <>
            {name && lastName && <Dot />}
            <TextElemStyled
              className="hovering"
              tid="USER.ITEM.EMAIL"
              tvalue={{ value: email }}
              type="medium"
              size="semiSmall"
              color="textPrimary"
              oneLine
            />
          </>
        )}
        {/* </FlexElem> */}
      </EllipsisContainerStyled>
      <UpdateContainer>
        <Chip
          className="hovering"
          tid={`USER.ROLE.${role}`}
          txColor={CHIP_TEXT_USER_VIEW[role]}
          bgColor={CHIP_USER_VIEW[role]}
        />
        <EditIconStyled src={editIcon} alt="edit" className="hovering" />
      </UpdateContainer>
    </Card>
  );
};

const EllipsisContainerStyled = styled(EllipsisContainerElem)`
  display: flex;
  gap: ${Spacing(2)};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: ${Spacing(2)};

    span {
    }
  }
`;

const Dot = styled.div`
  margin: auto 0;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 480px) {
    max-width: ${Spacing(67.5)} !important;
  }
  @media screen and (max-width: 460px) {
    max-width: ${Spacing(57.5)} !important;
  }
  @media screen and (max-width: 420px) {
    max-width: ${Spacing(47.5)} !important;
  }
  @media screen and (max-width: 380px) {
    max-width: ${Spacing(37.5)} !important;
  }
  @media screen and (max-width: 340px) {
    max-width: ${Spacing(32.5)} !important;
  }
`;

const UpdateWrapper = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;
  width: 100%;
`;

const UpdateContainer = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: end;
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
  }
`;

const EditIconStyled = styled.img`
  transition: all 0.2s;

  height: 24px;
  width: 24px;
`;

const Card = styled(CardElem)<{ height?: number; updVisible: boolean }>`
  display: grid;
  cursor: pointer;
  grid-column: span 2;
  grid-template-columns: 1fr max-content;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;
  transition: background-color
      ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        cursor: default;

        :hover {
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}

  &:not(:disabled) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);

      .hovering {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: ${Spacing(3.75)};
  }
`;

const DivStyled = styled.div`
  width: calc(100% + 2px);
  position: absolute;
  z-index: 999;
  top: -1px;
  left: -1px;
`;

export { Elem as UserItemElem };
