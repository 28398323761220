import React from 'react';
import { ButtonBackElem } from '../../common/button-back';
import { TextElem } from '../../common/text';
import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { RouterLinkElem } from '../../common/router-link';
import { THEME_ENUM } from '../../data/theme/constant';
import {
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
  SIZE_LAYOUT_TYPE,
} from '../../theme/size';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { DesktopHeaderContainer } from '../desktop-header';
import ReactDOM from 'react-dom';

export const Component: React.FC<{
  path?: string | boolean;
  title?: string;
  icon?: string;
  theme?: THEME_ENUM;
  iconLight?: string;
  iconDark?: string;
  button?: any;
  tvalue?: object | undefined;
  className?: string;
  light: boolean;
  background?: COLOR_TYPE;
  size: SIZE_LAYOUT_TYPE;
}> = ({
  path,
  title,
  icon,
  theme,
  iconLight,
  iconDark,
  button,
  tvalue,
  className,
  light = false,
  background = 'backgroundPrimary',
  size,
}) => {
  return (
    <>
      {ReactDOM.createPortal(
        <DesktopHeaderContainer
          path={path}
          icon={icon}
          title={title}
          tvalue={tvalue}
          size={size}
        />,
        document.getElementById('layoutApp') || document.body,
      )}
    </>
  );
};
