import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../../theme';
import { CATEGORY_INTER } from '../constant';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

import { ButtonElem } from '../../../common/button';
import arrowSvg from '../../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../../asset/svg/button/arrow-down-expanded.svg';
import { useHistory } from 'react-router';
import { ReadMoreElem } from '../../../common/read-more';
import { PreviewFile } from './preview-file';
import { useWindowWidth } from '../../../lib/util/useWindowWidth';

export const Category: React.FC<{ data?: CATEGORY_INTER }> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded, isReadMore]);

  const href = `/oplata/${data?.id}`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const windowWidth = useWindowWidth();

  const isCertificate = data?.certificate && data?.certificate?.length > 0;
  const isDiploma = data?.diploma && data?.diploma?.length > 0;
  const haveContent =
    !!data?.description || !!isCertificate || !!isDiploma || windowWidth < 721;

  const toggleExpand = () => {
    if (!haveContent) return;

    setIsExpanded(!isExpanded);
  };

  return (
    <Container
      haveContent={haveContent}
      isExpanded={isExpanded}
      height={height}
    >
      <Head onClick={toggleExpand} height={height}>
        <Title
          type="medium"
          color="textPrimary"
          size="main"
          tid={String(data?.category)}
        />
        <PriceContainer>
          <TextContainer>
            <TextElem
              oneLine
              tid="USER.PSYCHO.HEADER.PRICE"
              tvalue={{ value: data?.price }}
              type="bold"
              size="main"
            />
            <TextElem
              oneLine
              type="regular"
              color="textSecondary"
              tid="USER.PSYCHO.HEADER.TIME"
              tvalue={{ value: data?.duration }}
            />
          </TextContainer>
          {!isExpanded && (
            <ButtonDisabled
              type="save"
              tid="Забронювати"
              padding="12px 24px"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
              }}
            />
          )}
          {isExpanded && (
            <ButtonElemStyled
              onClick={onClick}
              padding="12px 24px"
              color="default"
              tid="Забронювати"
            />
          )}

          {haveContent && (
            <ArrowImg
              className="arrow"
              isExpanded={isExpanded}
              src={isExpanded ? arrowExpandedSvg : arrowSvg}
              alt="arrow"
            />
          )}
        </PriceContainer>
      </Head>
      <ContentWrapper isExpanded={isExpanded} ref={contentRef} height={height}>
        <TextContainerMobile>
          <TextElem
            oneLine
            tid="USER.PSYCHO.HEADER.PRICE"
            tvalue={{ value: data?.price }}
            type="bold"
            size="main"
          />
          <TextElem
            oneLine
            type="regular"
            color="textSecondary"
            tid="USER.PSYCHO.HEADER.TIME"
            tvalue={{ value: data?.duration }}
          />
        </TextContainerMobile>
        <ButtonElemMobile
          padding="15px 24px"
          onClick={onClick}
          sizeText="semiSmall"
          type="submit"
          tid="Забронювати"
        />
        <Content>
          {data?.video?.url && (
            <VideoPreviewContainer onClick={handleContentClick}>
              <VideoStyled controls>
                <source src={data?.video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </VideoStyled>
            </VideoPreviewContainer>
          )}

          <Info>
            {data?.description && (
              <ReadMoreElem
                onToggle={() => setIsReadMore((prev) => !prev)}
                textLimit={125}
                text={data.description}
              />
            )}
            {(isCertificate || isDiploma) && (
              <FileWrapper>
                {isCertificate && (
                  <FileContainer>
                    <FileTitle
                      onClick={handleContentClick}
                      type="medium"
                      color="textPrimary"
                      size="main"
                      tid="USER.PSYCHO.PROFILE.CERTIFICATE"
                    />
                    <FileList>
                      {data?.certificate.map((e) => (
                        <PreviewFile {...e} />
                      ))}
                    </FileList>
                  </FileContainer>
                )}
                {isDiploma && (
                  <FileContainer>
                    <FileTitle
                      onClick={handleContentClick}
                      type="medium"
                      color="textPrimary"
                      size="main"
                      tid="USER.PSYCHO.PROFILE.DIPLOMA"
                    />
                    <FileList>
                      {data?.diploma.map((e) => (
                        <PreviewFile {...e} />
                      ))}
                    </FileList>
                  </FileContainer>
                )}
              </FileWrapper>
            )}
          </Info>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: 15px;
  }
`;

const FileTitle = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: 14px;
  }
`;

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ButtonElemMobile = styled(ButtonElem)`
  display: none;

  @media screen and (max-width: 720px) {
    display: flex;
    margin-bottom: ${Spacing(4)};
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px !important;
  width: 147px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;

const ButtonDisabled = styled(ButtonElem)`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px !important;
  width: 147px;

  span {
    font-size: 13px !important;
    line-height: 1em !important;
  }
`;

const Head = styled.div<{ height: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ height }) =>
    height == 0 ? `${Spacing(6)}` : `${Spacing(6)} ${Spacing(6)} 0`};
  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: ${({ height }) =>
      height == 0 ? `${Spacing(5)}` : `${Spacing(5)} ${Spacing(5)} 0`};
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const TextContainerMobile = styled(TextContainer)`
  display: none;

  @media screen and (max-width: 720px) {
    margin-bottom: ${Spacing(3)};
    display: flex;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 720px) {
    :not(.arrow) {
      span,
      button {
        display: none;
      }
    }
  }
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-width: 250px;
  max-height: 480px;

  @media screen and (max-width: 720px) {
    height: ${Spacing(50)};
  }
`;

const VideoPreviewContainer = styled.div`
  max-width: 341px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px;

  @media screen and (max-width: 720px) {
    height: max-content;
    max-width: unset;
  }

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FileList = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-wrap: wrap;

  @media screen and (max-width: 1285px) {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  width: 100%;

  @media screen and (max-width: 720px) {
    gap: ${Spacing(4)};
  }
`;

const Content = styled.div`
  display: flex;
  gap: ${Spacing(6)};

  @media screen and (max-width: 720px) {
    flex-direction: column;
    gap: ${Spacing(4)};
  }
`;

const ContentWrapper = styled.div<{ height: number; isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
  // display: none;
  // height: ${(props) => props.height}px;
  height: ${(props) => (props.height !== 0 ? 'max-content' : `0`)};
  // height: max-content;
  overflow: hidden;
  transition: height 0.25s ease;
  position: relative;

  padding: 0 ${Spacing(6)} ${Spacing(6)};
  padding-bottom: ${({ height }) => (height !== 0 ? `${Spacing(6)}` : '0')};

  @media screen and (max-width: 720px) {
    padding: ${({ height }) => height !== 0 && `0 ${Spacing(5)} ${Spacing(5)}`};
  }
`;

const Container = styled.div<{
  isExpanded: boolean;
  height: number;
  haveContent: boolean;
}>`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  cursor: ${({ haveContent }) => haveContent && 'pointer'};
  display: flex;
  flex-direction: column;
  gap: ${({ isExpanded, height }) =>
    isExpanded && height !== 0 && `${Spacing(5)}`};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
