import React, { useState } from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import userPhoto from '../../../asset/svg/user/user-photo.svg';

import { ReadMoreElem } from '../../../common/read-more';
import { ReadMoreListElem } from '../../../common/read-more-list';
import { i18n } from '../../../lib/lang';

export const Profile: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  const isRequestList = data?.requestList && data?.requestList.length > 0;
  const isMethodList = data?.methodList && data?.methodList.length > 0;

  return (
    <ProfileContainer>
      <MainContainer>
        <NameContainer>
          <ImgContainer>
            <ImgStyled src={data?.photo?.url ?? userPhoto} alt="photo" />
          </ImgContainer>
          <NameInfo>
            <TextElemName
              type="medium"
              color="textPrimary"
              size="main"
              // tid={data?.name ?? 'COMMON.NO_NAME'}
            >
              {i18n.t(data?.name ?? 'COMMON.NO_NAME')}{' '}
              {data?.lastName && (
                <TextElemName
                  type="medium"
                  color="textPrimary"
                  size="main"
                  tid={data?.lastName}
                />
              )}
            </TextElemName>
            <TextElemInfo
              type="regular"
              color="textSecondary"
              size="semiSmall"
              tid="USER.PSYCHO.PROFILE.NAME"
              tvalue={{
                age: data?.age && `${data?.age}`,
                gender: data?.gender && `• ${data?.gender}`,
                timezone: data?.timezone && `• ${data?.timezone}`,
                lgbtFrendly: data?.lgbtFrendly ? '• LGBT+' : '',
              }}
            />
          </NameInfo>
        </NameContainer>
        <VideoContainer>
          <TextElemName
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.VIDEO"
          />
          <VideoPreviewContainer>
            {data?.video?.url && (
              <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
                <source src={data?.video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </VideoStyled>
            )}
            {!data?.video?.url && (
              <TextElemName
                type="medium"
                color="textPrimary"
                size="main"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </VideoPreviewContainer>
        </VideoContainer>
      </MainContainer>
      <InfoWrapper>
        <InfoContainer>
          <TextElemName
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.METHOD"
          />
          {isMethodList && <ReadMoreListElem list={data?.methodList} />}
          {data?.methodCustom && (
            <ReadMoreElem textLimit={165} text={data?.methodCustom || ''} />
          )}

          {!isMethodList && !data?.methodCustom && (
            <TextElemInfo
              type="regular"
              color="textSecondary"
              size="input"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </InfoContainer>
        <InfoContainer>
          <TextElemName
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.REQUEST"
          />
          {isRequestList && <ReadMoreListElem list={data?.requestList} />}
          {data?.requestCustom && (
            <ReadMoreElem textLimit={165} text={data?.requestCustom || ''} />
          )}

          {!isRequestList && !data?.requestCustom && (
            <TextElemInfo
              type="regular"
              color="textSecondary"
              size="input"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </InfoContainer>
      </InfoWrapper>
    </ProfileContainer>
  );
};

const TextElemName = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const TextElemInfo = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(3)};
  height: 100%;
`;

const VideoContainer = styled.div`
  min-height: ${Spacing(85)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;

  position: sticky;
  top: ${Spacing(4)};

  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 1180px) {
    max-width: unset;
    padding: ${Spacing(5)};
  }
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-height: ${Spacing(65)};
`;

const VideoPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NameInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const ImgContainer = styled.div`
  min-width: 72px;
  min-height: 72px;

  @media screen and (max-width: 1160px) {
    min-width: 56px;
    min-height: 56px;
  }
`;

const ImgStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  width: 72px;
  height: 72px;
  object-fit: cover;

  @media screen and (max-width: 1160px) {
    width: 56px;
    height: 56px;
  }
`;

const InfoContainer = styled.div`
  height: max-content;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(3)};
    padding: ${Spacing(5)};
  }
`;

const NameContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(4)} ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  align-items: center;

  @media screen and (max-width: 1180px) {
    max-width: unset;
    padding: ${Spacing(3)};
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  height: 100%;
`;

const ProfileContainer = styled.div`
  display: grid;
  gap: ${Spacing(4)};
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr;
  }
`;
