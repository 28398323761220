import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { useSelector } from '../../lib/store';
import { STORE_INTER } from '../../data/auth/constant';
import { action as fetch, getAction } from './action';
import {
  ACTION_ERROR_INTER,
  DATA_INTER,
  DATA_RAW_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { convert } from './convert';
import { validation } from '../../lib/validation';
import { FormikValues, useFormik } from 'formik';
import { required } from '../../lib/validation/service';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { useFetchTime } from '../../lib/util/useFetchTime';
import { useFetchDay } from '../../lib/util/useFetchDay';
import { AUTH_LOGIN_ORDER_LOCAL_STORE } from '../order-auth-signup/constant';
import { getQuery } from '../../lib/util/getQuery';
import { CONSULTATION_PACK_PRICE_CONFIG } from '../../data/consultation/constant';
import { addParamToUrl } from '../../lib/util/urlParamUtil';

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeId, setActiveId] = useState(id);
  const [file, setFile] = useState<File | null>(null);

  const isPack = !!getQuery('isPack');
  const timezoneQuery = getQuery('timezone');
  let selectedDate = getQuery('date') as string;

  const history = useHistory();

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const offsetInMinute = new Date().getTimezoneOffset();
  const offsetInHour = -offsetInMinute / 60;
  const defaultTimeZone =
    timezoneQuery.length > 0 ? Number(timezoneQuery) : offsetInHour;

  const timezone = auth.user ? auth.user.timezone : defaultTimeZone;

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(activeId || id));

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as DATA_RAW_INTER;
    if (data) {
      return convert(data);
    }
  };

  const data = getData();

  const onOpenSpChat = () => {
    const widgetButton = document.querySelector(
      '.open-widget',
    ) as HTMLButtonElement;
    if (widgetButton) {
      widgetButton.click();
    }
  };

  const onSuccess = (data: any) => {
    if (window?.dataLayer) {
      window.dataLayer.push({ event: 'consultation_success' });
    }

    history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id));

    onOpenSpChat();
  };

  const action = useMutation(fetch, { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.CATEGORY]: [required],
    [FORM_VALUE_ENUM.COMMENT]: [],
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [required],
    [FORM_VALUE_ENUM.CONSULTATION_COUNT]: isPack ? [required] : [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: data?.category || '',
    [FORM_VALUE_ENUM.COMMENT]: '',
    [FORM_VALUE_ENUM.DATE]: selectedDate,
    [FORM_VALUE_ENUM.TIME]: '',
    [FORM_VALUE_ENUM.CONSULTATION_COUNT]: isPack ? 4 : null,
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: () => {
      const formData = new FormData();

      formData.append(
        FORM_VALUE_ENUM.CATEGORY,
        formik.values[FORM_VALUE_ENUM.CATEGORY],
      );

      formData.append(
        FORM_VALUE_ENUM.DATE,
        formik.values[FORM_VALUE_ENUM.DATE],
      );

      formData.append(
        FORM_VALUE_ENUM.TIME,
        formik.values[FORM_VALUE_ENUM.TIME],
      );

      if (formik.values[FORM_VALUE_ENUM.COMMENT]) {
        formData.append(
          FORM_VALUE_ENUM.COMMENT,
          formik.values[FORM_VALUE_ENUM.COMMENT],
        );
      }

      if (formik.values[FORM_VALUE_ENUM.CONSULTATION_COUNT]) {
        formData.append(
          FORM_VALUE_ENUM.CONSULTATION_COUNT,
          formik.values[FORM_VALUE_ENUM.CONSULTATION_COUNT],
        );
      }

      if (isPack) {
        formData.append('isPack', 'true');
      }

      if (!isAuth) {
        formData.append('isUnlogged', 'true');
      }

      if (data?.duration) {
        formData.append('duration', String(data.duration));
      }

      if (data?.user?.id) {
        formData.append('psychologist', data.user.id);
      }

      if (data?.price) {
        formData.append('price', String(data?.price));
      }

      if (file) {
        formData.append('file', file);
      }

      return action.mutate({ payload: formData, isAuth: !!isAuth });
    },
  });

  const onDeleteFile = () => {
    setFile(null);
  };

  const handleDateChange = (e: any) => {
    if (e instanceof Date) {
      const formattedDate = e.toLocaleDateString('en-CA');

      formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, null);

      addParamToUrl(FORM_VALUE_ENUM.DATE, '', history);
    } else {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, null);
      formik.setFieldValue(FORM_VALUE_ENUM.DATE, null);
    }
  };

  const {
    timeOption,
    isLoading: isTimeLoading,
    isError: isTimeError,
  } = useFetchTime({
    selectedDate: formik.values[FORM_VALUE_ENUM.DATE],
    psychologistId: data?.user.id || '',
    clientTimezone: timezone,
    duration: data?.duration,
  });

  const {
    dayArr,
    isLoading: isDayLoading,
    isError: isDayError,
    isSuccess: isDaySuccess,
    refetch,
  } = useFetchDay({
    psychologistId: data?.user.id || '',
    duration: data?.duration,
  });

  useEffect(() => {
    if (activeId) {
      preFetch.refetch().then(() => {
        formik.resetForm();
        refetch();
      });
    }
  }, [activeId]);

  const authLoginOrderPath = localStorage.getItem(AUTH_LOGIN_ORDER_LOCAL_STORE);

  useEffect(() => {
    if (state.logged && authLoginOrderPath) {
      localStorage.removeItem(AUTH_LOGIN_ORDER_LOCAL_STORE);
    }
  }, []);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (isDayLoading) {
      return true;
    }

    if (!formik.values[FORM_VALUE_ENUM.DATE]) {
      return true;
    }
    if (!formik.values[FORM_VALUE_ENUM.TIME]) {
      return true;
    }
  };

  const isFieldDisabled = () => {
    if (isTimeLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (isTimeError && !isTimeLoading && getErrorMessage()) {
      return true;
    }

    if (isDayError) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    if (name === FORM_VALUE_ENUM.CATEGORY) {
      setActiveId(e.id);
    }

    if (name === FORM_VALUE_ENUM.DATE) {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, '');
    }

    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const consultationCountValue = getFieldValue(
    FORM_VALUE_ENUM.CONSULTATION_COUNT,
  ) as number;

  const consultationPackDiscount =
    CONSULTATION_PACK_PRICE_CONFIG[String(consultationCountValue)] ||
    CONSULTATION_PACK_PRICE_CONFIG['2'];

  const consultationPackPrice = Math.round(
    (data?.price || 0) *
      consultationCountValue *
      (1 - consultationPackDiscount / 100),
  );

  return (
    <Component
      file={file}
      handleDateChange={handleDateChange}
      consultationPackDiscount={consultationPackDiscount}
      consultationPackPrice={consultationPackPrice}
      isPack={isPack}
      setFile={setFile}
      onDeleteFile={onDeleteFile}
      availableDate={dayArr}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isTimeLoading={isTimeLoading}
      timeOption={timeOption}
      setValue={setValue}
      setFieldValue={setFieldValue}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess && isDaySuccess}
      data={data}
      formik={formik}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      isFieldDisabled={isFieldDisabled}
    />
  );
};
