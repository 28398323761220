import { i18n } from '../../lib/lang';
import { convertTimeWithTimeZone } from '../../lib/lang/service';
import { USER_ROLE } from '../user/constant';
import { MESSAGE_ITEM_DATA, MESSAGE_ITEM_DATA_RAW } from './constant';
import agentIcon from '../../asset/svg/consultation/chat/agent.svg';

const getUserName = (
  message: MESSAGE_ITEM_DATA_RAW,
  userId?: string,
): string => {
  if (message?.user?.id === userId) return i18n.t('CONSULTATION.CHAT.YOU');

  return message?.user?.name;
};

export const convertMessage = (
  message: MESSAGE_ITEM_DATA_RAW,
  userId?: string,
): MESSAGE_ITEM_DATA => {
  return {
    ...message,
    createDate: convertTimeWithTimeZone(message.createDate),
    name: getUserName(message, userId),
    photoList: message?.file?.filter((file) => file.mimetype.includes('image')),
    anyFileList: message?.file?.filter(
      (file) => !file.mimetype.includes('image'),
    ),
    photo: message?.user?.photo?.url,
  };
};

export const convertMessageList = (
  messageList: MESSAGE_ITEM_DATA_RAW[],
  userId?: string,
): MESSAGE_ITEM_DATA[] => {
  return messageList.map((message: MESSAGE_ITEM_DATA_RAW) =>
    convertMessage(message, userId),
  );
};
