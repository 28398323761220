import styled from 'styled-components';
import { useState } from 'react';
import { ModalElem } from '../../common/modal';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { SelectElem } from '../../common/select';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { ButtonElem } from '../../common/button';
import { OPTION_INTER } from '../../data/user/constant';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  isOpen: boolean;
  onClose: Function;
  defaultTimezone: OPTION_INTER;
  changedTimezone: OPTION_INTER;
  handleUpdate: (timezome: number) => void;
  onSaveTimezoneModal: Function;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
}> = ({
  isOpen,
  onClose,
  changedTimezone,
  defaultTimezone,
  handleUpdate,
  onSaveTimezoneModal,
  isLoading,
  errorMessage,
  isError,
}) => {
  const [timezone, setTimezone] = useState(defaultTimezone);

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      <ModalElem onClose={onClose} open={isOpen}>
        <Container>
          <TextElem
            tid="USER.TIMEZONE.INFORMATION_TITLE"
            type="medium"
            size="main"
            color="buttonText"
          />
          <TextElem
            tid="USER.TIMEZONE.INFORMATION_DESCRIPTION"
            type="light"
            size="input"
            color="textSecondary"
          />
        </Container>
        <Grid margin="12px 0 0 0">
          <SelectElem
            title="USER.TIMEZONE.CHANGED_TITLE"
            isDisabled
            placeholder="USER.TIMEZONE.CHANGED"
            value={changedTimezone}
          />
          <SelectElem
            title="USER.TIMEZONE.CURRENT_TITLE"
            options={TIMEZONE_OPTION_LIST}
            placeholder="USER.TIMEZONE.CURRENT_UNSET"
            value={timezone?.value !== null ? timezone : null}
            onChange={(e: any, value: any) => {
              setTimezone(value);
            }}
          />
        </Grid>
        <Grid margin="20px 0 0 0">
          <ButtonElem
            tid="USER.TIMEZONE.CHANGE_BUTTON"
            type="submit"
            onClick={() => {
              onSaveTimezoneModal(changedTimezone.value);
              handleUpdate(changedTimezone.value);
            }}
          />
          <ButtonElem
            disabled={timezone?.value === null}
            tid="USER.TIMEZONE.CANCEL_BUTTON"
            type="button"
            onClick={() => onSaveTimezoneModal(timezone.value)}
          />
        </Grid>
      </ModalElem>
    </>
  );
};

const Grid = styled.div<{ margin?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${Spacing(2)};
  margin: ${({ margin }) => margin};
  width: 100%;

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${Spacing(2)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;
