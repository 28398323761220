import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { useSelector } from 'react-redux';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { ConsultationItemContainer } from '../../epic/consultation-item';
import { ConsultationAdminContainer } from '../../epic/consultation-admin-item';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { HomeFooterContainer } from '../../epic/home-footer';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const isAdmin = state.user?.role === USER_ROLE.ADMIN;
  const isPsycho = state.user?.role === USER_ROLE.PSYCHOLOGIST;
  const isUser = state.user?.role === USER_ROLE.USER;

  const isLogged = state ? state.logged : null;

  return (
    <>
      <PageElem>
        <LayoutAppElem>
          <LayoutStyled
            padding={isLogged ? 9 : 3}
            size={isLogged ? 'default' : 'large'}
          >
            <LayoutSectionStyled>
              {(isUser || !isLogged || isPsycho) && (
                <ConsultationItemContainer role={state.user?.role} />
              )}

              {isAdmin && <ConsultationAdminContainer />}
            </LayoutSectionStyled>
          </LayoutStyled>
          {!isLogged && (
            <MarginBlock>
              <HomeFooterContainer />
            </MarginBlock>
          )}
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};

const LayoutSectionStyled = styled(LayoutSectionElem)``;

const LayoutStyled = styled(LayoutElem)`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const MarginBlock = styled.div`
  margin-top: ${Spacing(6)};
`;
