import { useEffect, useState } from 'react';

const useIntersectionObserver = () => {
    const [activeSection, setActiveSection] = useState<string>('');

    useEffect(() => {
        const sections = document.querySelectorAll('section');

        if (sections.length === 0) {
            console.warn('No sections found to observe.');
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                let closestSection: HTMLElement | null = null;
                let closestOffset = Infinity;

                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.target instanceof HTMLElement) {
                        const rect = entry.target.getBoundingClientRect();
                        const offset = Math.abs(rect.top - window.innerHeight / 2);

                        if (offset < closestOffset) {
                            closestOffset = offset;
                            closestSection = entry.target;
                            setActiveSection(entry.target.id);
                        }
                    }
                });

            },
            {
                root: null, 
                rootMargin: "-50% 0px -50% 0px", 
                threshold: 0, 
            }
        );

        sections.forEach((section) => observer.observe(section));

        return () => {
            sections.forEach((section) => observer.unobserve(section));
        };
    }, []);

    return activeSection;
};

export default useIntersectionObserver;
