import {
  GENDER_ENUM,
  REQUEST_ENUM,
  METHOD_ENUM,
} from '../../data/user/constant';
import { CATEGORY_RAW_INTER } from '../profile-psycho/constant';

export const MODULE_NAME = 'PSYCHO_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface PSYCHO_ITEM_DATA_RAW_INTER {
  name: string;
  lastName?: string;
  aboutMe?: string | null;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  requestList: REQUEST_ENUM[];
  methodList: METHOD_ENUM[];
  lgbtFrendly: boolean;
  timezone: number;
  nickname: string;
  yearExperience: number;
  hourPractice: number;
  methodCustom?: string;
  requestCustom?: string;
  aboutMyPath?: string;
  aboutMyService?: string;
  availableDayArr?: string[];
  category: CATEGORY_RAW_INTER[];
  id: string;
  photo: {
    name: string;
    id: string;
    url: string;
  };
  video: {
    name: string;
    id: string;
    url: string;
  };
}

export interface PSYCHO_ITEM_LIST_DATA_RAW_INTER {
  list: PSYCHO_ITEM_DATA_RAW_INTER[];
  isEmpty: boolean;
}

export interface ACTION_RESPONSE_INTER
  extends PSYCHO_ITEM_LIST_DATA_RAW_INTER {}

export interface PSYCHO_ITEM_DATA_INTER
  extends Omit<PSYCHO_ITEM_DATA_RAW_INTER, 'timezone' | 'requestList'> {
  timezone: string;
  requestList: string[];
  methodListString: string[];
}

export interface DATA_INTER {
  list: PSYCHO_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface filterOption {
  psychoCategory?: string;
  methodList?: string;
  requestList?: string;
  timezone?: string;
  priceOrder?: string;
  experience?: string;
  practice?: string;
  priceStart?: string;
  priceEnd?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  popularityOrder?: string;
  languageList?: string;
  date?: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `user/psycho/list${params}`,
};
