import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';

import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { i18n } from '../../lib/lang';
import { LANDING_ID_ENUM } from '../home-footer/constant';

export const Component: React.FC<{}> = () => {
  return (
    <HeroContainer>
      <HeroBlock>
        <HeroText>
          <HeroTitle color="textPrimary" size="mainHeadTitle">
            {i18n.t('LANDING.HOME_HERO.TITLE')}
            <HeroTitleName
              tid="LANDING.HOME_HERO.TITLE_NAME"
              size="mainHeadTitle"
              color="default"
            />
          </HeroTitle>
          <HeroSubtitle
            tid="LANDING.HOME_HERO.SUBTITLE"
            size="main"
            fontFamily="e-UkraineHead"
          />
        </HeroText>
        <Button
          padding="22px 32px"
          onClick={() => scrollToSection(LANDING_ID_ENUM.SERVICES)}
          type="landing"
          color="white"
          sizeText="label"
          tid="LANDING.HOME_HERO.BUTTON"
        />
      </HeroBlock>
      <HeroImg
        src="./assets/img/landing/family-stands-together-sunset.png"
        alt="doctor"
      />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  padding: ${Spacing(40)} 0px 0px;
  margin: 0 auto;
  // height: 100vh;
  // max-height: ${Spacing(300)};
  max-height: fit-content;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);
  // display: flex;
  // gap: ${Spacing(17.75)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(18.5)};
  justify-items: center;
  align-items: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  @media (max-width: 1200px) {
    // padding: ${Spacing(20)} ${Spacing(15)} 0px;
  }

  @media (max-width: 1400px) {
    grid-template-columns: minmax(540px, 1fr) 1fr;
  }

  @media (max-width: 992px) {
    padding: ${Spacing(40)} ${Spacing(10)} 0;
    max-height: unset;
    height: 100%;
  }

  @media (max-width: 768px) {
    padding: ${Spacing(35)} ${Spacing(4)} ${Spacing(8)};
    grid-template-columns: 1fr;
  }

  @media (max-width: 420px) {
    // height: 100%;
    padding: ${Spacing(35)} ${Spacing(4)} ${Spacing(8)};
  }
`;

const HeroBlock = styled.div`
  max-width: ${Spacing(125)};
  max-height: ${Spacing(150)};
  display: grid;
  gap: ${Spacing(8)};

  @media (max-width: 992px) {
    max-width: 100%;
    max-height: none;
  }
`;

const HeroText = styled.div`
  display: grid;
  min-width: ${Spacing(120)};
  gap: ${Spacing(4)};
  @media (max-width: 768px) {
    min-width: ${Spacing(72)};
  }
`;

const HeroTitle = styled(TextElem)`
  font-family: 'Oks-Free' !important;
  line-height: 1.5em;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  @media (max-width: 768px) {
    // font-size: ${Spacing(7)};
    text-align: center;
  }
`;

const HeroTitleName = styled(HeroTitle)`
  text-decoration: underline;
`;

const HeroSubtitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};

  @media (max-width: 768px) {
    text-align: center;
    font-size: ${Spacing(4.5)};
  }
`;

const HeroImg = styled.img`
  max-width: unset;
  margin: 0 auto;
  // position: relative;

  // transform: translateX(clamp(0vw, 5vw, 50vw); /* Динамічне зміщення */
  // transition: transform 0.3s ease;

  // right: clamp(-100px, -150px, -500px);

  @media (max-width: 992px) {
    max-height: ${Spacing(100)};
    // width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled(ButtonElem)`
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;
