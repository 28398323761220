import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum STATUS_CIRCLE_ENUM {
  CURRENT = 'current',
  DEFAULT = 'default',
  SUCCESS = 'success'
}