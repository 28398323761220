import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  onCancel: Function;
  onSubmit: Function;
  isVisible: boolean;
}> = ({ onCancel, onSubmit, isVisible }) => {
  if (!isVisible) return null;

  return (
    <ModalWrapper>
      <Wrapper>
        <Container>
          <TextElem
            tid="COMMON.COOKIE.INFORMATION_TITLE"
            type="light"
            size="alert"
            lineHeight="1.5em"
            color="textSecondary"
          />
        </Container>
        <Grid>
          <ButtonElemStyled
            padding="10px 30px"
            tid="COMMON.COOKIE.ACCEPT"
            type="submit"
            onClick={onSubmit}
          />
          <ButtonElemStyled
            padding="10px 30px"
            tid="COMMON.COOKIE.CANCEL"
            type="button"
            onClick={onCancel}
          />
        </Grid>
      </Wrapper>
    </ModalWrapper>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  min-width: ${Spacing(32)};

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.ALERT]}px;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  padding: ${Spacing(5)};
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  z-index: 10000;
  pointer-events: none;

  @media screen and (max-width: 720px) {
    justify-content: center;
    align-items: start;
  }
`;

const Wrapper = styled.div`
  pointer-events: auto;

  position: relative;
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
  border-radius: ${Spacing(4)};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};

  @media screen and (max-width: 720px) {
    top: ${Spacing(25)};
  }
`;

const Grid = styled.div<{ margin?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
  margin: ${({ margin }) => margin};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;
