import { CATEGORY_ENUM } from './../../profile-psycho-create-category/constant';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { AUTH_MODULE_NAME } from '../../../data/auth';
import { USER_ITEM_DATA_RAW_INTER } from '../../../data/user/constant';
import { convertPsychoFilter } from '../../../data/user/convert';
import {
  FILTER_OPTION_NAME,
  FORM_VALUE_ENUM,
  MODULE_NAME,
} from '../../psycho-filter/constant';
import {
  PSYCHO_FILTER_FORM_VALUE_INTER,
  psychoFilterAction,
} from '../../psycho-filter';
import { useSelector } from '../../../lib/store';
import { addParamToUrl, getUrlParam } from '../../../lib/util/urlParamUtil';
import { convert, convertToUrl } from '../convert';
import { CATEGORY_VIEW } from '../../profile-psycho/constant';
import { useHistory } from 'react-router';

export const usePsychoFilter = ({
  defaultCategory,
}: {
  defaultCategory: CATEGORY_ENUM;
}) => {
  const history = useHistory();

  const defaultCategoryOption = {
    label: CATEGORY_VIEW[defaultCategory],
    value: defaultCategory,
  };

  const filterParam: Record<string, null | string> = {
    [FILTER_OPTION_NAME.SEARCH]: null,
    // [FILTER_OPTION_NAME.PRICE_START]: null,
    // [FILTER_OPTION_NAME.PRICE_END]: null,
    [FILTER_OPTION_NAME.PRACTICE]: null,
    [FILTER_OPTION_NAME.EXPERIENCE]: null,
    [FILTER_OPTION_NAME.PRICE_ORDER]: null,
    [FILTER_OPTION_NAME.DATE_START]: null,
    [FILTER_OPTION_NAME.DATE_END]: null,
    [FILTER_OPTION_NAME.POPULARITY_ORDER]: null,
    [FILTER_OPTION_NAME.CATEGORY]: null,
    [FILTER_OPTION_NAME.REQUEST]: null,
    [FILTER_OPTION_NAME.METHOD]: null,
    [FILTER_OPTION_NAME.LANGUAGE_LIST]: null,
    [FILTER_OPTION_NAME.DATE]: null,

    // [FILTER_OPTION_NAME.TIMEZONE]: null,
  };

  Object.keys(filterParam).forEach((key) => {
    filterParam[key] = getUrlParam(key);
  });

  const initialValue = convert(filterParam, defaultCategoryOption);

  const [filterInitialOption, setFilterOption] =
    useState<PSYCHO_FILTER_FORM_VALUE_INTER>(initialValue);

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isLogged = () => !!auth.logged;

  const { isSuccess } = useQuery(MODULE_NAME, psychoFilterAction, {
    enabled: isLogged(),
    refetchOnWindowFocus: false,
    onSuccess: (data: USER_ITEM_DATA_RAW_INTER) => {
      const convertData = convertPsychoFilter(data);

      setFilterOption((prev: PSYCHO_FILTER_FORM_VALUE_INTER) => {
        const updatedFilterOption = { ...prev };

        // updatedFilterOption[FORM_VALUE_ENUM.PSYCHO_CATEGORY] =
        //   updatedFilterOption[FORM_VALUE_ENUM.PSYCHO_CATEGORY] ||
        //   convertData[FORM_VALUE_ENUM.PSYCHO_CATEGORY] ||
        //   defaultCategoryOption;

        updatedFilterOption[FORM_VALUE_ENUM.METHOD_LIST] =
          updatedFilterOption[FORM_VALUE_ENUM.METHOD_LIST] ??
          convertData[FORM_VALUE_ENUM.METHOD_LIST];

        updatedFilterOption[FORM_VALUE_ENUM.REQUEST_LIST] =
          updatedFilterOption[FORM_VALUE_ENUM.REQUEST_LIST] ??
          convertData[FORM_VALUE_ENUM.REQUEST_LIST];

        updatedFilterOption[FORM_VALUE_ENUM.LANGUAGE_LIST] =
          updatedFilterOption[FORM_VALUE_ENUM.LANGUAGE_LIST] ??
          convertData[FORM_VALUE_ENUM.LANGUAGE_LIST];

        // const urlValue = convertToUrl(updatedFilterOption);

        // Object.entries(urlValue).forEach(([key, value]) => {
        //   addParamToUrl(key, value, history);
        // });

        return updatedFilterOption;
      });
    },
  });

  const handleUpdateFilter = (
    updatedValue: Partial<PSYCHO_FILTER_FORM_VALUE_INTER>,
  ) => {
    setFilterOption((prev) => {
      const data = {
        ...prev,
        ...updatedValue,
      };

      const urlValue = convertToUrl({ ...data });

      Object.entries(urlValue).forEach(([key, value]) => {
        addParamToUrl(key, value, history);
      });

      return data;
    });
  };

  const clearFilter = () => {
    const resetFilterParam: Record<string, null | string> = {
      [FILTER_OPTION_NAME.SEARCH]: null,
      [FILTER_OPTION_NAME.PRICE_START]: null,
      [FILTER_OPTION_NAME.PRICE_END]: null,
      [FILTER_OPTION_NAME.PRACTICE]: null,
      [FILTER_OPTION_NAME.EXPERIENCE]: null,
      [FILTER_OPTION_NAME.PRICE_ORDER]: null,
      [FILTER_OPTION_NAME.DATE_START]: null,
      [FILTER_OPTION_NAME.DATE_END]: null,
      [FILTER_OPTION_NAME.POPULARITY_ORDER]: null,
      [FILTER_OPTION_NAME.CATEGORY]: null,
      [FILTER_OPTION_NAME.REQUEST]: null,
      [FILTER_OPTION_NAME.METHOD]: null,
      [FILTER_OPTION_NAME.TIMEZONE]: null,
      [FILTER_OPTION_NAME.LANGUAGE_LIST]: null,
      [FILTER_OPTION_NAME.DATE]: null,
    };

    setFilterOption((prev) => {
      const updatedFilterOption = { ...prev };

      Object.keys(resetFilterParam).forEach((key) => {
        updatedFilterOption[key as keyof PSYCHO_FILTER_FORM_VALUE_INTER] =
          resetFilterParam[key];
      });

      Object.entries(resetFilterParam).forEach(([key]) => {
        addParamToUrl(key, null, history);
      });

      return updatedFilterOption;
    });
  };

  return {
    filterInitialOption,
    isSuccess: isSuccess,
    handleUpdateFilter,
    clearFilter,
  };
};
