import React, { useRef } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { TextElem } from '../../common/text';
import { PAYMENT_ITEM_DATA_INTER } from '../../data/payment/constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { Skeleton } from './skeleton';
import ReactDOM from 'react-dom';

import closeIcon from '../../asset/svg/common/close.svg';
import { useOnClickOutside } from 'usehooks-ts';

export const Component: React.FC<{
  data?: PAYMENT_ITEM_DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  visible: boolean;
  onClose: any;
}> = ({
  data,
  isLoading,
  visible,
  onClose,
  isSuccess,
  isError,
  errorMessage,
}) => {
  const ref = useRef(null);
  const handleClickOutside = () => {
    onClose();
  };

  useOnClickOutside(ref, handleClickOutside);
  return (
    <>
      {visible && (
        <>
          {ReactDOM.createPortal(
            <Wrapper>
              <CustomModal ref={ref}>
                <GridElem spacing={5} style={{ overflow: 'hidden' }}>
                  <GridElem style={{ gridTemplateColumns: 'auto 16px' }}>
                    <TextElem
                      tid="PAYMENT.ITEM.TITLE"
                      size="label"
                      type="medium"
                      color="textPrimary"
                    />
                    <CloseIconStyled src={closeIcon} onClick={onClose} />
                  </GridElem>

                  {isLoading && <Skeleton />}
                  {isError && <AlertActionElem text={errorMessage} />}
                  {isSuccess && data && (
                    <>
                      <Container>
                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.ID"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.id}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.PRICE_CARD"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                            tid={`${data?.amount} ${data?.valute}`}
                          />
                        </GridElemStyled>
                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.USER_NAME"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.user?.name}
                          </TextElem>
                        </GridElemStyled>
                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.PSCYHO_NAME"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.psychologist?.name}
                          </TextElem>
                        </GridElemStyled>
                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.USER_EMAIL"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.user?.email}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.PSCYHO_EMAIL"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.psychologist?.email}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.USER_PHONE"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.user?.phone}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.PSYCHO_PHONE"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.psychologist?.phone}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.USER_ID"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.user?.id}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.PSCYHO_ID"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.psychologist?.id}
                          </TextElem>
                        </GridElemStyled>

                        {data?.psychologist?.bankName && (
                          <GridElemStyled spacing={3}>
                            <TextElem
                              tid="PAYMENT.ITEM.PSCYHO_BANK"
                              size="small"
                              type="medium"
                              color="textSecondary"
                            />
                            <TextElem
                              type="regular"
                              color="textPrimary"
                              size="semiSmall"
                            >
                              {data?.psychologist?.bankName}
                            </TextElem>
                          </GridElemStyled>
                        )}

                        {data?.psychologist?.accountNumber && (
                          <GridElemStyled spacing={3}>
                            <TextElem
                              tid="PAYMENT.ITEM.PSCYHO_ACCOUNT_NUMBER"
                              size="small"
                              type="medium"
                              color="textSecondary"
                            />
                            <TextElem
                              type="regular"
                              color="textPrimary"
                              size="semiSmall"
                            >
                              {data?.psychologist?.accountNumber}
                            </TextElem>
                          </GridElemStyled>
                        )}

                        {data?.consultation?.id && (
                          <GridElemStyled spacing={3}>
                            <TextElem
                              tid="PAYMENT.ITEM.CONSULTATION_ID"
                              size="small"
                              type="medium"
                              color="textSecondary"
                            />
                            <TextElem
                              type="regular"
                              color="textPrimary"
                              size="semiSmall"
                            >
                              {data?.consultation?.id}
                            </TextElem>
                          </GridElemStyled>
                        )}

                        {data?.consultation?.category && (
                          <GridElemStyled spacing={3}>
                            <TextElem
                              tid="PAYMENT.ITEM.CONSULTATION_CATEGORY"
                              size="small"
                              type="medium"
                              color="textSecondary"
                            />
                            <TextElem
                              type="regular"
                              color="textPrimary"
                              size="semiSmall"
                            >
                              {data?.categoryView}
                            </TextElem>
                          </GridElemStyled>
                        )}

                        {data?.consultation?.date && (
                          <GridElemStyled spacing={3}>
                            <TextElem
                              tid="PAYMENT.ITEM.CONSULTATION_DATE"
                              size="small"
                              type="medium"
                              color="textSecondary"
                            />
                            <TextElem
                              type="regular"
                              color="textPrimary"
                              size="semiSmall"
                            >
                              {data?.dateView}
                            </TextElem>
                          </GridElemStyled>
                        )}

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.CONSULTATION_IS_FIRST"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.consultation?.isFirst.toString()}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.CONSULTATION_PRICE"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.consultation?.price}
                          </TextElem>
                        </GridElemStyled>

                        <GridElemStyled spacing={3}>
                          <TextElem
                            tid="PAYMENT.ITEM.CONSULTATION_STATUS"
                            size="small"
                            type="medium"
                            color="textSecondary"
                          />
                          <TextElem
                            type="regular"
                            color="textPrimary"
                            size="semiSmall"
                          >
                            {data?.consultation?.status}
                          </TextElem>
                        </GridElemStyled>
                      </Container>
                    </>
                  )}
                </GridElem>
              </CustomModal>
            </Wrapper>,
            document.body,
          )}
        </>
      )}
    </>
  );
};

const CloseIconStyled = styled.img`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const GridElemStyled = styled(GridElem)`
  height: 100%;
`;

const Container = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  max-height: calc(80vh - 100px);
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: '8px';
  }
  &::-webkit-scrollbar-track {
    box-shadow: nset 0 0 6px grey;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: darkBlue;
    border-radius: 15px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightBlue;
    max-height: 10px;
  }
  @media screen and (width<620px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(20, 18, 26, 0.8);
  padding: ${Spacing(5)};

  @media screen and (width<620px) {
    align-items: end;
    border-radius-bottom: 0;
  }
`;

const CustomModal = styled.div`
  display: block;
  max-height: 80vh;
  height: auto;
  padding: ${Spacing(7)};
  width: 100%;
  max-width: 900px;
  @media screen and (width<620px) {
    align-items: end;
    border-radius: 16px 16px 0 0;
  }
  overflow-y: auto;

  border-radius: 16px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
`;
