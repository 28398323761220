import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  FOP_OPTION_LIST,
  FORM_VALUE_ENUM,
  GENDER_OPTION_LIST,
  LANGUAGE_OPTION_LIST,
} from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { ModalElem } from '../../common/modal';
import { ChangeTimezoneModal } from './frame/change-timezone-modal';
import { OPTION_INTER } from '../../data/user/constant';
import { multiValueContainer } from '../../common/select/frame/multi-value';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue?: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isLoadingAction: boolean;
  isSuccessAction: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  isLoadingAction,
  isSuccessAction,
  onChangeSelect,
}) => {
  return (
    <>
      <FormElem gap={7} onSubmit={formik.handleSubmit}>
        {isLoadingAction && <LoaderElem />}
        {isLoading && <SkeletonFormElem />}

        {isSuccess && (
          <ContentContainerElem>
            <GridElem spacing={4}>
              <TextElem
                tid="USER.PSYCHOLOGIST.INFORMATION"
                type="medium"
                size="main"
                color="buttonText"
              />
              <DoubleContainerElem>
                <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.NAME"
                  placeholder="USER.PSYCHOLOGIST.NAME_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />
                <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.LAST_NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.LAST_NAME"
                  placeholder="USER.PSYCHOLOGIST.LAST_NAME_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.LAST_NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.LAST_NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.LAST_NAME)}
                />
              </DoubleContainerElem>
              <DoubleContainerElem>
                <FieldTextElem
                  isDot
                  type="number"
                  name={FORM_VALUE_ENUM.AGE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.AGE"
                  placeholder="USER.PSYCHOLOGIST.AGE_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.AGE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.AGE)}
                  error={isFieldError(FORM_VALUE_ENUM.AGE)}
                />
                <SelectElem
                  placeholder="USER.PSYCHOLOGIST.IS_FOP_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.IS_FOP}
                  onChange={setFieldValue}
                  options={FOP_OPTION_LIST}
                  title="USER.PSYCHOLOGIST.IS_FOP"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.IS_FOP)}
                  error={isFieldError(FORM_VALUE_ENUM.IS_FOP)}
                  value={setValue(FOP_OPTION_LIST, FORM_VALUE_ENUM.IS_FOP)}
                />
              </DoubleContainerElem>

              <DoubleContainerElem>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.BANK_NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.BANK_NAME"
                  placeholder="USER.PSYCHOLOGIST.BANK_NAME_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.BANK_NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.BANK_NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.BANK_NAME)}
                />
                <FieldTextElem
                  name={FORM_VALUE_ENUM.ACCOUNT_NUMBER}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.ACCOUNT_NUMBER"
                  placeholder="USER.PSYCHOLOGIST.ACCOUNT_NUMBER_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.ACCOUNT_NUMBER)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ACCOUNT_NUMBER)}
                  error={isFieldError(FORM_VALUE_ENUM.ACCOUNT_NUMBER)}
                />
              </DoubleContainerElem>
              <DoubleContainerElem>
                <SelectElem
                  isDot
                  placeholder="USER.PSYCHOLOGIST.GENDER_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.GENDER}
                  onChange={setFieldValue}
                  options={GENDER_OPTION_LIST}
                  title="USER.PSYCHOLOGIST.GENDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                  error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                  value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
                />
                <SelectElem
                  placeholder="USER.PSYCHOLOGIST.TIMEZONE_PLACEHOLDER"
                  isDot
                  name={FORM_VALUE_ENUM.TIMEZONE}
                  onChange={setFieldValue}
                  options={TIMEZONE_OPTION_LIST}
                  dynamic
                  title="USER.PSYCHOLOGIST.TIMEZONE"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                  error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                  value={setValue(
                    TIMEZONE_OPTION_LIST,
                    FORM_VALUE_ENUM.TIMEZONE,
                  )}
                />
              </DoubleContainerElem>
              <SelectElem
                placeholder="USER.PSYCHOLOGIST.LANGUAGE_PLACEHOLDER"
                isMulti
                name={FORM_VALUE_ENUM.LANGUAGE_LIST}
                options={LANGUAGE_OPTION_LIST}
                title="USER.PSYCHOLOGIST.LANGUAGE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.LANGUAGE_LIST)}
                error={isFieldError(FORM_VALUE_ENUM.LANGUAGE_LIST)}
                value={getFieldValue(FORM_VALUE_ENUM.LANGUAGE_LIST)}
                onChange={onChangeSelect}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                customComponents={{
                  MultiValueContainer: multiValueContainer,
                }}
              />
              <FieldTextAreaElem
                isDot
                name={FORM_VALUE_ENUM.ABOUT_ME}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.ABOUT_ME)}
                title="USER.PSYCHOLOGIST.ABOUT_ME"
                placeholder="USER.PSYCHOLOGIST.ABOUT_ME_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_ME)}
                error={isFieldError(FORM_VALUE_ENUM.ABOUT_ME)}
              />

              <FieldTextElem
                type="phone"
                name={FORM_VALUE_ENUM.PHONE}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="USER.PSYCHOLOGIST.PHONE"
                placeholder="USER.PSYCHOLOGIST.PHONE_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                error={isFieldError(FORM_VALUE_ENUM.PHONE)}
              />

              <TitleContainer>
                <Dot />
                <TextElem
                  tid="USER.PSYCHOLOGIST.REQUIRED"
                  type="light"
                  size="input"
                  color="textSecondary"
                />
              </TitleContainer>

              <ButtonElem
                disabled={isSubmitDisabled()}
                type="submit"
                tid="CATEGORY.DATA.BUTTON"
              />
              {isError && <AlertActionElem text={errorMessage} />}
              {isSuccessAction && (
                <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
              )}
            </GridElem>
          </ContentContainerElem>
        )}
      </FormElem>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
