

// export enum STEP_LIST {
//   PSYCHO_LIST = 1,
//   PSYCHOLOGIST = 2,
//   ORDER = 3,
// }

export const STEP_LIST = {
    PSYCHO_LIST: 1,
    PSYCHOLOGIST: 2,
    ORDER: 3,
  } as const;

  export type STEP_LIST = typeof STEP_LIST[keyof typeof STEP_LIST];

// export type STEP_LIST_TYPE = `${STEP_LIST}`;



export {};
