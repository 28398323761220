import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { useDispatch, useSelector } from '../../lib/store';
import { ACTION_ERROR_INTER, ACTION_RESPONSE_INTER } from './constant';
import { action as fetch } from './action';
import { setAuthData } from '../../data/auth/action';
import { STORE_INTER } from '../../data/auth/constant';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';

export const Container: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();


  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;

  const redirect = (path: string) => {
    history.push(path);
  };

  const onSuccess = (raw: any) => {
    const data: ACTION_RESPONSE_INTER = raw as unknown as ACTION_RESPONSE_INTER;

    if (!isLogged) {
      dispatch(setAuthData(data.accessToken)).then(() => {
        if (data.consultation) {
          redirect(CONSULTATION_PAGE_PATH_DYNAMIC(data.consultation.id));
        }
      });
    }

    if (isLogged) {
      if (data?.consultation?.user?.id === state.user?.id) {
        redirect(CONSULTATION_PAGE_PATH_DYNAMIC(data.consultation.id));
      } else {
        dispatch(setAuthData(data.accessToken)).then(() => {
          if (data.consultation) {
            redirect(CONSULTATION_PAGE_PATH_DYNAMIC(data.consultation.id));
          }
        });
      }
    }
  };

  const action = useMutation(() => fetch(id), {
    onSuccess,
  });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  useEffect(() => {
    action.mutate();
  }, []);

  return <></>;
};
