import React, { useState } from 'react';

import { Component } from './component';

export const Container: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  React.useEffect(() => {
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setIsVisible(true);
    }
  }, []);

  const onCancel = () => {
    setIsVisible(false);

    window.location.href = 'https://www.google.com';
  };

  const onSubmit = () => {
    localStorage.setItem('cookieConsent', 'true');

    setIsVisible(false);
  };

  return (
    <Component onCancel={onCancel} onSubmit={onSubmit} isVisible={isVisible} />
  );
};
