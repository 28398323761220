import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  DATA_INTER,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { convert } from './convert';
import { PSYCHO_FILTER_FORM_VALUE_INTER } from '../psycho-filter';
import { convertFilterList } from '../psycho-filter/convert';

export const Container: React.FC<{
  filterOption: PSYCHO_FILTER_FORM_VALUE_INTER;
  cardLimit?: number;
  isCardLimit?: boolean;
  isPopularitySort?: boolean;
}> = ({ filterOption, cardLimit, isPopularitySort }) => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const [isMoreRender, setIsMoreRender] = React.useState(false);
  const popularityOrder = isPopularitySort ? { value: 1 } : null;

  console.log('filterOption date', filterOption.date);

  const {
    isLoading,
    isSuccess,
    isRefetching,
    isFetching,
    error: preFetchError,
    isError,
    data: preFetch,
  } = useQuery(
    [MODULE_NAME, filterOption],
    () => action(convertFilterList({ ...filterOption, popularityOrder })),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: Infinity,
    },
  );

  const error = preFetchError as ACTION_ERROR_INTER;

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      return convert(data);
    }
  };

  const data = getData();

  const onClickCardHandler = (id: string) => {
    window.location.hash = id;
  };

  useEffect(() => {
    const anchor = window.location.hash;

    if (anchor) {
      const element = document.getElementById(anchor.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [isLoading, isRefetching, isFetching]);

  const handleMoreRender = () => setIsMoreRender(!isMoreRender);

  const shouldLimit = isSmallScreen || !isMoreRender;

  const filteredList = data?.list;

  const cardArrToRender = filteredList
    ? shouldLimit
      ? filteredList.slice(0, cardLimit)
      : filteredList
    : [];

  const filterArrMobile = filteredList ? filteredList : [];

  const sortedCard =
    filterOption['priceOrder']?.value === 1
      ? [...cardArrToRender].sort(
          (a, b) => (b.category[0]?.price || 0) - (a.category[0]?.price || 0),
        )
      : filterOption['priceOrder']?.value === 2
      ? [...cardArrToRender].sort(
          (a, b) => (a.category[0]?.price || 0) - (b.category[0]?.price || 0),
        )
      : cardArrToRender;

  const cardToRenderMobile =
    filterOption['priceOrder']?.value === 1
      ? [...filterArrMobile].sort(
          (a, b) => (b.category[0]?.price || 0) - (a.category[0]?.price || 0),
        )
      : filterOption['priceOrder']?.value === 2
      ? [...filterArrMobile].sort(
          (a, b) => (a.category[0]?.price || 0) - (b.category[0]?.price || 0),
        )
      : filterArrMobile;

  const listLenght = filteredList?.length || 0;
  const isButtonRender = !isMoreRender && !!cardLimit && listLenght > cardLimit;

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 630);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Component
      cardToRenderMobile={cardToRenderMobile}
      cardArrToRender={sortedCard}
      onClickCardHandler={onClickCardHandler}
      selectedCategory={filterOption?.psychoCategory?.value}
      isLoading={isLoading}
      isError={isError && !isLoading && !!error}
      isSuccess={isSuccess && !isLoading}
      errorMessage={error?.message}
      isButtonRender={isButtonRender}
      handleMoreRender={handleMoreRender}
    />
  );
};
