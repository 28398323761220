import {
  CONSULTATION_RAW_INTER,
  CONSULTATION_STATUS_VIEW,
  CONSULTATION_TYPE_VIEW,
} from '../../data/consultation/constant';
import {
  GENDER_ENUM,
  languageViewObj,
  methodViewObj,
  requestViewObj,
} from '../../data/user/constant';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';

export const convert = (
  rawData: CONSULTATION_RAW_INTER,
  timezone: number,
): any => {
  // на доработку

  const { time, day } = convertDateToLocal(rawData.date, timezone);

  const isMethodList =
    Array.isArray(rawData?.user?.methodList) &&
    rawData?.user?.methodList.length > 0;

  const isRequestList =
    Array.isArray(rawData?.user?.requestList) &&
    rawData?.user?.requestList.length > 0;

  const isLanguageList =
    Array.isArray(rawData?.user?.languageList) &&
    rawData?.user?.languageList.length > 0;

  const isNeedGender =
    typeof rawData?.user?.needGender === 'number' && rawData?.user?.needGender;

  const data = {
    ...rawData,
    timeView: time,
    dayView: day,
    category: CATEGORY_VIEW[rawData?.category],
    typeView:
      CONSULTATION_TYPE_VIEW[rawData.type] || 'CONSULTATION.TYPE.ONE_OFF',
    paymentStatus: rawData?.payment?.isPaid ? 'PAID' : 'NOT_PAID',
    statusView: CONSULTATION_STATUS_VIEW[rawData.status],

    user: {
      ...rawData.user,
      methodList:
        rawData?.user?.methodList && isMethodList
          ? rawData.user.methodList.map((e) => e && methodViewObj[e])
          : [],
      requestList:
        rawData?.user?.requestList && isRequestList
          ? rawData.user.requestList.map((e) => e && requestViewObj[e])
          : [],
      needGender:
        isNeedGender && GENDER_VIEW[rawData.user.needGender as GENDER_ENUM],
      timezone:
        rawData?.user?.timezone &&
        TIMEZONE_VIEW[rawData.user.timezone as TIMEZONE_ENUM],
      numberTimezone: rawData?.user?.timezone && rawData.user.timezone,
      needAge: getYearLabel(rawData?.user?.needAge || 0),
      languageList:
        rawData?.user?.languageList && isLanguageList
          ? rawData.user.languageList.map((e) => e && languageViewObj[e])
          : [],
    },
    psychologist: {
      ...rawData.psychologist,
      gender:
        rawData?.psychologist?.gender &&
        GENDER_VIEW[rawData.psychologist.gender as GENDER_ENUM],
      timezone:
        rawData?.psychologist?.timezone &&
        TIMEZONE_VIEW[rawData.psychologist.timezone as TIMEZONE_ENUM],
      age: getYearLabel(rawData?.psychologist?.age || 0),
    },
  };

  return data;
};
