import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM, USER_TYPE_OPTION_LIST } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import { ContentContainerElem } from '../../common/content-container';
import { OPTION_ITEM_DATA } from '../../common/select/constant';
import { SelectElem } from '../../common/select';
import { CustomUserOption } from '../../common/select/frame/user-custom';
import { multiValueContainer } from '../../common/select/frame/multi-value';
import { FieldTextAreaElem } from '../../common/field-text-area';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { Modal } from 'antd';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  handleSelectUserChange: (newValue: string) => void;
  userList: OPTION_ITEM_DATA[];
  onChangeSelect: (name: string, values: any) => void;
  handleSubmit: (type: 'test' | 'all') => void;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  handleSelectUserChange,
  userList,
  onChangeSelect,
  handleSubmit,
}) => {
  const showConfirm = (type: 'test' | 'all') => {
    Modal.confirm({
      title: 'Ви впевнені?',
      content: 'Цю дію не можна скасувати!',
      okText: 'Так, надіслати',
      okType: 'danger',
      cancelText: 'Скасування',
      centered: true,
      maskClosable: true,
      onOk() {
        handleSubmit(type);
      },
      onCancel() {},
    });
  };

  return (
    <ContentContainerElem>
      <FormElem onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <GridElem spacing={6}>
          <GridElem spacing={4}>
            <FieldTextElem
              isDot
              title="NEWSLETTER.FORM.TITLE"
              placeholder="NEWSLETTER.FORM.TITLE_PLACEHOLDER"
              name={FORM_VALUE_ENUM.TITLE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.TITLE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.TITLE)}
              error={isFieldError(FORM_VALUE_ENUM.TITLE)}
            />
            <FieldTextElem
              isDot
              title="NEWSLETTER.FORM.SUB_TITLE"
              placeholder="NEWSLETTER.FORM.SUB_TITLE_PLACEHOLDER"
              name={FORM_VALUE_ENUM.SUB_TITLE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.SUB_TITLE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SUB_TITLE)}
              error={isFieldError(FORM_VALUE_ENUM.SUB_TITLE)}
            />

            <SelectElem
              isDisabled={
                (!!formik.values[FORM_VALUE_ENUM.USER] &&
                  formik.values[FORM_VALUE_ENUM.USER].length > 0) ||
                isLoading
              }
              title="NEWSLETTER.FORM.USER_TYPE"
              placeholder="NEWSLETTER.FORM.USER_PLACEHOLDER_TYPE"
              isClearable
              name={FORM_VALUE_ENUM.USER_TYPE}
              options={USER_TYPE_OPTION_LIST}
              errorMessage={getFieldError(FORM_VALUE_ENUM.USER_TYPE, formik)}
              error={isFieldError(FORM_VALUE_ENUM.USER_TYPE, formik)}
              value={formik.values[FORM_VALUE_ENUM.USER_TYPE]}
              onChange={(...e: any) => {
                formik.setFieldValue(FORM_VALUE_ENUM.USER, null);
                formik.setFieldValue(...e);
              }}
            />

            <SelectElem
              isDisabled={!!formik.values[FORM_VALUE_ENUM.USER_TYPE]}
              title="NEWSLETTER.FORM.USER"
              placeholder="NEWSLETTER.FORM.USER_PLACEHOLDER"
              isMulti
              dynamic
              isClearable
              filterOption={() => true}
              onInputChange={handleSelectUserChange}
              onChange={onChangeSelect}
              options={userList}
              name={FORM_VALUE_ENUM.USER}
              value={getFieldValue(FORM_VALUE_ENUM.USER)}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{
                Option: CustomUserOption,
                MultiValueContainer: multiValueContainer,
              }}
            />

            <FieldTextAreaElem
              isDot
              name={FORM_VALUE_ENUM.TEXT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.TEXT)}
              title="NEWSLETTER.FORM.TEXT"
              placeholder="NEWSLETTER.FORM.TEXT_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.TEXT)}
              error={isFieldError(FORM_VALUE_ENUM.TEXT)}
            />
          </GridElem>

          <ButtonContainer>
            <ButtonElem
              disabled={isSubmitDisabled()}
              onClick={() => showConfirm('test')}
              type="button"
              tid="NEWSLETTER.FORM.BUTTON.TEST"
            />
            <ButtonElem
              onClick={() => showConfirm('all')}
              disabled={isSubmitDisabled()}
              type="submit"
              tid="NEWSLETTER.FORM.BUTTON.ALL"
            />
          </ButtonContainer>
          {isError && <AlertActionElem text={errorMessage} />}
          {isSuccess && (
            <AlertActionElem type="success" tid="NEWSLETTER.SUCCESS" />
          )}
        </GridElem>
      </FormElem>
    </ContentContainerElem>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-direction: column;
`;
