import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const getAction = (id?: string) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(id),
  });
};

export const action = ({
  payload,
  isAuth,
}: {
  payload: FormData;
  isAuth: boolean;
}) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL(isAuth),
    data: payload,
  });
};
