import React, { useState } from 'react';

import { activeItemConfig, FORM_VALUE_ENUM } from './constant';
import { Component } from './component';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';
import { PSYCHO_FILTER_FORM_VALUE_INTER } from '../psycho-filter';
import { OPTION_INTER } from '../../data/user/constant';

export const Container: React.FC<{
  handleUpdateFilter: (
    updatedValue: Partial<PSYCHO_FILTER_FORM_VALUE_INTER>,
  ) => void;
  progressBarExist?: boolean;
  isOksFreeTitle?: boolean;
  initialCategory: OPTION_INTER;
}> = ({
  handleUpdateFilter,
  progressBarExist,
  isOksFreeTitle,
  initialCategory,
}) => {
  const defaultItem = activeItemConfig.find(
    (e) => Number(e.value) === Number(initialCategory.value),
  );

  const [type, setType] = useState<number | undefined>(defaultItem?.type);
  const [variant, setVariant] = useState<number | undefined>(
    defaultItem?.variant,
  );

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const isLogged = () => !!auth.logged;

  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }
  };

  const handleCardClick = (
    value: number,
    typeValue: 'firstColumn' | 'secondColumn',
  ) => {
    if (typeValue === 'firstColumn') {
      setType(() => {
        if (!variant) {
          setVariant(1);
        }

        const activeItem = activeItemConfig.find(
          (e) => e.variant === variant && e.type === value,
        );

        handleUpdateFilter({
          [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: {
            value: activeItem?.value,
            label: activeItem?.title,
          },
        });

        return value;
      });
    }

    if (typeValue === 'secondColumn') {
      setVariant(() => {
        if (!type) {
          setType(1);
        }

        const activeItem = activeItemConfig.find(
          (e) => e.variant === value && e.type === type,
        );

        handleUpdateFilter({
          [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: {
            value: activeItem?.value,
            label: activeItem?.title,
          },
        });

        return value;
      });
    }

    const isChangeTab =
      typeValue === 'firstColumn' && value === 2 && variant === 3;

    if (isChangeTab) {
      setVariant(1);

      handleUpdateFilter({
        [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: {
          value: CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST,
          label: activeItemConfig[3].title,
        },
      });
    }
  };

  return (
    <Component
      isOksFreeTitle={isOksFreeTitle}
      type={type}
      variant={variant}
      handleCardClick={handleCardClick}
      isLoading={isLoading()}
      progressBarExist={progressBarExist}
    />
  );
};
