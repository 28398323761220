import styled from 'styled-components';
import { useState } from 'react';

import { convertToTimezoneAndGroup } from '../../lib/util/getDayName';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { FlexElem } from '../../common/flex';
import { SelectElem } from '../../common/select';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { DropdownTimezoneIndicator } from '../../common/select/frame/multi-value';
import { AlertActionElem } from '../../common/alert-action';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';

export const Component: React.FC<{
  dateArr?: string[];
  handleClickDate: Function;
  isErrorCategory: boolean;
  errorMessage?: string;
  isError: boolean;
}> = ({ dateArr, handleClickDate, isErrorCategory, errorMessage, isError }) => {
  const offsetInMinuteDefault = new Date().getTimezoneOffset();
  const offsetInHourDefault = -offsetInMinuteDefault / 60;

  const [timeZoneOffset, setTimezoneOffset] = useState(
    TIMEZONE_OPTION_LIST.find((e) => e.value === offsetInHourDefault),
  );

  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isAdmin = state.user?.role === USER_ROLE.ADMIN;

  const arrWeek = convertToTimezoneAndGroup(
    dateArr ?? [],
    timeZoneOffset?.value ?? offsetInHourDefault,
  );

  return (
    <>
      {dateArr && dateArr?.length < 1 && <></>}
      {isError && <AlertActionElem text={errorMessage} />}

      {isErrorCategory && (
        <AlertActionElem tid="PSYCHO_LIST.CATEGORY_NOT_SET" />
      )}

      <Container>
        <FlexElem justifyContent="space-between" alignItems="center">
          <Title
            type="medium"
            size="default"
            lineHeight="1.5em"
            tid="PSYCHO_LIST.SCHEDULE.TITLE"
            color="textPrimary"
            oneLine
          />
          <TimeContainer alignItems="center" justifyContent="end" spacing={0}>
            <Title size="small" tid="PSYCHO_LIST.SCHEDULE.TIME" color="chip" />
            <TitleMobile
              tid="PSYCHO_LIST.SCHEDULE.TITLE"
              color="chip"
              size="small"
            />

            <SelectElemStyled
              isMenuPortalTarget={false}
              name="timezone"
              customComponents={{
                DropdownIndicator: DropdownTimezoneIndicator,
              }}
              placeholder="PSYCHO_LIST.SCHEDULE.TIMEZONE"
              onChange={(e: any, value: any) => {
                setTimezoneOffset(value);
              }}
              value={timeZoneOffset}
              options={TIMEZONE_OPTION_LIST}
            />
          </TimeContainer>
        </FlexElem>
        <Divider />

        <SlotContainer>
          {Object.keys(arrWeek).map((key) => {
            return (
              <DaySlotWrapper key={key}>
                <DayItem>
                  <TextElem color="textPrimary" size="semiSmall" tid={key} />
                </DayItem>
                <SlotItem>
                  {arrWeek[key].map((slot) => (
                    <TextElemStyled
                      as="button"
                      onClick={() =>
                        isAdmin ? '' : handleClickDate(slot.value)
                      }
                      size="small"
                      color="chip"
                      key={slot.value}
                    >
                      {slot.label}
                    </TextElemStyled>
                  ))}
                </SlotItem>
              </DaySlotWrapper>
            );
          })}
        </SlotContainer>
      </Container>
    </>
  );
};

const TimeContainer = styled(FlexElem)`
  @media screen and (max-width: 520px) {
    flex-wrap: wrap;
    gap: ${Spacing(2)};
    justify-content: space-between;
  }
`;

const TitleMobile = styled(TextElem)`
  display: none;

  @media screen and (max-width: 520px) {
    display: block;
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 520px) {
    display: none;
  }
`;

const SelectElemStyled = styled(SelectElem)`
  padding: unset !important;
  border: unset !important;
  max-width: 150px;

  .Select__control.Select__control--menu-is-open {
    background-color: unset !important;
  }

  .Select__menu-list {
    border-radius: ${Spacing(3)};
  }

  .Select__menu {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    border-radius: ${Spacing(3)};
  }

  .Select__option {
    border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }

  .Select__single-value {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px !important;
  }

  .Select__control {
    padding: ${Spacing(1)} ${Spacing(2)} !important;
    border: unset !important;
    min-height: 27px !important;
    max-height: unset !important;
  }

  .Select__placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.REGULAR
    ]} !important;
  }

  .Select__option {
    padding: ${Spacing(3)} ${Spacing(2)} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.REGULAR
    ]} !important;
  }

  .Select__single-value {
    max-width: 110px;
  }

  @media screen and (max-width: 430px) {
    max-width: unset;

    .Select__single-value {
      max-width: unset;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
`;

const TextElemStyled = styled(TextElem)<any>`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;

  color: ${({ theme }) => theme[COLOR_ENUM.CHIP]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
  padding: ${Spacing(2)} ${Spacing(3)};

  cursor: pointer;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 720px) {
    padding: ${Spacing(4)} ${Spacing(6)};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }

  @media screen and (max-width: 394px) {
    padding: ${Spacing(4)} ${Spacing(5)};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }

  @media screen and (max-width: 369px) {
    padding: ${Spacing(4)};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }

  @media screen and (max-width: 347px) {
    padding: ${Spacing(3)};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const DaySlotWrapper = styled.div`
  display: grid;
  grid-template-columns: ${Spacing(20)} 1fr;
  align-items: start;
  gap: ${Spacing(3)};
  justify-content: center;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
  }
`;

const DayItem = styled.div`
  padding-right: ${Spacing(3)};
`;

const SlotItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  padding: ${Spacing(5)};
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;
