import { LANGUAGE_ENUM, OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  PHONE = 'phone',
  NAME = 'name',
  LAST_NAME = 'lastName',
  AGE = 'age',
  GENDER = 'gender',
  ABOUT_ME = 'aboutMe',
  TIMEZONE = 'timezone',
  IS_FOP = 'isFop',
  BANK_NAME = 'bankName',
  ACCOUNT_NUMBER = 'accountNumber',
  LANGUAGE_LIST = 'languageList',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.LAST_NAME]: string;
  [FORM_VALUE_ENUM.AGE]: string;
  [FORM_VALUE_ENUM.GENDER]: string;
  [FORM_VALUE_ENUM.ABOUT_ME]: string;
  [FORM_VALUE_ENUM.TIMEZONE]: string;
  [FORM_VALUE_ENUM.IS_FOP]: boolean;
  [FORM_VALUE_ENUM.BANK_NAME]: string;
  [FORM_VALUE_ENUM.ACCOUNT_NUMBER]: string;
  [FORM_VALUE_ENUM.LANGUAGE_LIST]: OPTION_INTER[];
}

export const FOP_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: true,
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: false,
  },
];

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];

export const LANGUAGE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
    value: LANGUAGE_ENUM.UA,
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.RU'),
    value: LANGUAGE_ENUM.RU,
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
    value: LANGUAGE_ENUM.POL,
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
    value: LANGUAGE_ENUM.ENG,
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.DE'),
    value: LANGUAGE_ENUM.DE,
  },
];

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  TIMEZONE_PATCH: {
    TYPE: 'PATCH',
    URL: 'user/timezone-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-data',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
