import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';

import { getAction } from './action';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_RAW_INTER,
} from '../../data/consultation/constant';
import { convert } from './convert';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(id));

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth?.user?.timezone : null;

  const getData = (): CONSULTATION_DATA_INTER => {
    const data = preFetch.data as unknown as CONSULTATION_RAW_INTER;

    if (data) {
      return convert(data, timezone);
    }

    return {} as CONSULTATION_DATA_INTER;
  };

  const getErrorMessage = () => {
    const error = preFetch.error as ACTION_ERROR_INTER;
    return error?.message;
  };

  return (
    <Component
      isError={preFetch.isError}
      errorMessage={getErrorMessage()}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      data={getData()}
    />
  );
};
