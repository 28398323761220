import React from 'react';
import styled from 'styled-components';

import clipBoardText from '../../asset/svg/menu-app/clipboard-text.svg';
import { firstColumnConfig, secondColumnConfig } from './constant';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import { SIZE_FONT_DATA, SIZE_FONT_ENUM } from '../../theme/size';
import { CardFilter } from './frame/card';

export const Component: React.FC<{
  isLoading?: boolean;
  progressBarExist?: boolean;
  handleCardClick: (
    value: number,
    typeValue: 'firstColumn' | 'secondColumn',
  ) => void;
  type?: number;
  variant?: number;
  isOksFreeTitle?: boolean;
}> = ({ handleCardClick, type, variant, isOksFreeTitle }) => {
  return (
    <>
      <Container>
        <HeaderContainer>
          <img src={clipBoardText} alt="category filter image" />
          <TextElem
            fontFamily={isOksFreeTitle ? 'Oks-Free' : 'e-Ukraine'}
            color="textPrimary"
            type="medium"
            size="medium"
            tid="PSYCHO_LIST.FILTER.HEADER_CARD"
          />
        </HeaderContainer>
        <GridContainer>
          <GridColumnFirst>
            {firstColumnConfig.map((e) => {
              return (
                <CardFilter
                  {...e}
                  isActive={type === e.type}
                  handleClick={() => handleCardClick(e.type, 'firstColumn')}
                />
              );
            })}
          </GridColumnFirst>
          <GridColumnSecond>
            {secondColumnConfig.map((e) => {
              const isDisabled = type === 2 && e.variant === 3;
              return (
                <CardFilter
                  {...e}
                  isDisabled={isDisabled}
                  isActive={variant === e.variant}
                  handleClick={() => handleCardClick(e.variant, 'secondColumn')}
                />
              );
            })}
          </GridColumnSecond>
        </GridContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 2fr;
  grid-gap: ${Spacing(3)};
`;

const GridColumnFirst = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const GridColumnSecond = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1070px) {
    display: none;
  }

  @media screen and (max-width: 1160px) {
    display: flex;

    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
    }
  }
`;
