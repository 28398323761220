import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getUserListShort } from '../../data/user/action';
import {
  USER_ITEM_DATA_INTER,
  USER_ITEM_DATA_RAW_INTER,
  USER_LIST_SHORT_MODULE_NAME,
} from '../../data/user/constant';
import { USER_DATA_INTER } from '../../data/auth/constant';

const useUserOptionList = (initialSearch = '', debounceDelay = 500) => {
  const [searchValue, setSearchValue] = useState(initialSearch);
  const [debouncedSearchValue, setDebouncedSearchValue] =
    useState(initialSearch);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [searchValue, debounceDelay]);

  const { data, refetch, isFetching } = useQuery(
    [USER_LIST_SHORT_MODULE_NAME, debouncedSearchValue],
    () => getUserListShort({ search: debouncedSearchValue }),
    { enabled: true },
  );

  useEffect(() => {
    if (debouncedSearchValue) {
      refetch();
    }
  }, [debouncedSearchValue, refetch]);

  const userList = useMemo(() => {
    if (!data || data.list.length < 1) return [];
    return data.list.map((user: USER_ITEM_DATA_RAW_INTER) => ({
      label: `${user.name} ${user.lastName || ''}`,
      role: user.role,
      email: user.email,
      value: user.id,
    }));
  }, [data]);

  return { userList, isFetching, setSearchValue };
};

export default useUserOptionList;
