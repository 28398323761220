import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import telegramSvg from '../../asset/svg/common/telegram.svg';

export const Component: React.FC<{
  onCancel: Function;
  onSubmit: Function;
  isVisible: boolean;
  link: string;
}> = ({ onCancel, onSubmit, isVisible, link }) => {
  const onLinkClick = () => {
    window.open(link);
    onCancel();
  };

  return (
    <ModalWrapper>
      <Wrapper>
        <Circle
          isVisibleCircle={!isVisible}
          className="open-widget"
          onClick={() => onSubmit()}
        >
          <ImageStyled src={telegramSvg} alt="telegram" />
        </Circle>
        {isVisible && (
          <Container>
            <CloseContainer onClick={() => onCancel()}>x</CloseContainer>
            <TextElem
              tid="COMMON.WIDGET.TITLE"
              type="light"
              size="default"
              lineHeight="1.5em"
              color="textSecondary"
            />
            <ButtonStyled onClick={onLinkClick}>
              <AStyled
                onClick={(e) => {
                  e.preventDefault();
                }}
                target="_blank"
                href={link}
              >
                <img src={telegramSvg} alt="telegram" />
                <TextElem
                  color="white"
                  size="small"
                  tid="COMMON.WIDGET.BUTTON"
                />
              </AStyled>
            </ButtonStyled>
          </Container>
        )}
      </Wrapper>
    </ModalWrapper>
  );
};

const Circle = styled.button<{ isVisibleCircle: boolean }>`
  display: flex;
  visibility: ${({ isVisibleCircle }) => !isVisibleCircle && 'hidden'};
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  bottom: 100px;
  pointer-events: auto;
  background-color: #0088cc;
`;

const ImageStyled = styled.img`
  width: 64px;
  height: 64px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
`;

const AStyled = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  gap: ${Spacing(2)};
`;

const CloseContainer = styled.button`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  background-color: #f6f6f6 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  top: -15px;
  left: -15px;
  color: #1a1a1a !important;
  opacity: 1;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  }
`;

const ButtonStyled = styled.button`
  border-radius: 6px;
  display: inline-flex;
  text-align: center;
  letter-spacing: 0.1px;
  position: relative;
  text-decoration: none;
  -webkit-appearance: none;
  white-space: nowrap;
  width: max-content;
  overflow: hidden;
  background-color: #0088cc !important;
  color: #fff !important;
  padding: 9px 16px 10px;
  font-size: 14px;
  transition: background-color
    ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    background-color: #2d92c4 !important;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  padding: ${Spacing(5)};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: end;
  justify-content: end;
  z-index: 10000;
  pointer-events: none;
`;

const Wrapper = styled.div`
  padding: ${Spacing(3)};
  /* background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]}; */
  max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
  border-radius: ${Spacing(4)};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  position: relative;
  bottom: 150px;

  @media screen and (max-width: 720px) {
    bottom: ${Spacing(25)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  background: rgb(250, 249, 242);
  border: 1px solid #dddddd;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 35px);
  width: 100%;
  max-width: 360px;
  padding: 18px 22px;
  pointer-events: auto;
  border-radius: 8px;
  position: relative;
`;
