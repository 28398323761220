import { METHOD_ENUM, REQUEST_ENUM } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  REQUEST_LIST = 'requestList',
  REQUEST_CUSTOM = 'requestCustom',
  LGBT_FRENDLY = 'lgbtFrendly',
  METHOD_LIST = 'methodList',
  METHOD_CUSTOM = 'methodCustom',
  ABOUT_MY_PATH = 'aboutMyPath',
  ABOUT_MY_SERVICE = 'aboutMyService',
  URL = 'url',
  YEAR_EXPERIENCE = 'yearExperience',
  HOUR_PRACTICE = 'hourPractice',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.REQUEST_LIST]: string;
  [FORM_VALUE_ENUM.REQUEST_CUSTOM]: string;
  [FORM_VALUE_ENUM.LGBT_FRENDLY]: string;
  [FORM_VALUE_ENUM.METHOD_LIST]: string;
  [FORM_VALUE_ENUM.METHOD_CUSTOM]: string;
  [FORM_VALUE_ENUM.ABOUT_MY_SERVICE]: string;
  [FORM_VALUE_ENUM.URL]: string;
  [FORM_VALUE_ENUM.YEAR_EXPERIENCE]: string;
  [FORM_VALUE_ENUM.HOUR_PRACTICE]: string;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];

export const LGBT_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'false',
  },
];

export const METHOD_OPTION_LIST = [
  {
    label: i18n.t(
      'USER.PSYCHOLOGIST.SELECT.METHOD.COGNITIVE_BEHAVIORAL_THERAPY',
    ),
    value: METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.GESTALT_THERAPY'),
    value: METHOD_ENUM.GESTALT_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHOANALYSIS'),
    value: METHOD_ENUM.PSYCHOANALYSIS,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.RELATIONAL_PSYCHOANALYSIS'),
    value: METHOD_ENUM.RELATIONAL_PSYCHOANALYSIS,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.TRANSACTIONAL_ANALYSIS'),
    value: METHOD_ENUM.TRANSACTIONAL_ANALYSIS,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.EXISTENTIAL_ANALYSIS'),
    value: METHOD_ENUM.EXISTENTIAL_ANALYSIS,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHODRAMA'),
    value: METHOD_ENUM.PSYCHODRAMA,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.ART_THERAPY'),
    value: METHOD_ENUM.ART_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.POSITIVE_PSYCHOTHERAPY'),
    value: METHOD_ENUM.POSITIVE_PSYCHOTHERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.EMDR_THERAPY'),
    value: METHOD_ENUM.EMDR_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.TRAUMA_FOCUSED_THERAPY'),
    value: METHOD_ENUM.TRAUMA_FOCUSED_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.BODY_ORIENTED_THERAPY'),
    value: METHOD_ENUM.BODY_ORIENTED_THERAPY,
  },
  {
    label: i18n.t(
      'USER.PSYCHOLOGIST.SELECT.METHOD.CATATHYMIC_IMAGINATIVE_THERAPY',
    ),
    value: METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.FAMILY_THERAPY'),
    value: METHOD_ENUM.FAMILY_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SYSTEMIC_FAMILY_THERAPY'),
    value: METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.CLIENT_CENTERED_THERAPY'),
    value: METHOD_ENUM.CLIENT_CENTERED_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.LOGOTHERAPY'),
    value: METHOD_ENUM.LOGOTHERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.INTEGRATIVE_THERAPY'),
    value: METHOD_ENUM.INTEGRATIVE_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.NARRATIVE_EXPOSURE_THERAPY'),
    value: METHOD_ENUM.NARRATIVE_EXPOSURE_THERAPY,
  },
  {
    label: i18n.t(
      'USER.PSYCHOLOGIST.SELECT.METHOD.DIALECTICAL_BEHAVIOR_THERAPY',
    ),
    value: METHOD_ENUM.DIALECTICAL_BEHAVIOR_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SOLUTION_FOCUSED_THERAPY'),
    value: METHOD_ENUM.SOLUTION_FOCUSED_THERAPY,
  },
  {
    label: i18n.t(
      'USER.PSYCHOLOGIST.SELECT.METHOD.ACCEPTANCE_AND_COMMITMENT_THERAPY',
    ),
    value: METHOD_ENUM.ACCEPTANCE_AND_COMMITMENT_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.MINDFULNESS'),
    value: METHOD_ENUM.MINDFULNESS,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.EMOTION_FOCUSED_THERAPY'),
    value: METHOD_ENUM.EMOTION_FOCUSED_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.NEUROPSYCHOLOGY'),
    value: METHOD_ENUM.NEUROPSYCHOLOGY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SEXOLOGY'),
    value: METHOD_ENUM.SEXOLOGY,
  },
  {
    label: i18n.t(
      'USER.PSYCHOLOGIST.SELECT.METHOD.NEURO_LINGUISTIC_PROGRAMMING',
    ),
    value: METHOD_ENUM.NEURO_LINGUISTIC_PROGRAMMING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.COACHING'),
    value: METHOD_ENUM.COACHING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.CAREER_COUNSELING'),
    value: METHOD_ENUM.CAREER_COUNSELING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.MILITARY_PSYCHOLOGY'),
    value: METHOD_ENUM.MILITARY_PSYCHOLOGY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.ONCOPSYCHOLOGY'),
    value: METHOD_ENUM.ONCOPSYCHOLOGY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PERINATAL_PSYCHOLOGY'),
    value: METHOD_ENUM.PERINATAL_PSYCHOLOGY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.CRISIS_COUNSELING'),
    value: METHOD_ENUM.CRISIS_COUNSELING,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SPEECH_THERAPY'),
    value: METHOD_ENUM.SPEECH_THERAPY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHIATRY'),
    value: METHOD_ENUM.PSYCHIATRY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.CBT'),
    value: METHOD_ENUM.CBT,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.DBT'),
    value: METHOD_ENUM.DBT,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.SCHEMA_THERAPY'),
    value: METHOD_ENUM.SCHEMA_THERAPY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.FAMILY_THERAPY'),
    value: METHOD_ENUM.FAMILY_THERAPY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ART_THERAPY'),
    value: METHOD_ENUM.ART_THERAPY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.POSITIVE_PSYCHOTHERAPY'),
    value: METHOD_ENUM.POSITIVE_PSYCHOTHERAPY,
  },
];

// export const METHOD_OPTION_LIST = [
//   {
//     label: i18n.t(
//       'USER.PSYCHOLOGIST.SELECT.METHOD.COGNITIVE_PROCESSING_THERAPY',
//     ),
//     value: METHOD_ENUM.COGNITIVE_PROCESSING_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.GESTALT_THERAPY'),
//     value: METHOD_ENUM.GESTALT_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHOANALYSIS'),
//     value: METHOD_ENUM.PSYCHOANALYSIS,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.EXISTENTIAL_THERAPY'),
//     value: METHOD_ENUM.EXISTENTIAL_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.MILITARY_PSYCHOLOGY'),
//     value: METHOD_ENUM.MILITARY_PSYCHOLOGY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.ONCOPSYCHOLOGY'),
//     value: METHOD_ENUM.ONCOPSYCHOLOGY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.NEUROPSYCHOLOGY'),
//     value: METHOD_ENUM.NEUROPSYCHOLOGY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.TRANSACTIONAL_ANALYSIS'),
//     value: METHOD_ENUM.TRANSACTIONAL_ANALYSIS,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHODRAMA'),
//     value: METHOD_ENUM.PSYCHODRAMA,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.ART_THERAPY'),
//     value: METHOD_ENUM.ART_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.GESTALT_THERAPY'),
//     value: METHOD_ENUM.GESTALT_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.POSITIVE_PSYCHOTHERAPY'),
//     value: METHOD_ENUM.POSITIVE_PSYCHOTHERAPY,
//   },
//   {
//     label: i18n.t(
//       'USER.PSYCHOLOGIST.SELECT.METHOD.TRAUMA_FOCUSED_PSYCHOTHERAPY',
//     ),
//     value: METHOD_ENUM.TRAUMA_FOCUSED_PSYCHOTHERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.EMDR_THERAPY'),
//     value: METHOD_ENUM.EMDR_THERAPY,
//   },
//   {
//     label: i18n.t(
//       'USER.PSYCHOLOGIST.SELECT.METHOD.CATATHYMIC_IMAGINATIVE_THERAPY',
//     ),
//     value: METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SYSTEMIC_FAMILY_THERAPY'),
//     value: METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.NLP'),
//     value: METHOD_ENUM.NLP,
//   },
//   {
//     label: i18n.t(
//       'USER.PSYCHOLOGIST.SELECT.METHOD.CLIENT_CENTERED_PSYCHOTHERAPY',
//     ),
//     value: METHOD_ENUM.CLIENT_CENTERED_PSYCHOTHERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.INTEGRATIVE_PSYCHOTHERAPY'),
//     value: METHOD_ENUM.INTEGRATIVE_PSYCHOTHERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.CAREER_GUIDANCE'),
//     value: METHOD_ENUM.CAREER_GUIDANCE,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.LOGOTHERAPY'),
//     value: METHOD_ENUM.LOGOTHERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.SOLUTION_FOCUSED_THERAPY'),
//     value: METHOD_ENUM.SOLUTION_FOCUSED_THERAPY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.PSYCHIATRY'),
//     value: METHOD_ENUM.PSYCHIATRY,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.CRISIS_COUNSELING'),
//     value: METHOD_ENUM.CRISIS_COUNSELING,
//   },
//   {
//     label: i18n.t('USER.PSYCHOLOGIST.SELECT.METHOD.FAMILY_PSYCHOTHERAPY'),
//     value: METHOD_ENUM.FAMILY_PSYCHOTHERAPY,
//   },
// ];

export const REQUEST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.FAMILY_CONFLICTS'),
    value: REQUEST_ENUM.FAMILY_CONFLICTS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PARENT_CHILD_ISSUES'),
    value: REQUEST_ENUM.PARENT_CHILD_ISSUES,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.LONELINESS'),
    value: REQUEST_ENUM.LONELINESS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.SELF_IDENTIFICATION_CRISIS'),
    value: REQUEST_ENUM.SELF_IDENTIFICATION_CRISIS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.UNSTABLE_SELF_ESTIMATION'),
    value: REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.LACK_OF_CONFIDENCE'),
    value: REQUEST_ENUM.LACK_OF_CONFIDENCE,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.EMOTIONAL_DIFFICULTIES'),
    value: REQUEST_ENUM.EMOTIONAL_DIFFICULTIES,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.LOSS_OF_A_LOVED_ONE'),
    value: REQUEST_ENUM.LOSS_OF_A_LOVED_ONE,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.DIVORCE'),
    value: REQUEST_ENUM.DIVORCE,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.GRIEF'),
    value: REQUEST_ENUM.GRIEF,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PSYCHODIAGNOSTICS'),
    value: REQUEST_ENUM.PSYCHODIAGNOSTICS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PSYCHOCORRECTION'),
    value: REQUEST_ENUM.PSYCHOCORRECTION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.CAREER_COUNSELING'),
    value: REQUEST_ENUM.CAREER_COUNSELING,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ENURESIS'),
    value: REQUEST_ENUM.ENURESIS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ONCOPSYCHOLOGY'),
    value: REQUEST_ENUM.ONCOPSYCHOLOGY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.DEPRESSION'),
    value: REQUEST_ENUM.DEPRESSION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.POSTPARTUM_DEPRESSION'),
    value: REQUEST_ENUM.POSTPARTUM_DEPRESSION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PANIC_ATTACKS'),
    value: REQUEST_ENUM.PANIC_ATTACKS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ANXIETY'),
    value: REQUEST_ENUM.ANXIETY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PHOBIAS'),
    value: REQUEST_ENUM.PHOBIAS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.OCD'),
    value: REQUEST_ENUM.OCD,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.GAD'),
    value: REQUEST_ENUM.GAD,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.BIPOLAR_DISORDER'),
    value: REQUEST_ENUM.BIPOLAR_DISORDER,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.EATING_DISORDERS'),
    value: REQUEST_ENUM.EATING_DISORDERS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.MRO'),
    value: REQUEST_ENUM.MRO,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ZRO'),
    value: REQUEST_ENUM.ZRO,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ADHD'),
    value: REQUEST_ENUM.ADHD,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.RAS'),
    value: REQUEST_ENUM.RAS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.SELF_HARM'),
    value: REQUEST_ENUM.SELF_HARM,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.NERVOUS_TICS'),
    value: REQUEST_ENUM.NERVOUS_TICS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PSYCHOSOMATICS'),
    value: REQUEST_ENUM.PSYCHOSOMATICS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.OBSESSIVE_STATES'),
    value: REQUEST_ENUM.OBSESSIVE_STATES,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.GAMING_ADDICTION'),
    value: REQUEST_ENUM.GAMING_ADDICTION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.NARCISSISTIC_DISORDER'),
    value: REQUEST_ENUM.NARCISSISTIC_DISORDER,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ALCOHOL_ADDICTION'),
    value: REQUEST_ENUM.ALCOHOL_ADDICTION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.PTSD'),
    value: REQUEST_ENUM.PTSD,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.SCHIZOPHRENIA'),
    value: REQUEST_ENUM.SCHIZOPHRENIA,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.RELEASE_FROM_CAPTIVITY'),
    value: REQUEST_ENUM.RELEASE_FROM_CAPTIVITY,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.SUICIDAL_THOUGHTS'),
    value: REQUEST_ENUM.SUICIDAL_THOUGHTS,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.MILITARY_PSYCHOLOGICAL_ASSISTANCE'),
    value: REQUEST_ENUM.MILITARY_PSYCHOLOGICAL_ASSISTANCE,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.CHILDREN_UNDER_15'),
    value: REQUEST_ENUM.CHILDREN_UNDER_15,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.TEENAGERS_15_17'),
    value: REQUEST_ENUM.TEENAGERS_15_17,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.DRUG_ADDICTION'),
    value: REQUEST_ENUM.DRUG_ADDICTION,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.IRRITABLE_BOWEL_SYNDROME'),
    value: REQUEST_ENUM.IRRITABLE_BOWEL_SYNDROME,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.HYPOCHONDRIA'),
    value: REQUEST_ENUM.HYPOCHONDRIA,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.INSOMNIA'),
    value: REQUEST_ENUM.INSOMNIA,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ZMR'),
    value: REQUEST_ENUM.ZMR,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ZPR'),
    value: REQUEST_ENUM.ZPR,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.INTELLECTUAL_DIFFICULTIES'),
    value: REQUEST_ENUM.INTELLECTUAL_DIFFICULTIES,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.LEARNING_DIFFICULTIES'),
    value: REQUEST_ENUM.LEARNING_DIFFICULTIES,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.WORK_WITH_SYNDROMES'),
    value: REQUEST_ENUM.WORK_WITH_SYNDROMES,
  },
];

// export const REQUEST_OPTION_LIST = [
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.DEPRESSION'),
//     value: REQUEST_ENUM.DEPRESSION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.ANXIETY'),
//     value: REQUEST_ENUM.ANXIETY,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.PHOBIAS'),
//     value: REQUEST_ENUM.PHOBIAS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.FEAR'),
//     value: REQUEST_ENUM.FEAR,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.UNCONTROLLED_ANGER'),
//     value: REQUEST_ENUM.UNCONTROLLED_ANGER,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.GRIEF'),
//     value: REQUEST_ENUM.GRIEF,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.LOSS'),
//     value: REQUEST_ENUM.LOSS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.TRAUMA'),
//     value: REQUEST_ENUM.TRAUMA,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.PTSD'),
//     value: REQUEST_ENUM.PTSD,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.BURNOUT'),
//     value: REQUEST_ENUM.BURNOUT,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.STRESS'),
//     value: REQUEST_ENUM.STRESS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.LOSS_OF_MEANING'),
//     value: REQUEST_ENUM.LOSS_OF_MEANING,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SELF_ESTIMATION'),
//     value: REQUEST_ENUM.SELF_ESTIMATION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.UNCERTAINTY'),
//     value: REQUEST_ENUM.UNCERTAINTY,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.PSYCHOSOMATICS'),
//     value: REQUEST_ENUM.PSYCHOSOMATICS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.ADDICTION'),
//     value: REQUEST_ENUM.ADDICTION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.EATING_DISORDERS'),
//     value: REQUEST_ENUM.EATING_DISORDERS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.PANIC_ATTACKS'),
//     value: REQUEST_ENUM.PANIC_ATTACKS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.INTIMACY_PROBLEMS'),
//     value: REQUEST_ENUM.INTIMACY_PROBLEMS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.PARENTING'),
//     value: REQUEST_ENUM.PARENTING,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.EMOTIONAL_INTELLIGENCE'),
//     value: REQUEST_ENUM.EMOTIONAL_INTELLIGENCE,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SPEECH_THERAPY_SESSIONS'),
//     value: REQUEST_ENUM.SPEECH_THERAPY_SESSIONS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SEXUAL_EDUCATION'),
//     value: REQUEST_ENUM.SEXUAL_EDUCATION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.NEURO_CORRECTION'),
//     value: REQUEST_ENUM.NEURO_CORRECTION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SUPERVISION'),
//     value: REQUEST_ENUM.SUPERVISION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.MENTAL_DISORDERS'),
//     value: REQUEST_ENUM.MENTAL_DISORDERS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.ENURESIS'),
//     value: REQUEST_ENUM.ENURESIS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.ENCOPRESIS'),
//     value: REQUEST_ENUM.ENCOPRESIS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SLEEP_DISORDERS'),
//     value: REQUEST_ENUM.SLEEP_DISORDERS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.AUTOAGGRESSION'),
//     value: REQUEST_ENUM.AUTOAGGRESSION,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.ADHD'),
//     value: REQUEST_ENUM.ADHD,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.SPEECH_DISORDERS'),
//     value: REQUEST_ENUM.SPEECH_DISORDERS,
//   },
//   {
//     label: i18n.t('USER.USER.SELECT.REQUEST.AUTISM'),
//     value: REQUEST_ENUM.AUTISM,
//   },
// ];

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-data',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
