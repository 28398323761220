import React from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { AuthConsultationInviteContainer } from '../../epic/auth-consultation-invite';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size="default">
            <LayoutSectionElem spacing={5}>
              <AuthConsultationInviteContainer />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
