import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';

export const Elem: React.FC<{
  children?: ReactNode;
  href: string;
  opaque?: boolean;
  onClick?: Function;
  className?: string;
}> = ({ children, href, opaque = false, onClick, className }) => {
  const history = useHistory();

  const handleAction = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    if (onClick) {
      onClick();
    }
    setTimeout(() => {
      history.push(href);
    }, 0);
  };

  return (
    <Link
      href={href}
      onClick={handleAction}
      className={`${className} ion-activatable`}
      opaque={opaque}
    >
      {children}
    </Link>
  );
};

const Link = styled.a<{ opaque: boolean }>`
  position: relative;
  cursor: pointer;
  text-decoration: none;

  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
`;
