import { PSYCHO_FILTER_FORM_VALUE_INTER } from '.';
import { OPTION_INTER } from '../../data/user/constant';
import { filterOption } from '../psycho-list/constant';

export const convertFilterList = (
  values: PSYCHO_FILTER_FORM_VALUE_INTER,
): filterOption => {
  return {
    ...values,
    requestList: values?.requestList
      ? values?.requestList?.map((e: OPTION_INTER) => e.value).join(',')
      : undefined,

    methodList: values?.methodList
      ? values?.methodList?.map((e: OPTION_INTER) => e.value).join(',')
      : undefined,

    languageList: values?.languageList
      ? values?.languageList?.map((e: OPTION_INTER) => e.value).join(',')
      : undefined,
    psychoCategory: values?.psychoCategory?.value,
    timezone: values?.timezone?.value,
    priceOrder: values?.priceOrder?.value,
    popularityOrder: values?.popularityOrder?.value,
    experience: values?.experience?.value,
    practice: values?.practice?.value,
    priceStart: values?.priceStart ? String(values?.priceStart) : undefined,
    priceEnd: values?.priceEnd ? String(values?.priceEnd) : undefined,
    search: values?.search ?? undefined,
    startDate: values?.startDate ?? undefined,
    endDate: values?.endDate ?? undefined,
    date: values?.date ?? undefined,
  };
};
