import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerElem } from '../../common/content-container';
import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';

import sendMessage from '../../asset/svg/button/send-message.svg';
import sendMessageError from '../../asset/svg/button/send-message-error.svg';
import alertIcon from '../../asset/svg/consultation/chat/alert.svg';

import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { FlexElem } from '../../common/flex';
import { TextElem } from '../../common/text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { MessageItemElem } from '../../data/message/frame/message-item-list.elem';
import { FilePreviewSliderElem } from '../../common/file-preview-slider';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { DividerElem } from '../../common/divider';
import { FileItemIconUploadContainer } from '../file-item-icon-upload';
import { Skeleton } from 'antd';

export const Component: React.FC<{
  chatMessage: MESSAGE_ITEM_DATA[];
  error: string;
  changeMessage: Function;
  pressEnter: KeyboardEventHandler<HTMLInputElement>;
  newMessage: string;
  sendMessageWrapper: Function;
  setNewMessage: Function;
  isSubmitDisabled: Function;
  userId?: string;
  filePreviewList: {
    id: string;
    name: string;
    url: string;
    mimetype: string;
  }[];
  onSuccessUpload: (
    id: string,
    name: string,
    url: string,
    mimetype: string,
  ) => void;
  handleFileDelete: (id: string) => void;
  isAdmin: boolean;
  disabled: boolean;
  isOver: boolean;
  isNotPaid: boolean;
  isConnected?: boolean;
}> = ({
  chatMessage,
  error,
  changeMessage,
  pressEnter,
  newMessage,
  sendMessageWrapper,
  setNewMessage,
  isSubmitDisabled,
  userId,
  filePreviewList,
  onSuccessUpload,
  handleFileDelete,
  isAdmin,
  disabled,
  isOver,
  isNotPaid,
  isConnected,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessage, chatContainerRef]);

  const getPlaceholder = () => {
    if (disabled) return 'CONSULTATION.CHAT.UNAUTHORIZED';
    if (isOver) return 'CONSULTATION.CHAT.CONSULTATION_OVER';
    if (isNotPaid) return 'CONSULTATION.CHAT.NOT_PAID';
    return '';
  };

  const isPlaceholder = !!getPlaceholder();

  return (
    <Container>
      <>
        <ChatContainer ref={chatContainerRef}>
          {!chatMessage?.length && isConnected && (
            <GridElem
              spacing={3}
              style={{
                alignItems: 'center',
                height: 'min-content',
                placeItems: 'center',
                padding: '32px',
              }}
            >
              <TextElem tid="CONSULTATION.CHAT.EMPTY_1" color="textPrimary" />
              <TextElem
                tid="CONSULTATION.CHAT.EMPTY_2"
                size="small"
                color="textSecondary"
              />
            </GridElem>
          )}
          <GridElem spacing={4}>
            {chatMessage.map((message: MESSAGE_ITEM_DATA) => (
              <MessageItemElem data={message} userId={userId} />
            ))}
            {!isConnected && !disabled && (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </GridElem>
        </ChatContainer>

        <GridElem spacing={3}>
          <FieldWrapper style={{ width: '100%' }}>
            <CustomFieldContainer>
              <FieldText
                onChange={changeMessage}
                value={newMessage}
                onKeyDown={pressEnter}
                // containsOffensiveWords={!!error}
                rows={1}
                autoHeight={true}
                disabled={isOver || disabled}
              >
                <div></div>
              </FieldText>

              <ButtonStyled
                onClick={sendMessageWrapper}
                disabled={isSubmitDisabled()}
                iconSize="large"
                fill="icon"
                color="transparent"
                iconRight={error ? sendMessageError : sendMessage}
              />
              {!isPlaceholder && (
                <FileItemUploadStyled
                  onSuccess={(
                    id: string,
                    name: string,
                    url: string,
                    mimetype: string,
                  ) => onSuccessUpload(id, name, url, mimetype)}
                  resetAfterUpload
                  type="file"
                  variant="icon"
                  maxSize={3145728}
                />
              )}
            </CustomFieldContainer>
            <div>
              {filePreviewList.length > 0 && (
                <GridElem spacing={3} style={{ marginTop: '12px' }}>
                  <DividerElem />
                  <FlexElem style={{ flexWrap: 'wrap' }}>
                    <FilePreviewSliderElem
                      action={handleFileDelete}
                      file={filePreviewList}
                    />
                  </FlexElem>
                </GridElem>
              )}
            </div>
          </FieldWrapper>
          {isPlaceholder && (
            <Alert>
              <FlexElem style={{ width: 'fit-content', alignItems: 'center' }}>
                <Img src={alertIcon} alt="alert" />
                <TextElem tid={getPlaceholder()} color="success" />
              </FlexElem>
            </Alert>
          )}
          {/* {error && (
            <OffensiveWordsAlert spacing={3}>
              <OffensiveWordIcon style={{ minWidth: '18px' }} />
              <TextElem color="error" tid={error} size="semiSmall" />
            </OffensiveWordsAlert>
          )} */}
        </GridElem>
      </>
    </Container>
  );
};

const Img = styled.img`
  min-width: ${Spacing(5)};
`;

const FileItemUploadStyled = styled(FileItemIconUploadContainer)``;

const FieldWrapper = styled.div``;

const FieldText = styled(FieldTextAreaElem)<{
  containsOffensiveWords: boolean;
  reply: boolean;
}>`
  .content {
    /* background: #f2f6fa !important; */

    textarea {
      /* background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]}; */
      &:hover {
        border: none;
      }
      min-height: 22px;
      border: none;
      padding: 0;
      padding-right: 65px;
      border-radius: 0;
      resize: none;
      ::-webkit-scrollbar {
        display: none;
      }
      /* background: #f2f6fa; */
    }

    transition: all 0.2s;
    span {
      transition: all 0.2s;
    }

    position: relative;

    padding: ${Spacing(2.5)} ${Spacing(3)};

    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;

    /* border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]}; */
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

    &:hover {
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

      ::placeholder {
        display: none;
      }
    }

    &:has(textarea:focus) {
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    }
  }

  ${({ containsOffensiveWords, theme, reply }) => {
    if (reply) {
      return css`
        border-radius: 0 0 10px 10px;
        border-top: none;
      `;
    }
    if (containsOffensiveWords) {
      return css`
        border-color: ${theme[COLOR_ENUM.ERROR]};
        &:focus-within {
          border-color: ${theme[COLOR_ENUM.ERROR]} !important;
        }
        &:hover {
          border-color: ${theme[COLOR_ENUM.ERROR]}!important;
        }
      `;
    }
  }}
`;

const Alert = styled(FlexElem)`
  border-radius: 11px;
  padding: 16px;
  justify-content: center;
  background: #a82f2f1a;
  span {
    color: #a82f2f;
  }
`;

const ChatContainer = styled.div`
  padding-top: ${Spacing(4)};
  align-content: start;
  display: grid;
  overflow: scroll;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomFieldContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 47px;

  .file-upload-wrapper {
    position: absolute;
    right: 50px;
    top: 13px;
  }
`;

const ButtonStyled = styled(ButtonElem)`
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer;
  img {
    cursor: pointer;

    height: 26px;
    width: 26px;
  }

  background: transparent !important;
`;

const Container = styled(ContentContainerElem)`
  box-sizing: border-box;
  justify-content: end;

  padding: ${Spacing(4)};
  padding-top: 0;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 100%;
  min-height: calc(100vh - 335px);
  max-height: calc(100vh - 335px);

  @media screen and (max-width: 1160px) {
    min-height: calc(100vh - 360px);
    max-height: calc(100vh - 360px);
  }

  @media screen and (max-height: 680px) {
    min-height: calc(100vh - 275px);
    max-height: calc(100vh - 275px);
  }

  @media screen and (max-height: 500px) {
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
  }
`;
