import { useState } from 'react';
import { PsychoFilterContainer } from '../psycho-filter';
import { PsychoListContainer } from '../psycho-list';
import { usePsychoFilter } from './hook/useFilterOption';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC<{
  isCategoryFilter?: boolean;
  isOksFreeTitle?: boolean;
  isPopularitySort?: boolean;
  cardLimit?: number;
}> = ({ isCategoryFilter, isOksFreeTitle, cardLimit, isPopularitySort }) => {
  const {
    filterInitialOption,
    isSuccess: isSuccessInitial,
    handleUpdateFilter,
    clearFilter,
  } = usePsychoFilter({
    defaultCategory: CATEGORY_ENUM.INDIVIDUAL_CONSULTATION,
  });

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isLogged = !!auth.logged;

  return (
    <>
      <PsychoFilterContainer
        clearFilter={clearFilter}
        isOksFreeTitle={isOksFreeTitle}
        isCategoryFilter={!!isCategoryFilter}
        handleUpdateFilter={handleUpdateFilter}
        filterInitialOption={filterInitialOption}
      />
      {(isSuccessInitial || !isLogged) && (
        <PsychoListContainer
          isPopularitySort={isPopularitySort}
          isCardLimit={!!cardLimit}
          cardLimit={cardLimit}
          filterOption={filterInitialOption}
        />
      )}
    </>
  );
};
