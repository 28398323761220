import React from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';
import { STATUS_CIRCLE_ENUM } from './constant';

export const Component: React.FC<{}> = () => {
  const history = useHistory();

  const handleRedirect = (id: number) => {
    history.push(`/psycho-list?psychoCategory=${id}`);
  };

  return (
    <CardContainer>
      <CardHeader>
        <CardContainerTitle size="mainHeadTitle" fontFamily="Oks-Free">
          {i18n.t('LANDING.HOME_WORK.TITLE')}
        </CardContainerTitle>
        <CardContainerSubtitle>
          {i18n.t('LANDING.HOME_WORK.SUBTITLE')}
        </CardContainerSubtitle>
      </CardHeader>
      <GridContainer spacing={6} size="list">
        <LeftText>
          <TextElem
            tid="Перейдіть до списку психологів"
            color="textPrimary"
            type="regular"
            size="label"
          />
          <TextElem
            style={{ marginRight: '7px' }}
            tid="02"
            fontFamily="Oks-Free"
            size="mainHeadTitle"
            type="regular"
          />
          <TextElem
            style={{ marginRight: '4px' }}
            tid="Проведіть оформлення та оплатіть сесію"
            color="textSecondary"
            type="regular"
            size="label"
          />
          <TextElem
            style={{ marginRight: '20px' }}
            tid="04"
            fontFamily="Oks-Free"
            size="logo"
            color="border"
            type="regular"
          />
        </LeftText>
        <PointContainer>
          <Circle status={STATUS_CIRCLE_ENUM.SUCCESS} color="borderActive" />
          <Circle status={STATUS_CIRCLE_ENUM.CURRENT} color="white" />
          <Circle />
          <Circle />
        </PointContainer>
        <RightText>
          <TextElem
            style={{ marginLeft: '8px' }}
            tid="01"
            fontFamily="Oks-Free"
            size="logo"
            type="regular"
          />
          <TextElem
            tid="Оберіть потрібного спеціаліста"
            color="textPrimary"
            type="regular"
            size="label"
          />
          <TextElem
            style={{ marginLeft: '8px' }}
            tid="03"
            fontFamily="Oks-Free"
            size="logo"
            color="border"
            type="regular"
          />
          <TextElem
            style={{ marginLeft: '4px' }}
            tid="Зайдіть на консультацію у потрібний час"
            color="textSecondary"
            type="regular"
            size="label"
          />
        </RightText>
      </GridContainer>
      <MobileContainer>
        <PointContainer>
          <Circle status={STATUS_CIRCLE_ENUM.SUCCESS} color="borderActive" />
          <Circle status={STATUS_CIRCLE_ENUM.CURRENT} color="white" />
          <Circle />
          <Circle />
        </PointContainer>
        <NumberContainer>
          <TextElem tid="01" fontFamily="Oks-Free" size="logo" type="regular" />
          <TextElem
            className="currentPoint"
            tid="02"
            fontFamily="Oks-Free"
            size="mainHeadTitle"
            type="regular"
          />
          <TextElem
            tid="03"
            fontFamily="Oks-Free"
            size="logo"
            color="border"
            type="regular"
          />
          <TextElem
            tid="04"
            fontFamily="Oks-Free"
            size="logo"
            color="border"
            type="regular"
          />
        </NumberContainer>
        <MobileTextContainer>
          <TextElem
            tid="Перейдіть до списку психологів"
            color="textPrimary"
            type="regular"
            size="label"
          />
          <TextElem
            tid="Оберіть потрібного спеціаліста"
            color="textPrimary"
            type="regular"
            size="label"
          />
          <TextElem
            tid="Проведіть оформлення та оплатіть сесію"
            color="textSecondary"
            type="regular"
            size="label"
          />
          <TextElem
            tid="Зайдіть на консультацію у потрібний час"
            color="textSecondary"
            type="regular"
            size="label"
          />
        </MobileTextContainer>
      </MobileContainer>
    </CardContainer>
  );
};
const NumberContainer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: max-content;
    align-items: center;
    .currentPoint {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEAD_TITLE]}px !important;
    }
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LARGE]}px;
    }
    // height: max-content;
    // gap: ${Spacing(12)};
  }
`;

const MobileTextContainer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: max-content;
    align-items: center;
    width: 100%;
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    }
    // height: max-content;
    // gap: ${Spacing(12)};
  }
`;

const MobileContainer = styled.div`
  display: none;
  grid-template-columns: max-content max-content 1fr;
  gap: ${Spacing(5)};
  width: 100%;
  height: 100%;
  min-height: 344px;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  @media screen and (max-width: 1024px) {
    display: grid;
    padding: 0 ${Spacing(8)};
  }

  @media screen and (max-width: 1024px) {
    padding: ${Spacing(4)} ${Spacing(4)};
    gap: ${Spacing(3)};
  }
`;

const LeftText = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  // align-items: space-between;
  justify-content: space-between;
  height: 100%;
  // gap: ${Spacing(26.5)};
  width: max-content;
  align-items: center;
  justify-items: end;
`;

const PointContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  // align-items: space-between;
  justify-content: space-between;
  height: 100%;
  // width: max-content;
  width: 100%;
  align-items: center;
  justify-items: center;
  position: relative;
  div {
    z-index: 3;
  }

  &::before {
    // margin: 0;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 3px;
    height: calc(50% - 70px);
    background-color: ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
    z-index: 2;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  }
  &::after {
    // margin: 0;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 1.5px;
    height: calc(50% - 50px);
    background-color: ${COLOR_DATA[COLOR_ENUM.BORDER]};
    z-index: 1;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  }

  @media screen and (max-width: 1024px) {
    width: max-content;
    // align-items: start;
    // height: max-content;
    // gap: ${Spacing(12)};
    &::before {
      top: 36px;
      height: calc(50% - 36px);
    }
    &::after {
      bottom: 36px;
      height: calc(50% - 28px);
    }
  }
`;

const RightText = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  // align-items: space-between;
  justify-content: space-between;
  height: 100%;
  width: max-content;
  align-items: center;
  justify-items: start;

  // gap: ${Spacing(26.5)};
`;

const Circle = styled.div<{ color?: string; status?: STATUS_CIRCLE_ENUM }>`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, color }) =>
    color ? theme[color] : `${COLOR_DATA[COLOR_ENUM.BORDER]}`};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;

  ${({ status }) => {
    if (status == STATUS_CIRCLE_ENUM.CURRENT) {
      return css`
        border: 6px solid ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
        width: 24px;
        height: 24px;
      `;
    }
    if (status == STATUS_CIRCLE_ENUM.SUCCESS) {
      return css`
        width: 16px;
        height: 16px;
      `;
    }
  }}

  @media screen and (max-width: 768px) {
    width: 8px;
    height: 8px;
    ${({ status }) => {
      if (status == STATUS_CIRCLE_ENUM.CURRENT) {
        return css`
          border: 6px solid ${COLOR_DATA[COLOR_ENUM.BORDER_ACTIVE]};
          width: 20px;
          height: 20px;
        `;
      }
      if (status == STATUS_CIRCLE_ENUM.SUCCESS) {
        return css`
          width: 12px;
          height: 12px;
        `;
      }
    }}
  }
`;

const CardContainer = styled.div`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-size: cover;
  padding: ${Spacing(8)} 0 0;
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  // max-width: ${Spacing(350)};
  max-width: calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px);

  display: grid;
  justify-content: stretch;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    padding: ${Spacing(8)} ${Spacing(4)};
  }
`;

const CardHeader = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
`;

const CardContainerTitle = styled(TextElem)`
  padding-bottom: ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  text-align: center;

  @media (max-width: 768px) {
    font-size: ${Spacing(15)};
  }

  @media (max-width: 480px) {
    font-size: ${Spacing(12)};
  }
`;

const CardContainerSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-align: center;
  @media (max-width: 768px) {
    font-size: ${Spacing(3)};
  }

  @media (max-width: 480px) {
    font-size: ${Spacing(2.5)};
  }
`;

const CardSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
`;

const CardImg = styled.img`
  padding-bottom: ${Spacing(4)};
  width: 100%;
`;

const GridContainer = styled(GridElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  height: 100%;
  min-height: 554px;
  gap: ${Spacing(5)};
  // row-gap: ${Spacing(8)};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Card = styled(CardElem)`
  padding: 5px;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

export default Component;
