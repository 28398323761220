import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { HomeCatalogContainer } from '../../epic/home-catalog';
import { HomeHeaderContainer } from '../../epic/home-header';
import { HomeHeroContainer } from '../../epic/home-hero';
import { HomeAboutContainer } from '../../epic/home-about';
import { HomeFooterContainer } from '../../epic/home-footer';
import styled from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { HomeWorkContainer } from '../../epic/home-work';
import { LANDING_ID_ENUM } from '../../epic/home-footer/constant';
import { HomeReviewContainer } from '../../epic/home-review';
import { LandingAdvantageContainer } from '../../epic/landing-advantage';
import { TITLE } from '../../epic/themes-of-therapy/constant';
import { ThemesOfTherapy } from '../../epic/themes-of-therapy';
import { Spacing } from '../../theme';
import { PsychoFilterListContainer } from '../../epic/psycho-filter-list';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { TextElem } from '../../common/text';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeaderContainer />
          <HomeHeroContainer />
        </SectionHome>
        <SectionList id={LANDING_ID_ENUM.SERVICES}>
          <CardHeader>
            <TextElem
              color="textPrimary"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.TITLE"
              size="mainHeadTitle"
              fontFamily="Oks-Free"
            />
            <TextElem
              color="textSecondary"
              tid="LANDING.HOME_CATALOG.CATALOG_TEXT.SUBTITLE"
            />
          </CardHeader>
          <PsychoFilterListContainer isCategoryFilter cardLimit={5} />
        </SectionList>
        {/* <SectionService id={LANDING_ID_ENUM.SERVICES}>
          <HomeCatalogContainer />
        </SectionService> */}
        <SectionWork>
          <HomeWorkContainer />
        </SectionWork>
        <SectionAbout>
          <HomeAboutContainer />
        </SectionAbout>
        <SectionAdvantage>
          <LandingAdvantageContainer />
        </SectionAdvantage>
        <div id="popup-call"></div>
        <SectionReview>
          <HomeReviewContainer />
        </SectionReview>
        <SectionThemeOfTherapy>
          <ThemesOfTherapy title={TITLE.KNOWLEDGE_LANDING} />
        </SectionThemeOfTherapy>
        <SectionFooter>
          <HomeFooterContainer />
        </SectionFooter>
      </LayoutAppElem>
    </PageElem>
  );
};

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Spacing(3)};
  justify-content: center;
  span {
    text-align: center;
  }
`;

const SectionList = styled.section`
  margin: 0 auto;
  margin-top: ${Spacing(40)};
  padding: 0 ${Spacing(5)};
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(5 * 2)}
  );

  @media screen and (max-width: 768px) {
    margin-top: ${Spacing(30)};
  }
`;

const SectionHome = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const SectionService = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
const SectionAdvantage = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(26)} 0 ${Spacing(24)};
  @media screen and (max-width: 1024px) {
    padding: ${Spacing(24)} 0 ${Spacing(22)};
  }
  @media screen and (max-width: 768px) {
    padding: ${Spacing(6)} 0;
    margin-bottom: ${Spacing(14)};
  }
`;
const SectionThemeOfTherapy = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  margin-top: ${Spacing(11)};
  @media screen and (max-width: 768px) {
    margin-top: ${Spacing(12)};
  }
`;

const SectionAbout = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(14)} 0;
`;

const SectionWork = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  margin-top: ${Spacing(32)};
  @media screen and (max-width: 768px) {
    margin-top: ${Spacing(22)};
  }
`;
const SectionReview = styled.section`
  // background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionFooter = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
