import React from 'react';
import { RouteComponentProps } from 'react-router';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import ReactDOM from 'react-dom';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { HomeFooterContainer } from '../../epic/home-footer';
import { ProgressStepBar } from '../../common/progress-step-bar';
import { STEP_LIST } from '../../common/progress-step-bar/constant';
import { i18n } from '../../lib/lang';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { PsychoFilterListContainer } from '../../epic/psycho-filter-list';

export const Page: React.FC<RouteComponentProps> = () => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isLogged = () => auth.logged;

  return (
    <>
      {ReactDOM.createPortal(
        <ProgressWrapper isLogged={isLogged()}>
          <ProgressStepBar
            step={STEP_LIST.PSYCHO_LIST}
            text={i18n.t('PSYCHO_LIST.PROGRESS_BAR')}
          />
        </ProgressWrapper>,
        document.getElementById('layoutApp') || document.body,
      )}
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size={!isLogged() ? 'large' : 'mediumLarge'}>
            <LayoutSectionElem spacing={5}>
              <Wrapper isLogged={isLogged()}>
                <PsychoFilterListContainer isCategoryFilter />
              </Wrapper>
            </LayoutSectionElem>
          </LayoutElem>
          {!isLogged() && (
            <MarginBlock>
              <HomeFooterContainer />{' '}
            </MarginBlock>
          )}
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};

const MarginBlock = styled.div`
  margin-top: ${Spacing(6)};
`;

const ProgressWrapper = styled.div<{ isLogged: boolean }>`
  width: 100%;
  margin: 0 auto;
  padding: ${({ isLogged }) => (isLogged ? Spacing(6) : Spacing(3))};

  max-width: ${({ isLogged }) =>
    isLogged
      ? `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.MEDIUM_LARGE]}px + ${Spacing(
          6 * 2,
        )})`
      : `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(
          3 * 2,
        )})`};

  margin-top: ${({ isLogged }) => (isLogged ? Spacing(6) : Spacing(27))};

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(6)} ${Spacing(3)};
  }
`;

const Wrapper = styled.div<{ isLogged?: boolean }>`
  width: 100%;
  padding-bottom: ${Spacing(10)};
  margin-top: ${({ isLogged }) =>
    isLogged
      ? `${Spacing(9)}`
      : `${Spacing(5)}`}; //24px gap + 54px висота filter + ще один gap

  @media screen and (max-width: 1160px) {
    margin-top: ${({ isLogged }) =>
      isLogged ? `${Spacing(6)}` : '0'}; //24px gap
  }
`;
