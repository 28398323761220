import { i18n } from '../../lib/lang';
import { COLOR_ENUM } from '../../theme/color';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PSYCHO_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: `/user/request`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum FORM_VALUE_ENUM {
  PSYCHO_CATEGORY = 'psychoCategory',
}

export enum FILTER_OPTION_NAME {
  CATEGORY = 'category',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: string;
}

export const config = {
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: [],
};

export const firstColumnConfig = [
  {
    value: 1,
    type: 1,
    variant: 1,
    title: 'PSYCHO_LIST.FILTER.CARD.0.TITLE',
    description: 'PSYCHO_LIST.FILTER.CARD.0.DESCRIPTION',
    color: COLOR_ENUM.BLUE_VARIANT,
  },
  {
    value: 2,
    type: 2,
    variant: 1,
    title: 'PSYCHO_LIST.FILTER.CARD.1.TITLE',
    description: 'PSYCHO_LIST.FILTER.CARD.1.DESCRIPTION',
    color: COLOR_ENUM.GRIN_VARIANT,
  },
];

export const secondColumnConfig = [
  {
    value: 3,
    type: 1,
    variant: 1,
    title: 'PSYCHO_LIST.FILTER.CARD.2.TITLE',
    description: 'PSYCHO_LIST.FILTER.CARD.2.DESCRIPTION',
    color: COLOR_ENUM.PURPLE_VARIANT,
  },
  {
    value: 4,
    type: 2,
    variant: 2,
    title: 'PSYCHO_LIST.FILTER.CARD.3.TITLE',
    description: 'PSYCHO_LIST.FILTER.CARD.3.DESCRIPTION',
    color: COLOR_ENUM.TURQUOISE_VARIANT,
  },
  {
    value: 5,
    type: 2,
    variant: 3,
    title: 'PSYCHO_LIST.FILTER.CARD.4.TITLE',
    description: 'PSYCHO_LIST.FILTER.CARD.4.DESCRIPTION',
    color: COLOR_ENUM.YELLOW_VARIANT,
  },
];

export const activeItemConfig = [
  {
    value: CATEGORY_ENUM.INDIVIDUAL_CONSULTATION,
    type: 1,
    variant: 1,
    title: i18n.t('USER.PSYCHOLOGIST.SELECT.INDIVIDUAL_CONSULTATION'),
    description: 'PSYCHO_LIST.FILTER.CARD.0.DESCRIPTION',
    color: COLOR_ENUM.BLUE_VARIANT,
  },
  {
    value: CATEGORY_ENUM.CHILD_COUNSELING,
    type: 1,
    variant: 2,
    title: i18n.t('USER.PSYCHOLOGIST.SELECT.CHILD_COUNSELING'),
    description: 'PSYCHO_LIST.FILTER.CARD.1.DESCRIPTION',
    color: COLOR_ENUM.GRIN_VARIANT,
  },
  {
    value: CATEGORY_ENUM.COUPLES_COUNSELING,
    type: 1,
    variant: 3,
    title: i18n.t('USER.PSYCHOLOGIST.SELECT.COUPLES_COUNSELING'),
    description: 'PSYCHO_LIST.FILTER.CARD.2.DESCRIPTION',
    color: COLOR_ENUM.PURPLE_VARIANT,
  },
  {
    value: CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST,
    type: 2,
    variant: 1,
    title: i18n.t('USER.PSYCHOLOGIST.SELECT.CONSULTATION_OF_A_PSYCHIATRIST'),
    description: 'PSYCHO_LIST.FILTER.CARD.3.DESCRIPTION',
    color: COLOR_ENUM.TURQUOISE_VARIANT,
  },
  {
    value: CATEGORY_ENUM.CHILD_CONSULTATION_PSYCHIATRIST,
    type: 2,
    variant: 2,
    title: i18n.t('USER.PSYCHOLOGIST.SELECT.CHILD_CONSULTATION_PSYCHIATRIST'),
    description: i18n.t('PSYCHO_LIST.FILTER.CARD.4.DESCRIPTION'),
    color: COLOR_ENUM.YELLOW_VARIANT,
  },
];
