import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { FormikValues, useFormik } from 'formik';

import useUserOptionList from '../../lib/util/useUserOptionList';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { validation } from '../../lib/validation';
import { required, requiredArray } from '../../lib/validation/service';
import { action as fetch } from './action';
import { convert } from './convert';

const configUserType = {
  [FORM_VALUE_ENUM.USER]: [],
  [FORM_VALUE_ENUM.TITLE]: [required],
  [FORM_VALUE_ENUM.SUB_TITLE]: [required],
  [FORM_VALUE_ENUM.TEXT]: [required],
  [FORM_VALUE_ENUM.USER_TYPE]: [requiredArray],
};

const configUser = {
  [FORM_VALUE_ENUM.USER]: [required],
  [FORM_VALUE_ENUM.TITLE]: [required],
  [FORM_VALUE_ENUM.SUB_TITLE]: [required],
  [FORM_VALUE_ENUM.TEXT]: [required],
  [FORM_VALUE_ENUM.USER_TYPE]: [],
};

const validate = (values: FormikValues) =>
  validation(
    values,
    !!values[FORM_VALUE_ENUM.USER_TYPE] ? configUserType : configUser,
  );

export const Container: React.FC = () => {
  const action = useMutation(fetch, {});

  const { userList, isFetching, setSearchValue } = useUserOptionList();

  const handleSelectUserChange = (newValue: string) => {
    setSearchValue(newValue);
  };

  const initialValues = {};

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {},
  });

  const handleSubmit = (type: 'test' | 'all') => {
    action.mutate({ payload: convert(formik.values), type: type });
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (
      !formik.values[FORM_VALUE_ENUM.USER_TYPE] &&
      !!formik.values[FORM_VALUE_ENUM.USER] &&
      formik.values[FORM_VALUE_ENUM.USER].length < 1
    ) {
      return true;
    }
  };

  useEffect(() => {
    console.log('formik.values', formik.values);
  }, [formik.values]);

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
    formik.setFieldValue(FORM_VALUE_ENUM.USER_TYPE, null);
  };

  return (
    <Component
      handleSubmit={handleSubmit}
      onChangeSelect={onChangeSelect}
      handleSelectUserChange={handleSelectUserChange}
      userList={userList}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
